import DateFnsUtils from '@date-io/date-fns';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Paper,
  Popover,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import {
  useAddRatePlanMutation,
  useApproveRatePlanMutation,
  useCreateUnitNightListMutation,
  useGetAllDateListsQuery,
  useGetAllRatePlansQuery,
  useGetAllUnitListsQuery,
  useGetAllUnitNightListsQuery,
  useGetRatePlanOptionsQuery,
  useUpdateRatePlanMutation,
} from '../../store';
import { CurrentUser, RatePlan, UnitNightList } from '../../types';
import { UiUtils } from '../../utils';

interface RatePlansListProps {
  currentUser: CurrentUser;
  setRefetchRatePlans: (fn: () => Promise<any>) => void;
  onTabChange: (tabId: string, searchTerm?: string) => void;
}

type SortDirection = 'asc' | 'desc';
type FormMode = 'create' | 'edit' | 'copy';

interface RatePlanFormData
  extends Pick<
    RatePlan,
    | 'name'
    | 'channel'
    | 'active'
    | 'rate_plan_option_id'
    | 'description'
    | 'always_on'
    | 'active_date_start'
    | 'active_date_end'
    | 'unit_night_list_id'
    | 'priority'
  > {}

const getStatusInfo = (status: string) => {
  switch (status.toLowerCase()) {
    case 'approved':
      return {
        icon: <Icon.CheckCircle style={{ color: 'green', fontSize: 20 }} />,
        color: 'green',
      };
    case 'rejected':
      return {
        icon: <Icon.Close style={{ color: 'red', fontSize: 20 }} />,
        color: 'red',
      };
    case 'pending':
    default:
      return {
        icon: <Icon.Schedule style={{ color: 'orange', fontSize: 20 }} />,
        color: 'orange',
      };
  }
};

interface ColumnConfig {
  id: keyof RatePlan;
  label: string;
  sortable?: boolean;
  minWidth?: number;
}

const columnGroups = {
  Basic: [
    { id: 'id', label: 'ID', sortable: true },
    { id: 'name', label: 'Name', sortable: true },
    { id: 'channel', label: 'Channel', sortable: true },
    { id: 'active', label: 'Active', sortable: true },
    { id: 'always_on', label: 'Always On', sortable: true },
    { id: 'priority', label: 'Priority', sortable: true },
  ],
  'Rate Plan Option': [
    { id: 'rate_plan_option_name', label: 'Option Name', sortable: true },
    { id: 'rate_plan_option_active', label: 'Active', sortable: true },
    { id: 'non_refundable', label: 'Non-Refundable', sortable: true },
    { id: 'days_from_checkin', label: 'Days from Check-in', sortable: true },
    { id: 'discount_percentage', label: 'Discount', sortable: true },
  ],
  'Unit Nights': [
    { id: 'unit_night_list_id', label: 'Unit Night List', sortable: true },
  ],
  Approval: [
    { id: 'approved_status', label: 'Status', sortable: true },
    { id: 'approver', label: 'Approver', sortable: true },
    { id: 'approved_at', label: 'Approved At', sortable: true },
  ],
  Units: [
    {
      id: 'included_unit_list_ids',
      label: 'Included Unit Lists',
      sortable: true,
    },
    { id: 'unit_count', label: 'Units', sortable: true },
    {
      id: 'excluded_unit_list_ids',
      label: 'Excluded Unit Lists',
      sortable: true,
    },
    { id: 'excluded_unit_count', label: 'Excluded Units', sortable: true },
  ],
  Dates: [
    { id: 'active_dates', label: 'Active Date Range', sortable: false },
    { id: 'active_date_start', label: 'Active From', sortable: true },
    { id: 'active_date_end', label: 'Active Until', sortable: true },
    { id: 'stay_date_list_id', label: 'Stay Date List', sortable: true },
    { id: 'nights', label: 'Nights', sortable: true },
    { id: 'stay_dates', label: 'Stay Date Range', sortable: false },
    { id: 'stay_date_start', label: 'Stay From', sortable: true },
    { id: 'stay_date_end', label: 'Stay Until', sortable: true },
    { id: 'blackout_date_list_id', label: 'Blackout Dates', sortable: true },
    { id: 'blackout_nights', label: 'Blackout Nights', sortable: true },
  ],
  REBL: [
    {
      id: 'included_rebl_rule_ids',
      label: 'Included REBL Rules',
      sortable: false,
    },
    {
      id: 'excluded_rebl_rule_ids',
      label: 'Excluded REBL Rules',
      sortable: false,
    },
  ],
  Events: [
    { id: 'include_events', label: 'Include Events', sortable: true },
    { id: 'included_event_ids', label: 'Included Events', sortable: false },
    { id: 'exclude_events', label: 'Exclude Events', sortable: true },
    { id: 'excluded_event_ids', label: 'Excluded Events', sortable: false },
  ],
  Metadata: [
    { id: 'creator', label: 'Creator', sortable: true },
    { id: 'created_at', label: 'Created', sortable: true },
    { id: 'updater', label: 'Last Updated By', sortable: true },
    { id: 'updated_at', label: 'Last Updated', sortable: true },
  ],
} as const;

const formatEmailName = (email: string | null | undefined) => {
  if (!email) return '-';
  return email.split('@')[0];
};

export const RatePlansList: React.FC<RatePlansListProps> = ({
  currentUser,
  setRefetchRatePlans,
  onTabChange,
}) => {
  const isApprover = currentUser?.approver;
  const { refetch, data: ratePlans, isFetching } = useGetAllRatePlansQuery();
  const [updateRatePlanApproval] = useApproveRatePlanMutation();
  const {
    refetch: refetchUnitNightLists,
    data: unitNightLists,
    isFetching: isFetchingUnitNightLists,
  } = useGetAllUnitNightListsQuery();
  const {
    refetch: refetchUnitLists,
    data: unitLists,
    isFetching: isFetchingUnitLists,
  } = useGetAllUnitListsQuery();
  const {
    refetch: refetchDateLists,
    data: dateLists,
    isFetching: isFetchingDateLists,
  } = useGetAllDateListsQuery();
  const [addRatePlan] = useAddRatePlanMutation();
  const [updateRatePlan] = useUpdateRatePlanMutation();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    const refetchWithPromise = async () => {
      return await refetch();
    };
    setRefetchRatePlans(refetchWithPromise);
  }, [refetch, setRefetchRatePlans]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState<keyof RatePlan>('id');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formMode, setFormMode] = useState<FormMode>('create');
  const [selectedRatePlan, setSelectedRatePlan] = useState<RatePlan | null>(
    null
  );

  const defaultFormData: RatePlanFormData = {
    name: '',
    channel: 'vacasa',
    active: true,
    rate_plan_option_id: 1,
    description: 'Add a description',
    always_on: false,
    active_date_start: '',
    active_date_end: '',
    unit_night_list_id: null,
    priority: 6000,
  };
  const [formData, setFormData] = useState<RatePlanFormData>(defaultFormData);

  const newUnitNightList: Omit<UnitNightList, 'id'> = {
    name: formData.name,
    admin_only: false,
    description: formData.description,
    included_unit_list_ids: [],
    excluded_unit_list_ids: [],
    stay_date_list_id: null,
    blackout_date_list_id: null,
    included_rebl_rule_ids: [],
    excluded_rebl_rule_ids: [],
    include_events: false,
    included_event_ids: [],
    exclude_events: false,
    excluded_event_ids: [],
  };

  const [createUnitNightList] = useCreateUnitNightListMutation();

  const [searchQuery, setSearchQuery] = useState('');
  const [showNeedsApproval, setShowNeedsApproval] = useState(false);

  // Add new state for hiding expired rate plans
  const [hideExpired, setHideExpired] = useState(false);

  // Add helper to check if rate plan is expired
  const isRatePlanExpired = (ratePlan: RatePlan) => {
    if (!ratePlan.active_date_end) return false;
    return new Date(ratePlan.active_date_end) < new Date();
  };

  const getPriorityLevel = (priority: number) => {
    for (const level of UiUtils.levelCategories) {
      if (priority >= level.threshold) {
        return level;
      }
    }
    return UiUtils.levelCategories[UiUtils.levelCategories.length - 1];
  };

  const handleLevelChange = (newPriority: number) => {
    console.log(newPriority);
    setFormData((prev) => ({
      ...prev,
      priority: newPriority,
    }));
  };

  const handleSort = (column: keyof RatePlan) => {
    if (sortBy === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortDirection('asc');
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const formatDate = (date: string) => {
    try {
      const d = new Date(date);
      const userTimezoneOffset = d.getTimezoneOffset() * 60000;
      return format(new Date(d.getTime() + userTimezoneOffset), 'MMM d, yyyy');
    } catch (e) {
      return 'Invalid date';
    }
  };

  const getApprovalTooltip = (ratePlan: RatePlan) => {
    if (ratePlan.approved_status === 'APPROVED') {
      return `Approved by ${ratePlan.approver} on ${formatDate(
        ratePlan.approved_at
      )}`;
    }
    if (ratePlan.approved_status === 'REJECTED') {
      return `Rejected by ${ratePlan.approver} on ${formatDate(
        ratePlan.approved_at
      )}\nReason: ${ratePlan.rejection_reason}`;
    }
    return 'Pending approval';
  };

  const [expandedSection, setExpandedSection] = useState<string | false>(
    'basicInfo'
  );
  const handleOpenDialog = (mode: FormMode, ratePlan?: RatePlan) => {
    setFormMode(mode);
    setSelectedRatePlan(ratePlan || null);
    setExpandedSection('basicInfo');

    if (mode === 'edit' && ratePlan) {
      setFormData({
        ...ratePlan,
      });
    } else if (mode === 'copy' && ratePlan) {
      setFormData({
        ...ratePlan,
        name: `${ratePlan.name} (Copy)`,
      });
    } else {
      setFormData(defaultFormData);
    }
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedRatePlan(null);
  };

  const handleSubmit = async () => {
    let updatedRatePlanCopy = { ...formData };

    Object.keys(updatedRatePlanCopy).forEach((key) => {
      if (updatedRatePlanCopy[key] === '') {
        updatedRatePlanCopy[key] = null;
      }
    });
    console.log(updatedRatePlanCopy);

    setIsSaving(true);

    const finish = async (response: any) => {
      setIsSaving(false);
      if (!!response['error']) {
        console.log(response['error']);
      } else {
        refetch();
        handleCloseDialog();
      }
    };

    if (formMode === 'edit') {
      await updateRatePlan({
        id: selectedRatePlan.id,
        data: updatedRatePlanCopy,
      }).then(finish);
    } else {
      // CREATE or COPY
      await addRatePlan(updatedRatePlanCopy).then(finish);
    }
  };

  const isValidActiveDateRange = (start: string | null, end: string | null) => {
    if (!start || !end) return true;
    return new Date(start) < new Date(end);
  };

  const handleDateChange = (field: keyof RatePlanFormData) => (
    date: Date | null | 'Invalid Date'
  ) => {
    // Handle invalid or null dates
    if (!date || date === 'Invalid Date') {
      setFormData((prev) => ({
        ...prev,
        [field]: null,
      }));
      return;
    }

    try {
      // Ensure we have a valid date object
      const validDate = new Date(date.toISOString().split('T')[0]);
      if (isNaN(validDate.getTime())) {
        return;
      }

      // Format to YYYY-MM-DD
      const dateStr = validDate.toISOString().split('T')[0];

      setFormData((prev) => {
        const newData = { ...prev, [field]: dateStr };

        // Set end date to day after start date when initially setting start date
        if (
          field === 'active_date_start' &&
          (!prev.active_date_end || new Date(prev.active_date_end) < validDate)
        ) {
          newData.active_date_end = dateStr;
        }

        return newData;
      });
    } catch (error) {
      console.error('Invalid date:', error);
      // Keep the previous valid date
      return;
    }
  };

  const getChannelOptions = (channel: string) => {
    const options = ratePlanOptions.filter(
      (option) => option[channel] && option.active
    );
    if (options.length === 0) return [{ id: -1, name: 'No options available' }];
    return options;
  };

  const handleChannelChange = (newChannel: string) => {
    setFormData((prev) => ({
      ...prev,
      channel: newChannel,
      rate_plan_option_id: getChannelOptions(newChannel)[0]?.id,
    }));
  };

  const {
    data: ratePlanOptions,
    isFetching: isFetchingRatePlanOptions,
  } = useGetRatePlanOptionsQuery();

  const levelCategories = UiUtils.levelCategories;
  const filteredAndSortedData = React.useMemo(() => {
    if (!ratePlans) return [];

    return [...ratePlans]
      .filter((ratePlan) => {
        const searchLower = searchQuery.toLowerCase();
        const matchesSearch =
          ratePlan.id.toString().includes(searchLower) ||
          ratePlan.name.toLowerCase().includes(searchLower) ||
          ratePlan.channel.toLowerCase().includes(searchLower);

        const matchesApproval =
          !showNeedsApproval || ratePlan.approved_status !== 'APPROVED';

        const matchesExpired = !hideExpired || !isRatePlanExpired(ratePlan);

        return matchesSearch && matchesApproval && matchesExpired;
      })
      .sort((a, b) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];

        if (sortDirection === 'asc') {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else {
          return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
        }
      });
  }, [
    ratePlans,
    sortBy,
    sortDirection,
    searchQuery,
    showNeedsApproval,
    hideExpired,
  ]);

  const paginatedData = React.useMemo(() => {
    const startIndex = page * rowsPerPage;
    return filteredAndSortedData.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredAndSortedData, page, rowsPerPage]);

  const [approvalDialogOpen, setApprovalDialogOpen] = useState(false);
  const [
    selectedRatePlanForApproval,
    setSelectedRatePlanForApproval,
  ] = useState<RatePlan | null>(null);
  const [approvalStatus, setApprovalStatus] = useState('APPROVED');
  const [rejectionReason, setRejectionReason] = useState('');
  const [isApproving, setIsApproving] = useState(false);

  const handleOpenApprovalDialog = (ratePlan: RatePlan) => {
    setSelectedRatePlanForApproval(ratePlan);
    setApprovalStatus(ratePlan.approved_status || 'APPROVED');
    setRejectionReason(ratePlan.rejection_reason || '');
    setApprovalDialogOpen(true);
  };

  const handleApprovalSubmit = async () => {
    if (!selectedRatePlanForApproval) return;

    setIsApproving(true);
    try {
      await updateRatePlanApproval({
        id: selectedRatePlanForApproval.id,
        approval_status: approvalStatus as 'APPROVED' | 'REJECTED',
        reason: approvalStatus === 'REJECTED' ? rejectionReason : '',
      }).unwrap();

      await refetch();
      setApprovalDialogOpen(false);
    } catch (error) {
      console.error('Error updating approval status:', error);
    } finally {
      setIsApproving(false);
    }
  };

  const [isCreatingStayDateList, setIsCreatingStayDateList] = useState(false);

  const handleCreateUnitNightList = async () => {
    setIsCreatingStayDateList(true);
    const unitNightList = {
      ...newUnitNightList,
      name: `${formData.name} - Unit Nights`,
      description: `Unit nights for rate plan: ${formData.name}`,
    };

    try {
      const newListID = await createUnitNightList(unitNightList).unwrap();
      refetchUnitNightLists();
      setFormData((prev) => ({
        ...prev,
        unit_night_list_id: newListID,
      }));
    } catch (error) {
      console.error('Failed to create unit night list:', error);
    } finally {
      setIsCreatingStayDateList(false);
    }
  };

  const getUnitNightListName = (id: number) => {
    return unitNightLists?.find((list) => list.id === id)?.name || `List ${id}`;
  };

  const renderCellContent = (ratePlan: RatePlan, columnId: keyof RatePlan) => {
    switch (columnId) {
      case 'active_date_start':
      case 'active_date_end':
        return formatDate(ratePlan[columnId]);
      case 'unit_night_list_id':
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {ratePlan.unit_night_list_id ? (
              <>
                <Chip
                  label={getUnitNightListName(ratePlan.unit_night_list_id)}
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUnitNightListClick(ratePlan.unit_night_list_id);
                  }}
                />
                <Tooltip title="Edit unit night list">
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      onTabChange(
                        'unit_nights',
                        getUnitNightListName(ratePlan.unit_night_list_id)
                      );
                    }}
                  >
                    <Icon.Edit fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <Chip
                label="No list selected"
                size="small"
                style={{ backgroundColor: '#f5f5f5' }}
              />
            )}
          </div>
        );
      case 'approved_status':
        return (
          <Tooltip title={getApprovalTooltip(ratePlan)}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {getStatusInfo(ratePlan.approved_status).icon}
              <span
                style={{ color: getStatusInfo(ratePlan.approved_status).color }}
              >
                {ratePlan.approved_status}
              </span>
            </div>
          </Tooltip>
        );
      case 'priority':
        return (
          <Tooltip
            title={`Level ${getPriorityLevel(ratePlan.priority).category}`}
          >
            <span>{ratePlan.priority}</span>
          </Tooltip>
        );
      case 'creator':
        return (
          <Tooltip title={`Created: ${formatDate(ratePlan.created_at)}`}>
            <span>{formatEmailName(ratePlan.creator)}</span>
          </Tooltip>
        );
      case 'created_at':
        return formatDate(ratePlan.created_at);
      case 'updater':
        return ratePlan.updater ? (
          <Tooltip title={`Updated: ${formatDate(ratePlan.updated_at)}`}>
            <span>{formatEmailName(ratePlan.updater)}</span>
          </Tooltip>
        ) : null;
      case 'approver':
        return ratePlan.approver ? (
          <Tooltip title={`Approved: ${formatDate(ratePlan.approved_at)}`}>
            <span>{formatEmailName(ratePlan.approver)}</span>
          </Tooltip>
        ) : null;
      case 'updated_at':
        return ratePlan.updated_at ? formatDate(ratePlan.updated_at) : null;
      case 'approved_at':
        return ratePlan.approved_at ? formatDate(ratePlan.approved_at) : null;
      case 'non_refundable':
        return ratePlan.non_refundable ? (
          <Icon.CheckCircle fontSize="small" style={{ color: 'green' }} />
        ) : (
          <Icon.Cancel fontSize="small" style={{ color: 'red' }} />
        );
      case 'always_on':
        return (
          <Chip
            label={ratePlan[columnId] ? 'Always On' : 'Scheduled'}
            size="small"
            color={ratePlan[columnId] ? 'primary' : 'default'}
          />
        );
      case 'active':
        return (
          <Chip
            label={ratePlan.active ? 'Yes' : 'No'}
            color={ratePlan.active ? 'primary' : 'default'}
            size="small"
          />
        );
      case 'included_unit_list_ids':
      case 'excluded_unit_list_ids':
        const unitListIds = ratePlan[columnId] as number[];
        return (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '4px',
              alignItems: 'center',
            }}
          >
            {unitListIds?.map((id) => (
              <div
                key={id}
                style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
              >
                <Chip
                  label={
                    unitLists?.find((list) => list.id === id)?.name ||
                    `List ${id}`
                  }
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUnitListClick(id);
                  }}
                />
                <Tooltip title="Edit unit list">
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      const list = unitLists?.find((list) => list.id === id);
                      if (list) onTabChange('unit_lists', list.name);
                    }}
                  >
                    <Icon.Edit fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            ))}
          </div>
        );
      case 'stay_date_list_id':
      case 'blackout_date_list_id':
        const dateListId = ratePlan[columnId] as number;
        return dateListId ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Chip
              label={
                dateLists?.find((list) => list.id === dateListId)?.name ||
                `List ${dateListId}`
              }
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleDateListClick(dateListId);
              }}
            />
            <Tooltip title="Edit date list">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  const list = dateLists?.find(
                    (list) => list.id === dateListId
                  );
                  if (list) onTabChange('date_lists', list.name);
                }}
              >
                <Icon.Edit fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        ) : null;
      case 'discount_percentage':
        return `${ratePlan.discount_percentage}%`;

      default:
        return ratePlan[columnId];
    }
  };

  const availableColumns: ReadonlyArray<ColumnConfig> = Object.values(
    columnGroups
  ).flat();

  const defaultVisibleColumns = new Set<keyof RatePlan>([
    'id',
    'name',
    'channel',
    'active',
    'always_on',
    'days_from_checkin',
    'discount_percentage',
    'non_refundable',
    'unit_night_list_id',
    'approved_status',
    'creator',
  ]);

  const [visibleColumns, setVisibleColumns] = useState<Set<keyof RatePlan>>(
    () => {
      const stored = sessionStorage.getItem('ratePlansTableColumns');
      if (stored) {
        try {
          const parsed = JSON.parse(stored);
          const validColumns = parsed.filter(
            (key: string): key is keyof RatePlan =>
              availableColumns.some((col) => col.id === key)
          );
          return new Set(validColumns);
        } catch {
          return defaultVisibleColumns;
        }
      }
      return defaultVisibleColumns;
    }
  );

  const handleColumnVisibilityChange = (columnId: keyof RatePlan) => {
    const newVisibleColumns = new Set(visibleColumns);
    if (newVisibleColumns.has(columnId)) {
      if (newVisibleColumns.size > 1) {
        newVisibleColumns.delete(columnId);
      }
    } else {
      newVisibleColumns.add(columnId);
    }
    setVisibleColumns(newVisibleColumns);
    // Convert Set to Array before storing
    sessionStorage.setItem(
      'ratePlansTableColumns',
      JSON.stringify(Array.from(newVisibleColumns))
    );
  };

  const [
    columnSelectorAnchor,
    setColumnSelectorAnchor,
  ] = useState<null | HTMLElement>(null);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUnitNightListClick = (id: number) => {
    const list = unitNightLists?.find((list) => list.id === id);
    if (list) {
      onTabChange('unit_nights', list.name);
    }
  };

  const handleUnitListClick = (id: number) => {
    const list = unitLists?.find((list) => list.id === id);
    if (list) {
      onTabChange('unit_lists', list.name);
    }
  };

  const handleDateListClick = (id: number) => {
    const list = dateLists?.find((list) => list.id === id);
    if (list) {
      onTabChange('date_lists', list.name);
    }
  };

  // Add state for unit night list search
  const [unitNightListSearchTerm, setUnitNightListSearchTerm] = useState('');

  // Add filter function
  const filteredUnitNightLists = unitNightLists?.filter(
    (list) =>
      list.name.toLowerCase().includes(unitNightListSearchTerm.toLowerCase()) ||
      list.creator
        .toLowerCase()
        .includes(unitNightListSearchTerm.toLowerCase()) ||
      list.description
        ?.toLowerCase()
        .includes(unitNightListSearchTerm.toLowerCase())
  );

  return (
    <Paper>
      <Box
        style={{
          display: 'flex',
          gap: '16px',
          marginBottom: '16px',
          alignItems: 'center',
          padding: '16px',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <TextField
            label="Search Rate Plans"
            variant="outlined"
            style={{ width: '400px' }}
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showNeedsApproval}
                onChange={(e) => setShowNeedsApproval(e.target.checked)}
              />
            }
            label="Needs Approval"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={hideExpired}
                onChange={(e) => setHideExpired(e.target.checked)}
              />
            }
            label="Hide Expired"
          />
        </div>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Tooltip title={isFetching ? 'Loading...' : 'Refresh data'}>
            <IconButton onClick={refetch} disabled={isFetching} size="small">
              {isFetching ? (
                <CircularProgress size={20} />
              ) : (
                <Icon.Replay height={20} width={20} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Customize columns">
            <IconButton
              onClick={(e) => setColumnSelectorAnchor(e.currentTarget)}
              size="small"
            >
              <Icon.Settings height={20} width={20} />
            </IconButton>
          </Tooltip>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenDialog('create')}
            startIcon={<Icon.Add height={20} width={20} />}
          >
            New Rate Plan
          </Button>
        </div>
      </Box>

      <div style={{ position: 'relative' }}>
        {isFetching && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </div>
        )}

        <TableContainer
          style={{
            height: '575px',
            overflow: 'auto',
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className="sticky-header-cell">
                  <TableSortLabel
                    active={sortBy === 'id'}
                    direction={sortDirection}
                    onClick={() => handleSort('id')}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>
                {availableColumns
                  .filter(
                    (column) =>
                      visibleColumns.has(column.id) && column.id !== 'id'
                  )
                  .map((column) => (
                    <TableCell
                      key={column.id}
                      className="sticky-header-cell"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.sortable ? (
                        <TableSortLabel
                          active={sortBy === column.id}
                          direction={sortDirection}
                          onClick={() => handleSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  ))}
                <TableCell className="sticky-header-cell">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((ratePlan) => (
                <Tooltip
                  key={ratePlan.id}
                  title={ratePlan.description || 'No description'}
                  placement="bottom-start"
                  enterDelay={500}
                >
                  <TableRow>
                    <TableCell>{ratePlan.id}</TableCell>
                    {availableColumns
                      .filter(
                        (column) =>
                          visibleColumns.has(column.id) && column.id !== 'id'
                      )
                      .map((column) => (
                        <TableCell key={column.id}>
                          {renderCellContent(ratePlan, column.id)}
                        </TableCell>
                      ))}
                    <TableCell>
                      <div style={{ display: 'flex', gap: '4px' }}>
                        <Tooltip title="Edit">
                          <IconButton
                            size="small"
                            onClick={() => handleOpenDialog('edit', ratePlan)}
                          >
                            <Icon.Edit fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Copy">
                          <IconButton
                            size="small"
                            onClick={() => handleOpenDialog('copy', ratePlan)}
                          >
                            <Icon.FileCopy fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        {isApprover && (
                          <Tooltip
                            title={
                              ratePlan.approved_status.toLowerCase() ===
                              'approved'
                                ? 'Update approval (Currently: Approved)'
                                : ratePlan.approved_status.toLowerCase() ===
                                  'rejected'
                                ? 'Update approval (Currently: Rejected)'
                                : 'Update approval (Currently: Pending)'
                            }
                          >
                            <IconButton
                              size="small"
                              onClick={() => handleOpenApprovalDialog(ratePlan)}
                            >
                              {ratePlan.approved_status.toLowerCase() ===
                              'approved' ? (
                                <Icon.CheckCircle
                                  fontSize="small"
                                  style={{ color: 'green' }}
                                />
                              ) : ratePlan.approved_status.toLowerCase() ===
                                'rejected' ? (
                                <Icon.Cancel
                                  fontSize="small"
                                  style={{ color: 'red' }}
                                />
                              ) : (
                                <Icon.HourglassEmpty
                                  fontSize="small"
                                  style={{ color: 'orange' }}
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                </Tooltip>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <TablePagination
        style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={filteredAndSortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <Popover
        open={Boolean(columnSelectorAnchor)}
        anchorEl={columnSelectorAnchor}
        onClose={() => setColumnSelectorAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={2} style={{ maxHeight: '400px', overflow: 'auto' }}>
          <Typography variant="subtitle2" gutterBottom>
            Visible Columns
          </Typography>
          {Object.entries(columnGroups).map(([groupName, columns]) => (
            <div key={groupName}>
              <Typography
                variant="subtitle2"
                style={{
                  marginTop: '16px',
                  marginBottom: '8px',
                  color: '#666',
                  fontSize: '0.875rem',
                }}
              >
                {groupName}
              </Typography>
              <FormGroup>
                {columns.map((column) => (
                  <FormControlLabel
                    key={column.id}
                    control={
                      <Checkbox
                        checked={visibleColumns.has(column.id)}
                        onChange={() => handleColumnVisibilityChange(column.id)}
                        disabled={
                          visibleColumns.has(column.id) &&
                          visibleColumns.size === 1
                        }
                      />
                    }
                    label={column.label}
                  />
                ))}
              </FormGroup>
            </div>
          ))}
        </Box>
      </Popover>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {formMode === 'create'
            ? 'Create New Rate Plan'
            : formMode === 'edit'
            ? 'Edit Rate Plan'
            : 'Copy Rate Plan'}
        </DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                padding: '16px 0',
              }}
            >
              {/* Basic Info Section */}
              <Accordion
                expanded={expandedSection === 'basicInfo'}
                onChange={() =>
                  setExpandedSection(
                    expandedSection === 'basicInfo' ? false : 'basicInfo'
                  )
                }
                defaultExpanded
              >
                <AccordionSummary expandIcon={<Icon.ExpandMore />}>
                  <Typography variant="h6">Basic Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                      width: '100%',
                    }}
                  >
                    <div style={{ display: 'flex', gap: '16px' }}>
                      <TextField
                        label="Name"
                        value={formData.name}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }))
                        }
                        style={{ width: '50%' }}
                      />
                      <FormControlLabel
                        style={{ width: '25%' }}
                        control={
                          <Switch
                            checked={formData.active}
                            onChange={(e) =>
                              setFormData((prev) => ({
                                ...prev,
                                active: e.target.checked,
                              }))
                            }
                          />
                        }
                        label="Active"
                      />
                    </div>
                    <TextField
                      label="Description"
                      value={formData.description}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                      fullWidth
                    />
                    <div style={{ display: 'flex', gap: '16px' }}>
                      <TextField
                        label="Priority"
                        type="number"
                        value={formData.priority}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            priority: parseInt(e.target.value) || 0,
                          }))
                        }
                        style={{ width: '50%' }}
                      />
                      <FormControl style={{ width: '50%' }}>
                        <InputLabel>Level</InputLabel>
                        <Select
                          value={getPriorityLevel(formData.priority).threshold}
                          onChange={(e) =>
                            handleLevelChange(
                              parseInt(e.target.value as string)
                            )
                          }
                        >
                          {levelCategories.map((level) => (
                            <MenuItem
                              key={level.category}
                              value={level.threshold}
                            >
                              {`${level.category} (Priority >= ${level.threshold})`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              {/* Channel Info Section */}
              <Accordion
                expanded={expandedSection === 'channelInfo'}
                onChange={() =>
                  setExpandedSection(
                    expandedSection === 'channelInfo' ? false : 'channelInfo'
                  )
                }
              >
                <AccordionSummary expandIcon={<Icon.ExpandMore />}>
                  <Typography variant="h6">Rate Plan Option</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                      width: '100%',
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel>Channel</InputLabel>
                      <Select
                        value={formData.channel}
                        onChange={(e) =>
                          handleChannelChange(e.target.value as string)
                        }
                      >
                        <MenuItem value="vacasa">Vacasa</MenuItem>
                        <MenuItem value="vrbo">VRBO</MenuItem>
                        <MenuItem value="airbnb">Airbnb</MenuItem>
                        <MenuItem value="bdc">Booking.com</MenuItem>
                        <MenuItem value="expedia">Expedia</MenuItem>
                        <MenuItem value="mybookingpal">MyBookingPal</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel>Rate Plan Option</InputLabel>
                      <Select
                        value={formData.rate_plan_option_id}
                        onChange={(e) => {
                          const newOptionId = e.target.value as number;
                          setFormData((prev) => ({
                            ...prev,
                            rate_plan_option_id: newOptionId,
                          }));
                        }}
                        disabled={formData.rate_plan_option_id === -1}
                      >
                        {isFetchingRatePlanOptions ? (
                          <MenuItem value="">
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                              }}
                            >
                              <CircularProgress size={20} />
                              <span>Loading...</span>
                            </div>
                          </MenuItem>
                        ) : (
                          getChannelOptions(formData.channel)?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </AccordionDetails>
              </Accordion>

              {/* Date Ranges Section */}
              <Accordion
                expanded={expandedSection === 'dateRanges'}
                onChange={() =>
                  setExpandedSection(
                    expandedSection === 'dateRanges' ? false : 'dateRanges'
                  )
                }
              >
                <AccordionSummary expandIcon={<Icon.ExpandMore />}>
                  <Typography variant="h6">Dates</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                      width: '100%',
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.always_on}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              always_on: e.target.checked,
                              active_date_start: e.target.checked
                                ? null
                                : prev.active_date_start,
                              active_date_end: e.target.checked
                                ? null
                                : prev.active_date_end,
                            }))
                          }
                        />
                      }
                      label="Always On"
                    />

                    {/* Active Dates */}
                    <div
                      style={{
                        display: 'flex',
                        gap: '16px',
                        opacity: formData.always_on ? 0.5 : 1,
                      }}
                    >
                      <KeyboardDatePicker
                        margin="normal"
                        label="Active Date Start"
                        format="MM/dd/yyyy"
                        value={
                          formData.active_date_start
                            ? new Date(
                                `${formData.active_date_start}T12:00:00Z`
                              )
                            : null
                        }
                        onChange={handleDateChange('active_date_start')}
                        KeyboardButtonProps={{
                          'aria-label': 'change active start date',
                        }}
                        fullWidth
                        invalidDateMessage="Invalid date format (MM/DD/YYYY)"
                        maxDate={new Date('2099-12-31')}
                        minDate={new Date('2000-01-01')}
                        disabled={formData.always_on}
                      />
                      <KeyboardDatePicker
                        margin="normal"
                        label="Active Date End"
                        format="MM/dd/yyyy"
                        value={
                          formData.active_date_end
                            ? new Date(`${formData.active_date_end}T12:00:00Z`)
                            : null
                        }
                        onChange={handleDateChange('active_date_end')}
                        KeyboardButtonProps={{
                          'aria-label': 'change active end date',
                        }}
                        fullWidth
                        minDate={
                          formData.active_date_start
                            ? new Date(
                                `${formData.active_date_start}T12:00:00Z`
                              )
                            : undefined
                        }
                        minDateMessage="End date must be after start date"
                        invalidDateMessage="Invalid date format (MM/DD/YYYY)"
                        maxDate={new Date('2099-12-31')}
                        disabled={
                          formData.always_on || !formData.active_date_start
                        }
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              {/* Unit Night Lists Section */}
              <Accordion
                expanded={expandedSection === 'unitNightList'}
                onChange={() =>
                  setExpandedSection(
                    expandedSection === 'unitNightList'
                      ? false
                      : 'unitNightList'
                  )
                }
              >
                <AccordionSummary expandIcon={<Icon.ExpandMore />}>
                  <Typography variant="h6">Unit Night List</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                      width: '100%',
                    }}
                  >
                    <FormControl fullWidth>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                          marginBottom: '8px',
                        }}
                      >
                        <InputLabel style={{ position: 'relative' }}>
                          Unit Night List
                        </InputLabel>
                        <Button
                          size="small"
                          startIcon={
                            isCreatingStayDateList ? (
                              <CircularProgress size={16} />
                            ) : (
                              <Icon.Add height={16} width={16} />
                            )
                          }
                          onClick={handleCreateUnitNightList}
                          disabled={
                            !formData.name.trim() || isCreatingStayDateList
                          }
                          style={{
                            opacity: !formData.name.trim() ? 0.6 : 1,
                            position: 'relative',
                          }}
                          title={
                            !formData.name.trim()
                              ? 'Enter rate plan name before creating a new unit night list'
                              : 'Create new unit night list'
                          }
                        >
                          New List
                        </Button>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '8px',
                          marginTop: '16px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '4px',
                            margin: '8px 0',
                          }}
                        >
                          {(formData.unit_night_list_id
                            ? [formData.unit_night_list_id]
                            : []
                          ).map((id) => {
                            const list = unitNightLists?.find(
                              (l) => l.id === id
                            );
                            return (
                              <Tooltip title="Click to view unit night list">
                                <Chip
                                  key={id}
                                  label={list?.name || id}
                                  onClick={() => handleUnitNightListClick(id)}
                                  onDelete={() => {
                                    setFormData((prev) => ({
                                      ...prev,
                                      unit_night_list_id: null,
                                    }));
                                  }}
                                  size="small"
                                  style={{ cursor: 'pointer' }}
                                />
                              </Tooltip>
                            );
                          })}
                        </div>
                        <Select
                          value={formData.unit_night_list_id || ''}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              unit_night_list_id: e.target.value as number,
                            }))
                          }
                          displayEmpty
                          renderValue={(selected) => {
                            if (!selected)
                              return <em>Select a unit night list</em>;
                            return getUnitNightListName(selected as number);
                          }}
                          disabled={isFetchingUnitNightLists}
                        >
                          <ListSubheader>
                            <TextField
                              size="small"
                              autoFocus
                              placeholder="Type to filter..."
                              fullWidth
                              value={unitNightListSearchTerm}
                              onChange={(e) =>
                                setUnitNightListSearchTerm(e.target.value)
                              }
                              onKeyDown={(e) => {
                                if (e.key !== 'Escape') {
                                  e.stopPropagation();
                                }
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                              onMouseDown={(e) => {
                                e.stopPropagation();
                              }}
                            />
                          </ListSubheader>
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {filteredUnitNightLists
                            ?.filter(
                              (list) => list.id !== formData.unit_night_list_id
                            )
                            .map((list) => (
                              <MenuItem key={list.id} value={list.id}>
                                {list.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </FormControl>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={
              isSaving ||
              !formData.name.trim() ||
              !isValidActiveDateRange(
                formData.active_date_start,
                formData.active_date_end
              ) ||
              formData.rate_plan_option_id === -1
            }
            startIcon={
              isSaving ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            {formMode === 'create'
              ? 'Create'
              : formMode === 'edit'
              ? 'Save'
              : 'Copy'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={approvalDialogOpen}
        onClose={() => setApprovalDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Update Approval Status</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              paddingTop: '8px',
            }}
          >
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={approvalStatus}
                onChange={(e) => setApprovalStatus(e.target.value as string)}
              >
                <MenuItem value="APPROVED">Approved</MenuItem>
                <MenuItem value="REJECTED">Rejected</MenuItem>
              </Select>
            </FormControl>

            {approvalStatus === 'REJECTED' && (
              <TextField
                label="Rejection Reason"
                value={rejectionReason}
                onChange={(e) => setRejectionReason(e.target.value)}
                multiline
                rows={3}
                required
                fullWidth
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setApprovalDialogOpen(false)}
            color="primary"
            disabled={isApproving}
          >
            Cancel
          </Button>
          <Button
            onClick={handleApprovalSubmit}
            color="primary"
            variant="contained"
            disabled={
              isApproving ||
              (approvalStatus === 'rejected' && !rejectionReason.trim())
            }
            startIcon={
              isApproving ? (
                <CircularProgress size={20} color="inherit" />
              ) : null
            }
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};
