import React, {useEffect, useReducer, useState, useMemo, useRef} from 'react';
import './ReblRulesetModal.scss';
import {
    Cohort,
    CurrentUser,
    DemandInfluenceFormOptions,
    DemandInfluenceFormType,
    LocationOptions,
    ReblComponent,
    ReblRuleset,
    SelectOption,
    UnitTetherAnchor,
    UnitTetherChild
} from "../../types";
import * as Icon from '@material-ui/icons';

import {
    Button,
    CircularProgress,
    Select as MuiSelect,
    MenuItem,
    Tooltip,
    TextField,
    Collapse,
    IconButton,
    InputAdornment,
    Paper,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextareaAutosize
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {DATE_FORMAT, UiUtils} from "../../utils";
import {ReblSection} from "../../types/ReblSection";
import {Checkbox} from '@material-ui/core';
import {addDays, format} from "date-fns"
import {useGetReblOptionsQuery, useAddReblRuleMutation, useUpdateReblRuleMutation, useGetUnitTetherTableQuery} from "../../store";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import { GeographySelector } from '../Tables/GeographySelector';

// If we need to extend ReblComponent with additional properties, use type intersection
type ExtendedReblComponent = ReblComponent & {
    label?: string;      // Add any additional properties
    key?: string;        // that aren't in the imported type
    required?: boolean;
    disabled?: boolean;
    start_key?: string;
    end_key?: string;
    min?: number;
    max?: number;
    step?: number;
    default?: any;
    options?: SelectOption[];
    placeholder?: string;
};

interface ReblRulesetModalProps {
    selectedReblRule: ReblRuleset;
    cohorts: Cohort[];
    formType: DemandInfluenceFormType;
    closeModal: (refresh?: boolean) => void;
    // onUpdateReblRule: (rule: ReblRuleset) => void;
    currentUser: CurrentUser;
    locationOptions: LocationOptions,
    isFetchingLocationOptions: boolean,
}

interface ValidationMessage {
    message: string;
    field?: string;
    type: 'error' | 'warning';
}

interface ValidationState {
    messages: ValidationMessage[];
    hasErrors: boolean;
}

interface State {
    isLoading: boolean;
    isCompoundable: boolean;
    disableSave: boolean;
    isSaving: boolean;
    rules: string[];
    rulesetComponents: ReblComponent[];
    updatedRule: ReblRuleset | undefined;
    validation: ValidationState;
    ruleFilter: string;
}

type Action =
    | { type: 'SET_LOADING'; payload: boolean }
    | { type: 'SET_COMPOUNDABLE'; payload: boolean }
    | { type: 'SET_DISABLE_SAVE'; payload: boolean }
    | { type: 'SET_IS_SAVING'; payload: boolean }
    | { type: 'SET_RULES'; payload: string[] }
    | { type: 'SET_RULESET_COMPONENTS'; payload: ReblComponent[] }
    | { type: 'SET_UPDATED_RULE'; payload: ReblRuleset }
    | { type: 'SET_VALIDATION'; payload: ValidationState }
    | { type: 'INITIALIZE_FORM'; payload: {
        rulesetComponents: ReblComponent[],
        rules: string[],
        updatedRule: ReblRuleset
    }}
    | { type: 'RESET_FORM' }
    | { type: 'SET_FILTER'; payload: string };

const reblModalReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, isLoading: action.payload };
        case 'SET_COMPOUNDABLE':
            return { ...state, isCompoundable: action.payload };
        case 'SET_DISABLE_SAVE':
            return { ...state, disableSave: action.payload };
        case 'SET_IS_SAVING':
            return { ...state, isSaving: action.payload };
        case 'SET_RULES':
            return { ...state, rules: action.payload };
        case 'SET_RULESET_COMPONENTS':
            return { ...state, rulesetComponents: action.payload };
        case 'SET_UPDATED_RULE':
            return { ...state, updatedRule: action.payload };
        case 'SET_VALIDATION':
            return {
                ...state,
                validation: action.payload,
                disableSave: action.payload.hasErrors
            };
        case 'INITIALIZE_FORM':
            return {
                ...state,
                rulesetComponents: action.payload.rulesetComponents,
                rules: action.payload.rules,
                updatedRule: action.payload.updatedRule,
                isLoading: false
            };
        case 'RESET_FORM':
            return {
                ...initialState,
                rulesetComponents: state.rulesetComponents
            };
        case 'SET_FILTER':
            return { ...state, ruleFilter: action.payload };
        default:
            return state;
    }
};

const initialState: State = {
    isLoading: true,
    isCompoundable: false,
    disableSave: true,
    isSaving: false,
    rules: [],
    rulesetComponents: [],
    updatedRule: undefined as ReblRuleset,
    validation: { messages: [], hasErrors: false },
    ruleFilter: ""
};

const ValidationMessages: React.FC<{
    messages: ValidationMessage[];
    onMessageClick?: (field: string) => void;
}> = ({messages, onMessageClick}) => {
    // Group messages by context
    const groupedMessages = messages.reduce((acc, message) => {
        const context = validationContexts.find(c =>
            message.field && c.type === message.field.split('_')[0]
        ) || validationContexts.find(c => c.type === 'general');

        const contextType = context?.type || 'general';
        if (!acc[contextType]) {
            acc[contextType] = {
                title: context?.title || 'General Validation',
                messages: []
            };
        }
        acc[contextType].messages.push(message);
        return acc;
    }, {} as Record<string, { title: string, messages: ValidationMessage[] }>);

    if (messages.length === 0) return null;

    return (
        <div className="validation-messages">
            {Object.entries(groupedMessages).map(([type, group]) => (
                <div key={type} className="validation-group">
                    <Typography variant="subtitle1">{group.title}</Typography>
                    <div className="messages">
                        {group.messages.map((m, i) => (
                            <div 
                                key={i} 
                                className={`message ${m.type} ${m.field ? 'clickable' : ''}`}
                                onClick={() => m.field && onMessageClick?.(m.field)}
                                style={{ cursor: m.field ? 'pointer' : 'default' }}
                            >
                                <Icon.Error fontSize="small" />
                                <span>{m.message}</span>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

interface ButtonProps {
    onClick: () => void;
    children: React.ReactNode;
    variant?: 'text' | 'outlined' | 'contained';
    color?: 'default' | 'inherit' | 'primary' | 'secondary';
    disabled?: boolean;
    className?: string;
}

const filterSection = (section: ReblSection, filter: string): boolean => {
    if (!filter) return true;
    const lowerFilter = filter.toLowerCase();
    if (section.title.toLowerCase().includes(lowerFilter)) return true;
    return section.components.some(c => c.label.toLowerCase().includes(lowerFilter));
};

const filterComponent = (component: ExtendedReblComponent, filter: string): boolean => {
    if (!filter) return true;
    const searchText = component.label || component.title || '';
    return searchText.toLowerCase().includes(filter.toLowerCase());
};

interface ExpandedSections {
    [key: string]: boolean;  // key is section title
}

interface ExpandedCategories {
    [key: string]: boolean;  // key is category title
}

interface RuleGroup {
    title: string;
    description?: string;
    required: boolean;
    sections: {
        title: string;
        components: ExtendedReblComponent[];
    }[];
}

const RulesSidebar: React.FC<{
    ruleGroups: RuleGroup[];
    selectedRules: string[];
    onToggleRule: (component: ExtendedReblComponent) => void;
    filter: string;
    onFilterChange: (value: string) => void;
    onSectionClick: (sectionTitle: string) => void;
    expandedSections: ExpandedSections;
    setExpandedSections: (value: React.SetStateAction<ExpandedSections>) => void;
    expandedCategories: ExpandedCategories;
    setExpandedCategories: (value: React.SetStateAction<ExpandedCategories>) => void;
}> = ({
    ruleGroups,
    selectedRules,
    onToggleRule,
    filter,
    onFilterChange,
    onSectionClick,
    expandedSections,
    setExpandedSections,
    expandedCategories,
    setExpandedCategories
}) => {
    // Add this function to check if all sections are expanded
    const areAllExpanded = useMemo(() => {
        const allSections = ruleGroups.flatMap(g => g.sections.map(s => s.title));
        return allSections.every(title => expandedSections[title]);
    }, [expandedSections, ruleGroups]);

    const toggleSection = (sectionTitle: string) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionTitle]: !prev[sectionTitle]
        }));
    };

    const toggleCategory = (categoryTitle: string) => {
        setExpandedCategories(prev => ({
            ...prev,
            [categoryTitle]: !prev[categoryTitle]
        }));
    };

    const toggleAll = () => {
        if (areAllExpanded) {
            // Collapse everything - both categories and sections
            setExpandedSections({});
            setExpandedCategories(
                ruleGroups.reduce((acc, group) => ({
                    ...acc,
                    [group.title.toLowerCase()]: false
                }), {})
            );
        } else {
            // Expand everything - both categories and sections
            const allExpanded: ExpandedSections = {};
            ruleGroups.forEach(g => {
                g.sections.forEach(s => {
                    allExpanded[s.title] = true;
                });
            });
            setExpandedSections(allExpanded);
            setExpandedCategories(
                ruleGroups.reduce((acc, group) => ({
                    ...acc,
                    [group.title.toLowerCase()]: true
                }), {})
            );
        }
    };

    // When searching, automatically expand sections with matches
    useEffect(() => {
        if (filter) {
            const sectionsWithMatches: ExpandedSections = {};
            const categoriesWithMatches: ExpandedCategories = {};
            
            ruleGroups.forEach(g => {
                g.sections.forEach(s => {
                    if (filterSection(s, filter)) {
                        sectionsWithMatches[s.title] = true;
                        categoriesWithMatches[g.title.toLowerCase()] = true;
                    }
                });
            });
            
            setExpandedSections(sectionsWithMatches);
            setExpandedCategories(categoriesWithMatches);
        }
    }, [filter, ruleGroups]);

    return (
        <Paper className="rebl-sidebar">
            <div className="sidebar-header">
                <Typography variant="h6">Rules</Typography>
                <div className="sidebar-actions">
                    <Tooltip title={areAllExpanded ? "Collapse All" : "Expand All"}>
                        <IconButton
                            size="small"
                            onClick={toggleAll}
                            className="toggle-all-button"
                        >
                            {areAllExpanded ?
                                <Icon.ExpandLess fontSize="small" /> :
                                <Icon.ExpandMore fontSize="small" />
                            }
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <TextField
                className="search-field"
                placeholder="Search rules..."
                value={filter}
                onChange={(e) => onFilterChange(e.target.value)}
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icon.Search fontSize="small" />
                        </InputAdornment>
                    ),
                    endAdornment: filter && (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear search"
                                onClick={() => onFilterChange('')}
                                size="small"
                                className="clear-button"
                            >
                                <Icon.Clear fontSize="small" />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <div className="sidebar-content">
                {ruleGroups.map((group) => (
                    <div key={group.title} className="rule-category">
                        <div
                            className="category-header"
                            onClick={() => toggleCategory(group.title.toLowerCase())}
                        >
                            {expandedCategories[group.title.toLowerCase()] ?
                                <Icon.ExpandMore fontSize="small" /> :
                                <Icon.ChevronRight fontSize="small" />
                            }
                            <span>{group.title}</span>
                            {group.description && (
                                <Tooltip title={group.description}>
                                    <Icon.HelpOutline fontSize="small" />
                                </Tooltip>
                            )}
                        </div>
                        <Collapse in={expandedCategories[group.title.toLowerCase()]}>
                            {group.sections
                                .filter((section) => !filter || filterSection(section, filter))
                                .map((section) => (
                                    <div key={section.title} className="rule-section">
                                        <div
                                            className="section-header"
                                            onClick={() => toggleSection(section.title)}
                                        >
                                            {expandedSections[section.title] ?
                                                <Icon.ExpandMore fontSize="small" /> :
                                                <Icon.ChevronRight fontSize="small" />
                                            }
                                            <span>{section.title.split('_')[0]}</span>
                                        </div>
                                        <Collapse in={expandedSections[section.title]}>
                                            {section.components
                                                .filter(c => !filter || filterComponent(c, filter))
                                                .map(component => (
                                                    <div
                                                        key={component.key || component.title}
                                                        className={`rule-item ${selectedRules.includes(component.label || '') ? 'selected' : ''}`}
                                                        onClick={() => {
                                                            onToggleRule(component);
                                                            onSectionClick(section.title);
                                                        }}
                                                    >
                                                        <Checkbox
                                                            checked={selectedRules.includes(component.label || '') || group.required}
                                                            onChange={() => onToggleRule(component)}
                                                            size="small"
                                                            disabled={!component.label || group.required}
                                                        />
                                                        <span>{component.label || component.title}</span>
                                                        {component.tooltip && (
                                                            <Tooltip title={component.tooltip}>
                                                                <Icon.HelpOutline fontSize="small" />
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                ))}
                                        </Collapse>
                                    </div>
                                ))}
                        </Collapse>
                    </div>
                ))}
            </div>
        </Paper>
    );
};

const organizeRules = (components: ReblComponent[]): RuleGroup[] => {
    return components.map(rc => ({
        title: rc.title,
        description: rc.tooltip,
        required: rc.type === "metadata",
        sections: rc.sections.map(section => ({
            ...section,
            title: section.title
        }))
    }));
};

// Update the ValidationContext interface
interface ValidationContext {
    type: 'dates' | 'pricing' | 'location' | 'general' | 'tether';
    title: string;
    validate: (state: {
        rule: ReblRuleset,
        rules: string[],
        rulesetComponents: ReblComponent[],
        currentUser: CurrentUser
    }) => ValidationMessage[];
}

// Update the validation contexts array
const validationContexts: ValidationContext[] = [
    {
        type: 'general',
        title: 'Required Fields',
        validate: ({ rule, rulesetComponents, rules }) => {
            const messages: ValidationMessage[] = [];
            
            rulesetComponents
                .flatMap(rc => rc.sections)
                .flatMap(s => s.components)
                .filter(c => {
                    if (c.type === 'boolean') {
                        return false;
                    }
                    return c.required || 
                           rules.includes(c.label || '') ||
                           (c.key === 'included_units' && rule.action?.toLowerCase().includes('tether'));
                })
                .forEach(c => {
                    if (c.type === "date_range") {
                        if (!rule[c.start_key] && !rule[c.end_key]) {
                            messages.push({
                                message: `${c.label || c.title} ${c.required ? 'is required' : 'has no value'}`,
                                type: 'error',
                                field: c.key
                            });
                        }
                    } else if (c.type === "days_range") {
                        if (!!rule[c.start_key] && !rule[c.end_key] && c.max !== null) {
                            messages.push({
                                message: `missing ${c.end_key}`,
                                type: 'error',
                                field: c.end_key
                            });
                        } else if (!rule[c.start_key] && !!rule[c.end_key]) {
                            messages.push({
                                message: `missing ${c.start_key}`,
                                type: 'error',
                                field: c.start_key
                            });
                        } else if (rule[c.start_key] > rule[c.end_key]) {
                            messages.push({
                                message: `${c.start_key} must be less than ${c.end_key}`,
                                type: 'error',
                                field: c.key
                            });
                        }
                    } else if (rule[c.key] === undefined || 
                             rule[c.key] === '' || 
                             rule[c.key] === null ||
                             Number.isNaN(rule[c.key]) ||
                             (Array.isArray(rule[c.key]) && rule[c.key].length === 0) ||
                             (typeof rule[c.key] === 'number' && !isFinite(rule[c.key]))) {
                        messages.push({
                            message: `${c.label || c.title} ${c.required ? 'is required' : 'has no value'}`,
                            type: (c.required || c.key === 'included_units' && rule.action?.toLowerCase().includes('tether')) ? 'error' : 'warning',
                            field: c.type === 'priority' ? `${c.key}-priority` : 
                                  c.key === 'category' ? `${c.key}-category` : 
                                  c.key
                        });
                    }
                });

            return messages;
        }
    },
    {
        type: 'dates',
        title: 'Date Validation',
        validate: ({ rule, rulesetComponents, rules }) => {
            const messages: ValidationMessage[] = [];

            rulesetComponents
                .flatMap(rc => rc.sections)
                .flatMap(s => s.components)
                .filter(c => (c.type === "date_range" || c.type === "days_range") && 
                            (c.required || rules.includes(c.label || '')))
                .forEach(c => {
                    if (c.type === "date_range") {
                        const startDate = createDateFromString(rule[c.start_key]);
                        const endDate = createDateFromString(rule[c.end_key]);

                        // Validate both dates exist if either is set
                        if ((startDate || endDate) && !(startDate && endDate)) {
                            messages.push({
                                message: `${c.label || c.title} requires both start and end dates`,
                                type: 'error',
                                field: c.key
                            });
                        }

                        // Validate end date is after start date
                        if (startDate && endDate && endDate <= startDate) {
                            messages.push({
                                message: `${c.label || c.title} end date must be after start date`,
                                type: 'error',
                                field: c.key
                            });
                        }
                    } else if (c.type === "days_range") {
                        const startValue = rule[c.start_key];
                        const endValue = rule[c.end_key];

                        // Validate end value is greater than start value
                        if (startValue !== null && endValue !== null && endValue <= startValue) {
                            messages.push({
                                message: `${c.label || c.title} end value must be greater than start value`,
                                type: 'error',
                                field: c.key
                            });
                        }
                    }
                });

            return messages;
        }
    },
    {
        type: 'pricing',
        title: 'Pricing Rules',
        validate: ({ rule, rules, rulesetComponents }) => {
            const messages: ValidationMessage[] = [];
            const pricingRules = new Set(["Pricing", "Comp", "Occupancy", "Pacing", "Builds"]);

            // Find all selected components that are either in pricing sections or under Price section
            let  pricingComponentLabels = new Set([] as string[]);

            rulesetComponents.forEach((rc: ReblComponent) => {
                rc.sections.forEach((s: ReblSection) => {
                    s.components.forEach((c: any) => {
                        if (
                            rule[c.key] &&
                            rc.type === "rules" &&
                            (
                                pricingRules.has(rc.title) ||
                                s.title === "Price"
                            )
                        ) {
                            pricingComponentLabels.add(rc.title)
                        }
                    })
                })
            });

            if (pricingComponentLabels.has("Pricing")) {
                if (pricingComponentLabels.size === 1 && rule.wipeable) {
                    messages.push({
                        message: "Pricing-only rules cannot be wipeable",
                        type: 'warning',
                        field: 'wipeable'
                    });
                }
                else if (pricingComponentLabels.size > 1 && !rule.wipeable) {
                    messages.push({
                        message: "Pricing rules must be wipeable when mixed with other REBL conditions",
                        type: 'warning',
                        field: 'wipeable'
                    });
                }
            }

            return messages;
        }
    },
    {
        type: 'location',
        title: 'Location Rules',
        validate: ({ rule, rules, rulesetComponents, currentUser }) => {
            const messages: ValidationMessage[] = [];

            // Check if there are any required field errors first
            const missingRequiredFields = rulesetComponents
                .flatMap(rc => rc.sections)
                .flatMap(s => s.components)
                .filter(c => c.required)
                .filter(c => c.type !== 'boolean')
                .filter(c => {
                    if (c.type === "date_range") {
                        return !rule[c.start_key] && !rule[c.end_key];
                    }
                    return !rule[c.key];
                });

            if (missingRequiredFields.length > 0) {
                return messages;
            }

            // Check locations
            const locations = new Set<string>();
            rulesetComponents
                .flatMap(rc => rc.sections)
                .filter(s => s.title === "Locations")
                .flatMap(s => s.components)
                .forEach(c => {
                    if (c.key !== "excluded_units" && !!rule[c.key]) {
                        locations.add(c.key);
                    }
                });

            if (locations.size === 0) {
                messages.push({
                    message: currentUser?.approver
                        ? "No geography selected - This is a global rule"
                        : "No geography selected - Only admins can create global rules",
                    type: currentUser?.approver ? 'warning' : 'error',
                    field: 'location'
                });
            } else if (locations.size > 1) {
                messages.push({
                    message: `Only use one location type. Found: ${Array.from(locations).join(", ")}`,
                    type: 'error',
                    field: 'location'
                });
            }

            return messages;
        }
    },
    {
        type: 'tether',
        title: 'Tether Validation',
        validate: ({ rule }) => {
            const messages: ValidationMessage[] = [];
            
            if (rule.action?.toLowerCase().includes('tether')) {
                if (!rule.included_units || 
                    (Array.isArray(rule.included_units) && rule.included_units.length === 0)) {
                    messages.push({
                        message: "Tether actions require units to be selected",
                        type: 'error',
                        field: 'included_units'
                    });
                }
            }
            
            return messages;
        }
    }
];

// Add interface for unit IDs
interface TetherOption {
    label: string;
    value: number;
    unitIds: number[];
}

// Add this near other component interfaces
interface TetherDialogProps {
    open: boolean;
    onClose: () => void;
    onSelect: (units: number[]) => void;
    tetherData?: UnitTetherAnchor[];
}

// Update the TetherDialog component
const TetherDialog: React.FC<TetherDialogProps> = ({ open, onClose, onSelect, tetherData }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Select Tethered Units</DialogTitle>
            <DialogContent>
                <Autocomplete<TetherOption, true>
                    multiple
                    options={tetherData?.map((anchor: UnitTetherAnchor) => ({
                        label: `ID: ${anchor.id} - ${anchor.title} (${anchor.unit_code}) - ${anchor.tethers.length} units`,
                        value: anchor.id,
                        unitIds: anchor.tethers.map((child: UnitTetherChild) => child.unit_id)
                    })) || []}
                    style={{ width: '100%' }}
                    onChange={(_, newValue: TetherOption[]) => {
                        const units = newValue.flatMap(v => v.unitIds);
                        onSelect(units);
                        onClose();
                    }}
                    getOptionLabel={(option: TetherOption) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Search by title, ID, or unit code..."
                        />
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const createDateFromString = (dateString: string | null) => {
    if (!dateString) return null;
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);  // month is 0-based in Date constructor
};

export const ReblRulesetModal: React.FC<ReblRulesetModalProps> = (props) => {
    const {
        selectedReblRule,
        cohorts,
        formType,
        closeModal,
        // onUpdateReblRule,
        currentUser,
        locationOptions,
        isFetchingLocationOptions
    } = props
    const {data: reblOptions, isFetching: isFetchingReblOptions} = useGetReblOptionsQuery();
    const { data: tetherData } = useGetUnitTetherTableQuery();
    const [state, dispatch] = useReducer(reblModalReducer, initialState);
    const [tetherDialogOpen, setTetherDialogOpen] = useState(false);
    const [validation, setValidation] = useState<ValidationState>({ messages: [], hasErrors: false });
    const [expandedSections, setExpandedSections] = useState<ExpandedSections>({});
    const [expandedCategories, setExpandedCategories] = useState<ExpandedCategories>({});

    const {
        isLoading,
        isCompoundable,
        disableSave,
        isSaving,
        rules,
        rulesetComponents,
        updatedRule,
        validation: reblValidation,
        ruleFilter
    } = state;

    // Add form ref
    const formRef = useRef<HTMLDivElement>(null);

    // Add scroll handler function
    const scrollToSection = (sectionTitle: string) => {
        const sectionId = `section-${sectionTitle.replace(/\s+/g, '-').toLowerCase()}`;
        // Add small delay to ensure DOM is updated
        setTimeout(() => {
            const element = document.getElementById(sectionId);
            if (element && formRef.current) {
                const formRect = formRef.current.getBoundingClientRect();
                formRef.current.scrollTo({
                    top: element.offsetTop - formRect.top,
                    behavior: 'smooth'
                });
            }
        }, 100);
    };

    // endpoints
    const [addRuleset] = useAddReblRuleMutation();
    const [updateRuleset] = useUpdateReblRuleMutation();
    const todayStr = new Date().toISOString().split("T")[0];
    const today = format(createDateFromString(todayStr)!, DATE_FORMAT);
    const tomorrow = format(addDays(createDateFromString(todayStr)!, 1), DATE_FORMAT);

    const getValue = (key: string, type?: string) => {
        if (key === "category") {
            const categories = UiUtils.levelCategories.sort((a, b) => a.threshold - b.threshold);
            const priority = !!updatedRule["priority"] ? updatedRule["priority"] : 1;
            let selectedCategory = categories[0].category
            for (const category of categories) {
                if (priority >= category.threshold) {
                    selectedCategory = category.category
                }
            }
            return selectedCategory
        }

        if (!!updatedRule && updatedRule[key] !== undefined && updatedRule[key] !== null) {
            if (type === "date") {
                return createDateFromString(updatedRule[key])
            }
            else if (type === "geography") {
                const multiSelectValue = updatedRule[key].split(',').map((k: string) => UiUtils.getSelectOption(k));
                if (multiSelectValue.length === 1 && multiSelectValue[0]?.value === "") return [];
                return multiSelectValue;
            }
            else if (type === "select_id") {
                const matchingKey = Object.keys(UiUtils.reblOptionsMap).find(k =>
                    UiUtils.reblOptionsMap[k].key === key.replace("_id", "")
                );
                
                if (matchingKey && reblOptions) {
                    const optionMap = UiUtils.reblOptionsMap[matchingKey];
                    const activeOptions = reblOptions[optionMap.plural].filter((o: any) => o.active);
                    const selectedOption = activeOptions.find(o => o.id === updatedRule[key]);
                    
                    if (!selectedOption) {
                        const updatedRuleCopy = { ...updatedRule };
                        updatedRuleCopy[key] = null;
                        dispatch({ type: 'SET_UPDATED_RULE', payload: updatedRuleCopy });
                        return null;
                    }
                    return {
                        value: selectedOption.id,
                        display: selectedOption[optionMap.key]
                    };
                }
                
                return null;
            }
            return updatedRule[key];
        }
        else if (type === "geography") return [];
        else if (type === "select_id") return null;
        else if (type === "select") return "";
        else return "";
    }

    const setValue = (key: string, value: string | number | boolean, min?: number, max?: number) => {
        let updatedRuleCopy = {...updatedRule}

        if(!!min && value < min) value = min;
        if(!!max && value > max) value = max;

        if (key === "category") {
            for (const category of UiUtils.levelCategories) {
                if (value === category.category) {
                    updatedRuleCopy["priority"] = category.threshold
                }
            }
        }
        else if (key === "priority") {
            for (const category of UiUtils.levelCategories.sort((a, b) => a.threshold - b.threshold)) {
                if (value >= category.threshold) {
                    updatedRuleCopy["category"] = category.category;
                }
            }
        }

        updatedRuleCopy[key] = value
        dispatch({ type: 'SET_UPDATED_RULE', payload: updatedRuleCopy })
    }

    const getOptions = (c: any) => {
        let selectOptions: SelectOption[];

        if (c.hasOwnProperty("options")) {
            selectOptions = c.options;
        } else if (c.type === "select_id") {
            const matchingKey = Object.keys(UiUtils.reblOptionsMap).filter(k => UiUtils.reblOptionsMap[k].key === c.key.replace("_id", ""))[0];
            if (!matchingKey) return [];

            const optionMap = UiUtils.reblOptionsMap[matchingKey];
            let options = reblOptions[optionMap.plural].filter((o: any) => o.active);
            if (optionMap.extras) {
                optionMap.extras.forEach((column: string) => {
                    if (column === "compoundable") {
                        options = options.filter((o: any) => isCompoundable ? o.compoundable : true);
                    }
                });
            }
            selectOptions = options.map((o: any) => ({
                value: o.id,
                display: o[optionMap.key]
            }));
            if (!optionMap.required) {
                selectOptions.unshift({
                    value: '',
                    display: '(unset)'
                });
            }
        }

        if (c.type === "geography") {
            const values = getValue(c.key, c.type)
            selectOptions = selectOptions.filter((o: SelectOption) => {
                const so = o.value.toString().toUpperCase();
                let available = true;
                values.map((v: SelectOption) => v.value).forEach((v: string) => {
                    if (so === v?.toUpperCase()) {
                        available = false;
                        return
                    }
                });
                return available;
            })
        }

        return selectOptions;
    }

    const handleDateChange = async (value: Date | null, dateType: string, start_key: string, end_key: string) => {
        // Return early if value is null or invalid
        if (!value || isNaN(value.getTime())) {
            return;
        }

        let updatedRuleCopy = {...updatedRule}

        if (dateType === "start") {
            updatedRuleCopy[start_key] = format(new Date(value), DATE_FORMAT)

            let endDate = createDateFromString(updatedRule[end_key])
            // Usually start is picked first. If so, check the end date and push it out
            if (value > endDate) {
                endDate = value;
                updatedRuleCopy[end_key] = format(endDate, DATE_FORMAT)
            }
        }
        else {
            updatedRuleCopy[end_key] = format(new Date(value), DATE_FORMAT)
        }
        dispatch({ type: 'SET_UPDATED_RULE', payload: updatedRuleCopy })
    }

    const handleDayChange = async (value: number, dayType: string, start_key: string, end_key: string, min: number, max: number) => {
        // Handle empty/invalid values
        if (isNaN(value)) {
            let updatedRuleCopy = {...updatedRule};
            updatedRuleCopy[dayType === "start" ? start_key : end_key] = null;
            dispatch({ type: 'SET_UPDATED_RULE', payload: updatedRuleCopy });
            return;
        }
        
        if (typeof min === 'number' && value < min) value = min;
        if (typeof max === 'number' && value > max) value = max;
        
        let updatedRuleCopy = {...updatedRule};
        let endValue = updatedRule[end_key];
        if (dayType === "start") {
            updatedRuleCopy[start_key] = value;

            // Usually start is picked first. If so, check the end value and push it out
            if (value >= endValue) {
                endValue = value + 1;
                // Ensure end value doesn't exceed max
                if (endValue > max) endValue = max;
                updatedRuleCopy[end_key] = endValue;
            }
        }
        else {
            updatedRuleCopy[end_key] = value;
        }

        dispatch({ type: 'SET_UPDATED_RULE', payload: updatedRuleCopy });
    }

    const changeCheckedRules = (component: ExtendedReblComponent) => {
        const updatedRules = [...rules];
        const index = updatedRules.indexOf(component.label || '');
        
        if (index >= 0) {
            // Remove rule
            updatedRules.splice(index, 1);
            
            // Clear related fields in updatedRule
            const updatedRuleCopy = { ...updatedRule };
            if (component.type === "date_range" || component.type === "days_range") {
                updatedRuleCopy[component.start_key] = null;
                updatedRuleCopy[component.end_key] = null;
            } else {
                updatedRuleCopy[component.key] = null;
            }
            
            dispatch({ type: 'SET_UPDATED_RULE', payload: updatedRuleCopy });
        } else {
            // Add rule
            updatedRules.push(component.label || '');
        }
        
        dispatch({ type: 'SET_RULES', payload: updatedRules });
    };

    const handleSave = async () => {
        let updatedRuleCopy = {...updatedRule};

        rulesetComponents.forEach((rc: ReblComponent) => {
            rc.sections.forEach((s: ReblSection) => {
                s.components.forEach((c: any) => {
                    if (["date_range", "days_range"].indexOf(c.type) >= 0) {
                        if (!updatedRuleCopy[c.start_key]) updatedRuleCopy[c.start_key] = null;
                        if (!updatedRuleCopy[c.end_key]) updatedRuleCopy[c.end_key] = null;
                    }
                    else if (c.type === "boolean") updatedRuleCopy[c.key] = !!updatedRuleCopy[c.key];
                    else if (updatedRuleCopy[c.key] === "" || updatedRule[c.key] === undefined) updatedRuleCopy[c.key] = null;
                })
            })
        })
        Object.keys(updatedRuleCopy).forEach((key) => {
            if (updatedRuleCopy[key] === "") {
                updatedRuleCopy[key] = null;
            }
        });

        dispatch({ type: 'SET_IS_SAVING', payload: true });

        if (formType === DemandInfluenceFormOptions.EDIT) {
            await updateRuleset({
                id: selectedReblRule.id,
                data: updatedRuleCopy
            }).then((response) => {
                dispatch({ type: 'SET_IS_SAVING', payload: false });
                if (!!response["error"]) {
                    console.log(response["error"]);
                }
                else {
                    closeModal(true);
                }
            })
        }
        else { // ADD and COPY
            await addRuleset(updatedRuleCopy).then((response) => {
                dispatch({ type: 'SET_IS_SAVING', payload: false });
                if (!!response["error"]) {
                    console.log(response["error"]);
                }
                else {
                    closeModal(true);
                }
            })
        }
    }

    const cancelButton: ButtonProps = {
        onClick: () => {
            closeModal()
        },
        children: "Cancel",
        variant: "outlined",
        color: "default",
        className: 'button-group'
    }

    const saveButton: ButtonProps = {
        onClick: handleSave,
        children: isSaving ? <CircularProgress size={24} /> : "Save",
        variant: "contained",
        color: "primary",
        disabled: disableSave || isSaving || validation.hasErrors,
        className: 'button-group'
    }

    useEffect(() => {
        if (
            !!locationOptions &&
            !isFetchingLocationOptions &&
            !!reblOptions &&
            !isFetchingReblOptions
        ) {
            dispatch({ type: 'SET_RULESET_COMPONENTS', payload: UiUtils.getRulesetComponents(cohorts, locationOptions) })
        }
    }, [isFetchingLocationOptions, locationOptions, isFetchingReblOptions, reblOptions])

    useEffect(() => {
        if (updatedRule && !isFetchingReblOptions && rulesetComponents) {
            // Handle compoundable state
            if (updatedRule.compoundable !== undefined && isCompoundable !== updatedRule.compoundable) {
                dispatch({ type: 'SET_COMPOUNDABLE', payload: !isCompoundable });
            }

            // Run validation with current values instead of full state
            const messageObj = validationContexts.reduce((acc, context) => {
                const contextMessages = context.validate({
                    rule: updatedRule,
                    rules,
                    rulesetComponents,
                    currentUser
                });
                acc.messages.push(...contextMessages);
                return acc;
            }, { messages: [] as ValidationMessage[], hasErrors: false });

            // Set hasErrors based on messages
            messageObj.hasErrors = messageObj.messages.some(m => m.type === 'error');

            dispatch({
                type: 'SET_VALIDATION',
                payload: messageObj
            });
        }
    }, [updatedRule, isFetchingReblOptions, rulesetComponents, currentUser, rules, isCompoundable]);

    useEffect(() => {
        if (!!selectedReblRule) dispatch({ type: 'SET_UPDATED_RULE', payload: selectedReblRule })
        else {
            // setUpdatedRule(new ReblRuleset())
        }
    }, [selectedReblRule]);

    useEffect(() => {
        if (!!rulesetComponents && rulesetComponents.length > 0 && (!!updatedRule || !selectedReblRule)) {
            if (isLoading) {
                let tempRules = [];
                let updatedRuleCopy = {...updatedRule};

                rulesetComponents.forEach((rc) => {
                    rc.sections.forEach((s: ReblSection) => {
                        s.components.forEach((c: any) => {
                            if(!selectedReblRule) {
                                if (["date_range"].indexOf(c.type) >= 0) {
                                    updatedRuleCopy[c.start_key] = today;
                                    updatedRuleCopy[c.end_key] = tomorrow;
                                }
                                else if (!!c.default) {
                                    updatedRuleCopy[c.key] = c.default;
                                }

                                if (c.required || !!c.default) tempRules.push(c.label);
                            }
                            else if (rc.type === "rules") {
                                if (c.required) {
                                    tempRules.push(c.label);
                                } else if (c.key === 'included_units' && updatedRule?.action?.toLowerCase().includes('tether')) {
                                    tempRules.push(c.label);
                                } else if (["date_range", "days_range"].indexOf(c.type) >= 0) {
                                    if (!!updatedRule[c.start_key]) tempRules.push(c.label)
                                } else {
                                    if (updatedRule[c.key] !== "") tempRules.push(c.label);
                                }
                            }
                        })
                    })
                });

                dispatch({
                    type: 'INITIALIZE_FORM',
                    payload: {
                        rulesetComponents,
                        rules: tempRules,
                        updatedRule: !selectedReblRule ? updatedRuleCopy : updatedRule
                    }
                });
                dispatch({ type: 'SET_LOADING', payload: false });
            }
        }
    }, [rulesetComponents, updatedRule]);

    useEffect(() => {
        return () => {
            dispatch({ type: 'RESET_FORM' });
        };
    }, []);

    useEffect(() => {
        // Don't show validation while loading or if rule isn't loaded yet
        if (!updatedRule || isLoading || isFetchingReblOptions) {
            setValidation({
                messages: [],
                hasErrors: true  // Keep form invalid while loading
            });
            return;
        }

        // Run all validations
        const allMessages = validationContexts.flatMap(context => 
            context.validate({
                rule: updatedRule,
                rules,
                rulesetComponents,
                currentUser
            })
        );

        // Separate errors and warnings
        const errors = allMessages.filter(m => m.type === 'error');
        const warnings = allMessages.filter(m => m.type === 'warning');

        setValidation({
            messages: [...errors, ...warnings],
            hasErrors: errors.length > 0
        });
    }, [updatedRule, rules, rulesetComponents, currentUser, isLoading, isFetchingReblOptions]);

    useEffect(() => {
        if (updatedRule?.action?.toLowerCase().includes('tether')) {
            // Find the Include Units component
            const includeUnitsComponent = rulesetComponents
                .flatMap(rc => rc.sections)
                .flatMap(s => s.components)
                .find(c => c.key === 'included_units');

            if (includeUnitsComponent?.label && !rules.includes(includeUnitsComponent.label)) {
                changeCheckedRules(includeUnitsComponent);
            }
        }
    }, [updatedRule?.action, rulesetComponents, rules]);

    const getComponent = (c: ExtendedReblComponent) => {
        const fieldErrors = validation.messages.filter(m => {
            if (c.type === 'days_range') {
                // For days_range, only show validation on the specific field that's empty
                return m.field === c.start_key || m.field === c.end_key;
            }
            // For all other fields, only show validation for this specific field
            return m.field === c.key;
        });

        const hasError = fieldErrors.some(m => m.type === 'error');
        const hasWarning = fieldErrors.some(m => m.type === 'warning');

        const inputClassName = `${hasError ? 'has-error' : ''} ${hasWarning && !hasError ? 'has-warning' : ''} ${c.type === "textarea" ? 'rebl-textarea' : ''}`.trim();

        if (c.key === "included_units") {
            const isTetherAction = updatedRule?.action?.toLowerCase().includes('tether');
            return (
                <div className="rebl-input-group" key={c.key} data-field-id={c.key}>
                    {!isTetherAction && (
                        <div className="remove-rule">
                            <IconButton
                                size="small"
                                onClick={() => changeCheckedRules(c)}
                                title="Remove rule"
                            >
                                <Icon.Close fontSize="small" />
                            </IconButton>
                        </div>
                    )}

                    <div className="label-container">
                        {(c.required || isTetherAction) && <span className="required-indicator">*</span>}
                        <label>
                            {c.label || c.title}
                            {isTetherAction && (
                                <Tooltip title="Units are required for tether actions">
                                    <Icon.Info fontSize="small" style={{ marginLeft: '4px', color: '#dc3545' }} />
                                </Tooltip>
                            )}
                            {isTetherAction && (
                                <Tooltip title="Add units from tether rule">
                                    <IconButton 
                                        onClick={() => setTetherDialogOpen(true)}
                                        size="small"
                                        style={{ padding: '4px', marginLeft: '4px' }}
                                    >
                                        <Icon.Link fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {c.tooltip && (
                                <Tooltip title={c.tooltip}>
                                    <Icon.HelpOutline fontSize="small" />
                                </Tooltip>
                            )}
                        </label>
                    </div>
                    <div className={`rebl-field ${inputClassName}`}>
                        <TextareaAutosize
                            rowsMin={4}
                            rowsMax={16}
                            value={getValue(c.key)}
                            onChange={(e) => setValue(c.key, e.target.value)}
                            placeholder={c.placeholder}
                            className={inputClassName}
                            style={{ flex: 1 }}
                        />
                    </div>
                    <TetherDialog
                        open={tetherDialogOpen}
                        onClose={() => setTetherDialogOpen(false)}
                        onSelect={(units) => {
                            const currentUnits = getValue(c.key) || '';
                            // Filter out empty strings and clean up the arrays
                            const existingUnits = currentUnits.split(',').filter(u => u.trim());
                            const newUnits = Array.from(new Set([...existingUnits, ...units]))
                                .filter(Boolean)
                                .join(',');
                            setValue(c.key, newUnits);
                        }}
                        tetherData={tetherData}
                    />
                </div>
            );
        }

        if (c.type === "priority") {
            return (
                <>
                <div className="rebl-input-group" key={`${c.key}-priority`} data-field-id={`${c.key}-priority`}>
                    <div className="label-container">
                        {c.required && <span className="required-indicator">*</span>}
                            <label>{c.label || c.title}</label>
                    </div>
                    <div className="rebl-field">
                        <TextField
                            type="number"
                            value={getValue(c.key)}
                            onChange={(e) => setValue(c.key, parseInt(e.target.value), c.min, c.max)}
                            variant="outlined"
                            placeholder={c.placeholder}
                            className={inputClassName}
                            disabled={c.disabled}
                            style={{ width: '450px' }}
                        />
                    </div>
                </div>
                <div className="rebl-input-group" key={`${c.key}-category`} data-field-id={`${c.key}-category`}>
                    <div className="label-container">
                        <label>Category</label>
                    </div>
                    <div className="rebl-field">
                        <MuiSelect
                            value={getValue("category")}
                            className={inputClassName}
                            onChange={(e) => setValue("category", e.target.value as string)}
                            variant="outlined"
                            style={{ width: '450px' }}
                        >
                            {UiUtils.levelCategories.map((category) => (
                                <MenuItem key={category.category} value={category.category}>
                                    {category.category} (Priority ≥ {category.threshold})
                                </MenuItem>
                            ))}
                        </MuiSelect>
                    </div>
                </div>
                </>
            );
        }

        return (
            <div 
                className="rebl-input-group"
                data-field-id={c.key || c.start_key || c.end_key}
            >
                {!c.required && !c.default && !rulesetComponents.find(rc => rc.type === "metadata")?.sections.some(s => s.components.includes(c)) && (
                    <div className="remove-rule">
                        <IconButton
                            size="small"
                            onClick={() => changeCheckedRules(c)}
                            title="Remove rule"
                        >
                            <Icon.Close fontSize="small" />
                        </IconButton>
                    </div>
                )}

                <div className="label-container">
                    {c.required && <span className="required-indicator">*</span>}
                    <label>
                        {c.label}
                        {c.tooltip && (
                            <Tooltip title={c.tooltip} placement="top">
                                <Icon.HelpOutline />
                            </Tooltip>
                        )}
                    </label>
                </div>

                <div className={`rebl-field ${inputClassName}`}>
                    {fieldErrors.length > 0 && (
                        <div className="field-validation-messages"></div>
                    )}
                    {c.type === "text" && (
                        <TextField
                            className={inputClassName}
                            value={getValue(c.key)}
                            onChange={(e) => setValue(c.key, e.target.value)}
                            disabled={c.disabled}
                            variant="outlined"
                            size="small"
                            fullWidth
                            label={c.label}
                            required={c.required}
                            helperText={c.tooltip}
                        />
                    )}
                    {c.type === "boolean" &&
                        <Checkbox
                            checked={getValue(c.key)}
                            onChange={(e) => setValue(c.key, !getValue(c.key))}
                        />
                    }
                    {(c.type === "number" || c.type === "priority") && (
                        <TextField
                            className={inputClassName}
                            value={getValue(c.key)}
                            type="number"
                            inputProps={{
                                min: c.min,
                                max: c.max,
                                step: c.step
                            }}
                            disabled={c.disabled}
                            onChange={(e) => {
                                const input = e.target as HTMLInputElement;
                                setValue(c.key, input.valueAsNumber, c.min, c.max);
                            }}
                            label={c.label}
                            required={c.required}
                            helperText={c.tooltip || `Between ${c.min} and ${c.max}`}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    )}
                    {c.type === "select" &&
                        <MuiSelect
                            className={inputClassName}
                            value={getValue(c.key, c.type)}
                            onChange={(e) => setValue(c.key, e.target.value as string)}
                            disabled={c.disabled}
                            variant="outlined"
                        >
                            {getOptions(c).map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.display}
                                </MenuItem>
                            ))}
                        </MuiSelect>
                    }
                    {c.type === "textarea" && (
                        <TextareaAutosize
                            rowsMin={4}
                            rowsMax={16}
                            value={getValue(c.key)}
                            onChange={(e) => setValue(c.key, e.target.value)}
                            placeholder={c.placeholder}
                            className={inputClassName}
                            style={{flex: 1}}
                            disabled={c.disabled}
                        />
                    )}
                    {c.type === "date_range" && (
                        <div className="date-range-group">
                            <KeyboardDatePicker
                                variant="inline"
                                inputVariant="outlined"
                                value={getValue(c.start_key, "date")}
                                onChange={(date) => handleDateChange(date, "start", c.start_key, c.end_key)}
                                format={DATE_FORMAT}
                                autoOk={false}
                                disableToolbar
                                invalidDateMessage="Invalid date format"
                                maxDateMessage="Date should not be after maximum date"
                                minDateMessage="Date should not be before minimum date"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            <span>to</span>
                            <KeyboardDatePicker
                                value={getValue(c.end_key, "date")}
                                onChange={(date) => handleDateChange(date, "end", c.start_key, c.end_key)}
                                format={DATE_FORMAT}
                                variant="inline"
                                inputVariant="outlined"
                                autoOk={false}
                                disableToolbar
                                invalidDateMessage="Invalid date format"
                                maxDateMessage="Date should not be after maximum date"
                                minDateMessage="Date should not be before minimum date"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </div>
                    )}
                    {c.type === "days_range" && (
                        <div className="date-range-group">
                            <TextField
                                className={inputClassName}
                                value={getValue(c.start_key)}
                                type="number"
                                inputProps={{
                                    min: c.min,
                                    max: c.max,
                                    step: c.step
                                }}
                                disabled={c.disabled}
                                onChange={(e) => {
                                    const input = e.target as HTMLInputElement;
                                    handleDayChange(
                                        input.valueAsNumber,
                                        "start",
                                        c.start_key,
                                        c.end_key,
                                        c.min,
                                        c.max
                                    );
                                }}
                                variant="outlined"
                                size="small"
                            />
                            <span>to</span>
                            <TextField
                                className={inputClassName}
                                value={getValue(c.end_key)}
                                type="number"
                                inputProps={{
                                    min: c.min,
                                    max: c.max,
                                    step: c.step
                                }}
                                disabled={c.disabled}
                                onChange={(e) => {
                                    const input = e.target as HTMLInputElement;
                                    handleDayChange(
                                        input.valueAsNumber,
                                        "end",
                                        c.start_key,
                                        c.end_key,
                                        c.min,
                                        c.max
                                    );
                                }}
                                variant="outlined"
                                size="small"
                            />
                        </div>
                    )}
                    {c.type === "geography" &&
                        <div className={inputClassName}>
                            <GeographySelector
                                label={c.label || c.title}
                                options={getOptions(c).map(opt => opt.value.toString())}
                                value={getValue(c.key)}
                                onChange={(value) => setValue(c.key, value)}
                            />
                        </div>
                    }
                    {c.type === "select_id" &&
                        <div className={inputClassName}>
                            <Autocomplete
                                className={inputClassName}
                                options={getOptions(c)}
                                getOptionLabel={(option: SelectOption) => option?.display || ''}
                                getOptionSelected={(option, value) => option?.value === value?.value}
                                multiple={false}
                                filterSelectedOptions={true}
                                value={getValue(c.key, c.type)}
                                onChange={(e, v) => setValue(c.key, v?.value)}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" placeholder="Type to filter..."/>
                                )}
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }

    const ruleGroups = useMemo(() => organizeRules(rulesetComponents), [rulesetComponents]);

    const scrollToField = (fieldId: string) => {
        // Special handling for location warning
        if (fieldId === 'location') {
            // Expand both the category and section
            const categoryTitle = 'unit attributes';
            const sectionTitle = 'Locations';

            // Expand the category
            setExpandedCategories(prev => ({
                ...prev,
                [categoryTitle]: true
            }));
            
            // Expand the section
            setExpandedSections(prev => ({
                ...prev,
                [sectionTitle]: true
            }));

            // Scroll to the Location section after a brief delay
            setTimeout(() => {
                const locationSection = document.querySelector('#section-locations') as HTMLElement;
                if (locationSection && formRef.current) {
                    const formRect = formRef.current.getBoundingClientRect();
                    formRef.current.scrollTo({
                        top: locationSection.offsetTop - formRect.top - 20,
                        behavior: 'smooth'
                    });
                }
            }, 100);
        }
        
        // Find the component that contains this field
        const component = rulesetComponents
            .flatMap(rc => rc.sections)
            .flatMap(s => s.components)
            .find(c => 
                c.key === fieldId || 
                c.start_key === fieldId || 
                c.end_key === fieldId
            );
        
        if (component && formRef.current) {
            const element = document.querySelector(`[data-field-id="${fieldId}"]`) as HTMLElement;
            if (element) {
                const formRect = formRef.current.getBoundingClientRect();
                formRef.current.scrollTo({
                    top: element.offsetTop - formRect.top - 20,
                    behavior: 'smooth'
                });
                
                // Optional: Add a brief highlight effect
                element.classList.add('highlight');
                setTimeout(() => element.classList.remove('highlight'), 2000);
            }
        }
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
                <div className="form-header">
                    <h4>Ruleset</h4>
                    <Button
                        onClick={() => closeModal(false)}
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            minWidth: 'auto',
                            padding: '6px'
                        }}
                    >
                        <Icon.Close />
                    </Button>
                </div>
                <div className="form-body rebl-body">
                    {isLoading ? (
                        <div className="loading-container">
                            <CircularProgress />
                            <Typography variant="body1" style={{ marginTop: 16 }}>
                                Loading Ruleset...
                            </Typography>
                        </div>
                    ) : (
                        <div className="rebl-row">
                            <RulesSidebar
                                ruleGroups={ruleGroups}
                                selectedRules={rules}
                                onToggleRule={changeCheckedRules}
                                filter={ruleFilter}
                                onFilterChange={(value) => dispatch({ type: 'SET_FILTER', payload: value })}
                                onSectionClick={scrollToSection}
                                expandedSections={expandedSections}
                                setExpandedSections={setExpandedSections}
                                expandedCategories={expandedCategories}
                                setExpandedCategories={setExpandedCategories}
                            />
                            <div className="rebl-form" ref={formRef}>
                                {ruleGroups.map((group) => {
                                    const visibleSections = group.sections.filter(section => 
                                        section.components.some(c => group.required || rules.includes(c.label || ''))
                                    );

                                    return visibleSections.length > 0 ? (
                                        <Paper key={group.title} className="form-section">
                                            <Typography variant="h6">{group.title}</Typography>
                                            {visibleSections.map(section => (
                                                <div 
                                                    key={section.title} 
                                                    className="rule-section"
                                                    id={`section-${section.title.replace(/\s+/g, '-').toLowerCase()}`}
                                                >
                                                    {section.components
                                                        .filter(c => group.required || rules.includes(c.label || ''))
                                                        .map(c => getComponent(c))}
                                                </div>
                                            ))}
                                        </Paper>
                                    ) : null;
                                })}
                            </div>
                        </div>
                    )}
                </div>
                <div className="form-footer">
                    <div className="validation-container">
                        <ValidationMessages 
                            messages={validation.messages} 
                            onMessageClick={scrollToField} 
                        />
                    </div>
                    <div className="form-footer-buttons">
                        <Button {...cancelButton} />
                        <Button {...saveButton} />
                    </div>
                </div>
            </div>
        </MuiPickersUtilsProvider>
    );
};
