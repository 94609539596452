import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {Configuration} from '../Configuration';
import {
    apiAdmin,
    apiCohort,
    apiDateList,
    apiDemandInfluence,
    apiDemandDetail,
    apiPromotions,
    apiUnit,
    apiRate,
    apiRebl,
    apiTables,
    apiRatePlans,
    apiUnitNightLists,
} from './api';
import adminReducer from '../store/admin';
import cohortReducer from '../store/cohort';
import dateListReducer from '../store/dateLists';
import demandInfluenceReducer from '../store/demandInfluence';
import demandDetailReducer from '../store/demandDetail';
import promotionsReducer from '../store/promotions';
import rateReducer from '../store/rates';
import reblReducer from '../store/rebl';
import tablesReducer from '../store/tables';
import unitReducer from '../store/units';
import ratePlansReducer from '../store/ratePlans';

const rootReducer = combineReducers({
    [apiAdmin.reducerPath]: apiAdmin.reducer,
    [apiCohort.reducerPath]: apiCohort.reducer,
    [apiDateList.reducerPath]: apiDateList.reducer,
    [apiDemandInfluence.reducerPath]: apiDemandInfluence.reducer,
    [apiDemandDetail.reducerPath]: apiDemandDetail.reducer,
    [apiPromotions.reducerPath]: apiPromotions.reducer,
    [apiRatePlans.reducerPath]: apiRatePlans.reducer,
    [apiRebl.reducerPath]: apiRebl.reducer,
    [apiTables.reducerPath]: apiTables.reducer,
    [apiUnit.reducerPath]: apiUnit.reducer,
    [apiRate.reducerPath]: apiRate.reducer,
    [apiUnitNightLists.reducerPath]: apiUnitNightLists.reducer,
    "adminList": adminReducer,
    "cohorts": cohortReducer,
    "dateList": dateListReducer,
    "demandInfluences": demandInfluenceReducer,
    "demandDetails": demandDetailReducer,
    "promotions": promotionsReducer,
    "reblRulesets": reblReducer,
    "tables": tablesReducer,
    "units": unitReducer,
    "rates": rateReducer,
    "ratePlans": ratePlansReducer,
});

export const store = configureStore({
    devTools: !Configuration.isProduction,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
        apiCohort.middleware,
        apiPromotions.middleware,
        apiRebl.middleware,
        apiUnit.middleware,
        apiUnitNightLists.middleware,
    ]),
});

export type AppState = ReturnType<typeof store.getState>;
