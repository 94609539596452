import { RatePlan } from '../types';
import { apiRatePlans } from './api';
import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  EntityState,
} from '@reduxjs/toolkit';
import { AppState } from './store';
import { RatePlanOption } from '../types/RatePlanOption';

const ratePlansAdapter = createEntityAdapter<RatePlan>({
  selectId: (ratePlan) => ratePlan.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export interface RatePlanRequest
  extends Pick<
    RatePlan,
    | 'name'
    | 'active'
    | 'rate_plan_option_id'
    | 'description'
    | 'always_on'
    | 'active_date_start'
    | 'active_date_end'
    | 'unit_night_list_id'
    | 'priority'
  > {}

export interface UpdateRatePlanRequest extends RatePlanRequest {
  id: number;
}

export interface RatePlanOptionRequest
  extends Pick<
    RatePlanOption,
    | 'name'
    | 'active'
    | 'days_from_checkin'
    | 'discount_percentage'
    | 'vacasa'
    | 'airbnb'
    | 'vrbo'
    | 'bdc'
    | 'expedia'
    | 'mybookingpal'
  > {}

export interface UpdateRatePlanOptionRequest extends RatePlanOptionRequest {
  id: number;
}

const initialState: EntityState<RatePlan> = ratePlansAdapter.getInitialState();

export const ratePlansApi = apiRatePlans.injectEndpoints({
  endpoints: (builder) => ({
    getAllRatePlans: builder.query<RatePlan[], void>({
      query: () => 'rate_plans/',
    }),
    addRatePlan: builder.mutation<RatePlan, Partial<RatePlan>>({
      query: (body) => ({
        url: `rate_plans/`,
        method: 'POST',
        body: JSON.stringify(body),
      }),
    }),
    updateRatePlan: builder.mutation<
      RatePlan,
      { id: number; data: Partial<RatePlan> }
    >({
      query: ({ id, data }) => ({
        url: `rate_plans/${id}`,
        method: 'PUT',
        body: JSON.stringify(data),
      }),
    }),
    getRatePlanOptions: builder.query<RatePlanOption[], void>({
      query: () => 'rate_plans/options',
    }),
    addRatePlanOption: builder.mutation<
      RatePlanOption,
      Partial<RatePlanOption>
    >({
      query: (body) => ({
        url: `rate_plans/options`,
        method: 'POST',
        body: JSON.stringify(body),
      }),
    }),
    updateRatePlanOption: builder.mutation<
      RatePlanOption,
      { id: number; data: Partial<RatePlanOption> }
    >({
      query: ({ id, data }) => ({
        url: `rate_plans/options/${id}`,
        method: 'PUT',
        body: JSON.stringify(data),
      }),
    }),
  }),
  overrideExisting: false,
});

export const ratePlansSlice = createSlice({
  name: 'ratePlans',
  initialState,
  reducers: {
    merge: (state, action: PayloadAction<RatePlan[]>) => {
      ratePlansAdapter.upsertMany(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      ratePlansApi.endpoints.getAllRatePlans.matchFulfilled,
      (state, { payload }) => {
        ratePlansAdapter.upsertMany(state, payload);
      }
    );
  },
});

export const {
  useGetAllRatePlansQuery,
  useAddRatePlanMutation,
  useUpdateRatePlanMutation,
  useGetRatePlanOptionsQuery,
  useAddRatePlanOptionMutation,
  useUpdateRatePlanOptionMutation,
} = ratePlansApi;

export const ratePlansSelector = (state: AppState) => state.ratePlans;

export default ratePlansSlice.reducer;
