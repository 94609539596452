import React, {useState, useMemo, useEffect} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Checkbox,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    ListSubheader,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    TableSortLabel,
    TablePagination,
    Tooltip,
    IconButton,
    FormControlLabel,
    Switch,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import {
    useAddDateListMutation,
    useUpdateDateListMutation,
    useLinkDatesToParentMutation,
    useGetDateListMutation,
    useRefreshDateListMutation,
} from '../../store';
import { DateList, CurrentUser } from "../../types";
import { format } from 'date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { UiUtils } from "../../utils";

type SortDirection = 'asc' | 'desc';

interface DateListsProps {
    dateLists: DateList[];
    isLoading: boolean;
    refetch: () => void;
    searchQuery: string;
    setSearchQuery: (value: string) => void;
    currentUser: CurrentUser;
}

const formatDate = (date: string | null, forPicker: boolean = false) => {
    if (!date) return forPicker ? null : '-';
    
    // For display, just format the date string
    if (!forPicker) {
        const [year, month, day] = date.split('T')[0].split('-');
        return format(new Date(Number(year), Number(month) - 1, Number(day)), 'yyyy-MM-dd');
    }
    // For date picker, create a date object that won't shift due to timezone
    return new Date(date.split('T')[0] + 'T12:00:00');
    // return  format(new Date(new Date(date).toISOString().split('T')[0]), 'yyyy-MM-dd');
};

const formatMetadataDate = (updatedAt: string | null | undefined, createdAt: string | null | undefined) => {
    if (updatedAt) {
        try {
            return `Last updated: ${format(new Date(updatedAt), 'MMM d, yyyy HH:mm:ss')}`;
        } catch (e) {
            return 'Invalid update date';
        }
    } else if (createdAt) {
        try {
            return `Created: ${format(new Date(createdAt), 'MMM d, yyyy HH:mm:ss')}`;
        } catch (e) {
            return 'Invalid creation date';
        }
    }
    return 'No date available';
};

const formatCreatorTooltip = (created_at: string) => {
    return `Created: ${format(new Date(created_at), 'MM/dd/yyyy HH:mm:ss')}`;
};

const formatUpdaterTooltip = (updated_at: string) => {
    return `Updated: ${format(new Date(updated_at), 'MM/dd/yyyy HH:mm:ss')}`;
};

// Update the DATE_LIST_SOURCES constant to be the source of truth
const DATE_LIST_SOURCES = [
    { value: 'Manual', label: 'Manual' },
    { value: 'generate', label: 'Generate' },
    { value: 'major_us_holidays', label: 'Major US Holidays' },
    { value: 'all_dates', label: 'All Dates' }
] as const;

// Add a helper function to normalize source values
const normalizeSource = (source: string): string => {
    const found = DATE_LIST_SOURCES.find(s => 
        s.value.toLowerCase() === source.toLowerCase()
    );
    return found ? found.value : source;
};

// Add type for day of week config
interface DayOfWeek {
    key: string;
    label: string;
    shortLabel: string;
}

// Add days of week configuration
const daysOfWeek: DayOfWeek[] = [
    { key: 'sunday', label: 'Sunday', shortLabel: 'S' },
    { key: 'monday', label: 'Monday', shortLabel: 'M' },
    { key: 'tuesday', label: 'Tuesday', shortLabel: 'T' },
    { key: 'wednesday', label: 'Wednesday', shortLabel: 'W' },
    { key: 'thursday', label: 'Thursday', shortLabel: 'T' },
    { key: 'friday', label: 'Friday', shortLabel: 'F' },
    { key: 'saturday', label: 'Saturday', shortLabel: 'S' },
];

const isValidStayDateRange = (start: string | null, end: string | null) => {
    if (!start || !end) return true;
    return new Date(start) < new Date(end);
};

// Add helper function to format creator name
const formatCreatorName = (email: string) => {
    return email ? email.split('@')[0] : '-';
};

// Add helper function to format date ranges
const formatDateRange = (list: DateList): string => {
    // Check for stay dates first
    if (list.stay_date_start && list.stay_date_end) {
        return `${formatDate(list.stay_date_start)} to ${formatDate(list.stay_date_end)}`;
    }
    
    // Then check for days out
    if (list.days_out_start !== null && 
        list.days_out_end !== null && 
        (list.days_out_start > 0 || list.days_out_end > 0)) {
        return `${list.days_out_start} - ${list.days_out_end} days`;
    }
    
    // Default case
    return '-';
};

// Add date validation helper
const isValidDateFormat = (dateStr: string): boolean => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateStr)) return false;
    
    const date = new Date(dateStr);
    return date instanceof Date && !isNaN(date.getTime());
};

interface DateListFormDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: Omit<DateList, 'id'> & { dates?: string[] }) => Promise<void>;
    initialData?: DateList;
    currentUser?: CurrentUser;
    isLoading?: boolean;
    successMessage?: string;
    dateLists: DateList[];
}

const DateListFormDialog: React.FC<DateListFormDialogProps> = ({
    open,
    onClose,
    onSubmit,
    initialData,
    currentUser,
    isLoading,
    successMessage,
    dateLists
}) => {
    const [formData, setFormData] = useState<Omit<DateList, 'id'>>({
        name: '',
        admin_only: false,
        description: '',
        source: 'Manual',
        stay_date_start: null,
        stay_date_end: null,
        days_out_start: null,
        days_out_end: null,
        sunday_checked: true,
        monday_checked: true,
        tuesday_checked: true,
        wednesday_checked: true,
        thursday_checked: true,
        friday_checked: true,
        saturday_checked: true,
    });
    const [datesText, setDatesText] = useState('');
    const [nameError, setNameError] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadingDates, setIsLoadingDates] = useState(false);
    const [getDateList] = useGetDateListMutation();

    useEffect(() => {
        if (initialData) {
            setFormData({
                ...initialData,
                description: initialData.description || '',
                source: normalizeSource(initialData.source || 'Manual'),
                stay_date_start: initialData.stay_date_start,
                stay_date_end: initialData.stay_date_end,
                days_out_start: initialData.days_out_start,
                days_out_end: initialData.days_out_end,
                sunday_checked: initialData.sunday_checked,
                monday_checked: initialData.monday_checked,
                tuesday_checked: initialData.tuesday_checked,
                wednesday_checked: initialData.wednesday_checked,
                thursday_checked: initialData.thursday_checked,
                friday_checked: initialData.friday_checked,
                saturday_checked: initialData.saturday_checked,
            });
        } else {
            setFormData({
                name: '',
                admin_only: false,
                description: '',
                source: 'Manual',
                stay_date_start: null,
                stay_date_end: null,
                days_out_start: null,
                days_out_end: null,
                sunday_checked: true,
                monday_checked: true,
                tuesday_checked: true,
                wednesday_checked: true,
                thursday_checked: true,
                friday_checked: true,
                saturday_checked: true,
            });
        }
        setNameError('');
        setDatesText('');
    }, [initialData, open]);

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);

            // Create base data object
            const submitData = {
                name: formData.name,
                admin_only: formData.admin_only,
                description: formData.description,
                source: formData.source,
                // Always include DOW fields as true for non-generate sources
                sunday_checked: formData.source === 'generate' ? formData.sunday_checked : true,
                monday_checked: formData.source === 'generate' ? formData.monday_checked : true,
                tuesday_checked: formData.source === 'generate' ? formData.tuesday_checked : true,
                wednesday_checked: formData.source === 'generate' ? formData.wednesday_checked : true,
                thursday_checked: formData.source === 'generate' ? formData.thursday_checked : true,
                friday_checked: formData.source === 'generate' ? formData.friday_checked : true,
                saturday_checked: formData.source === 'generate' ? formData.saturday_checked : true,
                // Set date fields based on source and type, ensuring null values
                stay_date_start: formData.source === 'generate' ? formData.stay_date_start || null : null,
                stay_date_end: formData.source === 'generate' ? formData.stay_date_end || null : null,
                days_out_start: formData.source === 'generate' && typeof formData.days_out_start === 'number' ? formData.days_out_start : null,
                days_out_end: formData.source === 'generate' && typeof formData.days_out_end === 'number' ? formData.days_out_end : null,
            };

            await onSubmit({
                ...submitData,
                dates: formData.source === 'Manual' ? parseDates(datesText) : undefined
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const parseDates = (text: string): string[] => {
        return text
            .split(/[,\n]/)
            .map(date => date.trim())
            .filter(date => date && isValidDateFormat(date))
            .sort();
    };

    const handleLoadCurrentDates = async () => {
        if (!initialData?.id) return;

        try {
            setIsLoadingDates(true);
            const response = await getDateList(initialData.id).unwrap();
            
            // Debug the response
            console.log('Date list response:', response);
            
            // Handle different possible response formats
            let dates: string[];
            if (Array.isArray(response)) {
                dates = response;
            } else {
                console.error('Unexpected response format:', response);
                alert('Failed to load dates: unexpected format');
                return;
            }

            if (dates.length === 0) {
                alert('No dates found in this list');
                return;
            }

            // If the list is very large, show a confirmation
            if (dates.length > 10000) {
                const confirmed = window.confirm(
                    `This list contains ${dates.length.toLocaleString()} dates. Loading this many dates might affect performance. Continue?`
                );
                if (!confirmed) return;
            }

            // Sort dates before joining
            const sortedDates = [...dates].sort();
            setDatesText(sortedDates.join('\n'));
            
            // Debug the result
            console.log('Setting dates text:', sortedDates.length, 'dates');
        } catch (error) {
            console.error('Failed to load dates:', error);
            alert('Failed to load dates. Please try again.');
        } finally {
            setIsLoadingDates(false);
        }
    };

    const isNameUnique = (name: string) => {
        return !dateLists?.some(list => 
            (!initialData || list.id !== initialData.id) && 
            list.name.toLowerCase() === name.toLowerCase()
        );
    };

    // Add helper to check if any day is selected
    const hasSelectedDay = (data: typeof formData): boolean => {
        return daysOfWeek.some(day => data[`${day.key}_checked`]);
    };

    // Update the validation function
    const isFormValid = () => {
        if (!formData.name.trim() || !!nameError) return false;
        
        if (formData.source === 'generate') {
            // For generate source, require:
            // 1. Either both stay dates or both days out values
            // 2. At least one day of week selected
            const hasValidDateRange = (
                (formData.stay_date_start && formData.stay_date_end) ||
                (typeof formData.days_out_start === 'number' && 
                 typeof formData.days_out_end === 'number' && 
                 formData.days_out_start >= 0 && 
                 formData.days_out_end >= formData.days_out_start)
            );
            
            return hasValidDateRange && hasSelectedDay(formData);
        }
        
        return true;
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{initialData ? 'Edit Date List' : 'Create Date List'}</DialogTitle>
            <DialogContent>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '8px' }}>
                    {/* Basic fields */}
                    <TextField
                        label="Name"
                        value={formData.name}
                        onChange={(e) => {
                            const newName = e.target.value;
                            setFormData(prev => ({ ...prev, name: newName }));
                            
                            if (!newName.trim()) {
                                setNameError('Name is required');
                            } else if (!isNameUnique(newName)) {
                                setNameError('Name must be unique');
                            } else {
                                setNameError('');
                            }
                        }}
                        error={!!nameError}
                        helperText={nameError}
                        fullWidth
                    />
                    
                    <TextField
                        label="Description"
                        value={formData.description}
                        onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                        fullWidth
                        multiline
                        rows={3}
                    />

                    {currentUser?.admin && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formData.admin_only}
                                    onChange={(e) => setFormData(prev => ({ ...prev, admin_only: e.target.checked }))}
                                />
                            }
                            label="Admin Only"
                        />
                    )}

                    {/* Source selection */}
                    <FormControl fullWidth>
                        <InputLabel>Source</InputLabel>
                        <Select
                            value={formData.source}
                            onChange={(e) => {
                                setFormData(prev => ({
                                    ...prev,
                                    source: normalizeSource(e.target.value as string)
                                }));
                            }}
                            disabled={isLoading}
                        >
                            <MenuItem value="Manual"><em>Manual</em></MenuItem>
                            <MenuItem value="generate">Generate</MenuItem>
                            <ListSubheader>Global Lists</ListSubheader>
                            <MenuItem value="all_dates" disabled={true}>All Dates</MenuItem>
                            <MenuItem value="major_us_holidays" disabled={true}>Major US Holidays</MenuItem>
                        </Select>
                    </FormControl>

                    {/* Manual source UI */}
                    {formData.source === 'Manual' && (
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        const file = e.target.files?.[0];
                                        if (file) {
                                            const reader = new FileReader();
                                            reader.onload = (e) => {
                                                const text = e.target?.result as string;
                                                setDatesText(text);
                                            };
                                            reader.onerror = (error) => {
                                                console.error('Error reading file:', error);
                                                alert('Failed to read file. Please try again.');
                                            };
                                            reader.readAsText(file);
                                        }
                                    }}
                                    style={{ flex: 1 }}
                                />
                                {initialData && (
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={handleLoadCurrentDates}
                                        disabled={isLoadingDates}
                                        startIcon={isLoadingDates ? <CircularProgress size={20} /> : <Icon.CloudDownload />}
                                    >
                                        Load Current Dates
                                    </Button>
                                )}
                            </div>
                            <TextField
                                label={`Dates${datesText ? ` (${parseDates(datesText).length})` : ''}`}
                                multiline
                                rows={5}
                                value={datesText}
                                onChange={(e) => setDatesText(e.target.value)}
                                placeholder="Enter dates in YYYY-MM-DD format (e.g., 2025-01-31)"
                                fullWidth
                            />
                        </>
                    )}

                    {/* Generate source UI */}
                    {formData.source === 'generate' && (
                        <>
                            {/* Stay date range */}
                            <div style={{ display: 'flex', gap: '8px' }}>
                                <KeyboardDatePicker
                                    margin="none"
                                    label="Stay Date Start"
                                    format="yyyy-MM-dd"
                                    value={formatDate(formData.stay_date_start, true)}
                                    onChange={(date) => {
                                        if (!date) return;
                                        const formattedDate = format(date, 'yyyy-MM-dd');
                                        setFormData(prev => ({
                                            ...prev,
                                            stay_date_start: formattedDate
                                        }));
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change stay date start',
                                    }}
                                    fullWidth
                                    size="small"
                                />
                                <KeyboardDatePicker
                                    margin="none"
                                    label="Stay Date End"
                                    format="yyyy-MM-dd"
                                    value={formatDate(formData.stay_date_end, true)}
                                    onChange={(date) => {
                                        if (!date) return;
                                        const formattedDate = format(date, 'yyyy-MM-dd');
                                        setFormData(prev => ({
                                            ...prev,
                                            stay_date_end: formattedDate
                                        }));
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change stay date end',
                                    }}
                                    fullWidth
                                    minDate={formatDate(formData.stay_date_start, true)}
                                />
                            </div>

                            {/* Days out range */}
                            <div style={{ display: 'flex', gap: '8px' }}>
                                <TextField
                                    label="Days Out Start"
                                    type="number"
                                    value={formData.days_out_start ?? ''}
                                    onChange={(e) => {
                                        const value = e.target.value === '' ? null : Number(e.target.value);
                                        setFormData(prev => ({
                                            ...prev,
                                            days_out_start: value,
                                            // If end is set but start is being cleared, set end to null
                                            days_out_end: value === null && prev.days_out_end !== null ? null : prev.days_out_end,
                                        }));
                                    }}
                                    size="small"
                                    fullWidth
                                />
                                <TextField
                                    label="Days Out End"
                                    type="number"
                                    value={formData.days_out_end ?? ''}
                                    onChange={(e) => {
                                        const value = e.target.value === '' ? null : Number(e.target.value);
                                        setFormData(prev => ({
                                            ...prev,
                                            days_out_end: value,
                                            // Don't set start if end is being cleared
                                            days_out_start: value !== null && prev.days_out_start === null ? 0 : prev.days_out_start
                                        }));
                                    }}
                                    disabled={formData.days_out_start === null} // Disable if start isn't set
                                    size="small"
                                    fullWidth
                                    helperText={formData.days_out_start === null ? "Set Days Out Start first" : ""}
                                />
                            </div>

                            {/* Days of week selection */}
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={daysOfWeek.every(day =>
                                                formData[`${day.key}_checked`]
                                            )}
                                            onChange={(e) => {
                                                const checked = e.target.checked;
                                                setFormData(prev => ({
                                                    ...prev,
                                                    ...daysOfWeek.reduce((acc, day) => ({
                                                        ...acc,
                                                        [`${day.key}_checked`]: checked
                                                    }), {})
                                                }));
                                            }}
                                        />
                                    }
                                    label="All Days"
                                />
                                <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
                                    {daysOfWeek.map(day => (
                                        <div key={day.key} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px' }}>
                                            <span style={{ fontSize: '12px' }}>{day.shortLabel}</span>
                                            <Checkbox
                                                checked={formData[`${day.key}_checked`]}
                                                onChange={(e) => {
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        [`${day.key}_checked`]: e.target.checked
                                                    }));
                                                }}
                                                size="small"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button 
                    onClick={handleSubmit}
                    color="primary"
                    disabled={isSubmitting || !isFormValid()}
                >
                    {isSubmitting ? <CircularProgress size={24} /> : (initialData ? 'Save' : 'Create')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const DateListsTable: React.FC<DateListsProps> = ({
    dateLists,
    isLoading,
    refetch,
    searchQuery,
    setSearchQuery,
    currentUser,
}) => {
    const [sortBy, setSortBy] = useState<keyof DateList>('id');
    const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [formDialogOpen, setFormDialogOpen] = useState(false);
    const [selectedDateList, setSelectedDateList] = useState<DateList | null>(null);
    const [downloadingRows, setDownloadingRows] = useState<{ [key: number]: boolean }>({});
    const [refreshingRows, setRefreshingRows] = useState<{ [key: number]: boolean }>({});
    const [successMessage, setSuccessMessage] = useState('');

    const [createDateList] = useAddDateListMutation();
    const [updateDateList] = useUpdateDateListMutation();
    const [refreshDateList] = useRefreshDateListMutation();
    const [getDateList] = useGetDateListMutation();
    const [linkDatesToParent] = useLinkDatesToParentMutation();

    const classes = UiUtils.useTableStyles();

    const handleSort = (column: keyof DateList) => {
        if (sortBy === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortDirection('asc');
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAdd = () => {
        setSelectedDateList(null);
        setFormDialogOpen(true);
    };

    const handleEdit = (dateList: DateList) => {
        if (dateList.admin_only && !currentUser?.admin) {
            console.warn('Only admins can edit admin-only lists');
            return;
        }
        setSelectedDateList(dateList);
        setFormDialogOpen(true);
    };

    const handleFormSubmit = async (data: Omit<DateList, 'id'> & { dates?: string[] }) => {
        try {
            const { dates, ...listData } = data;
            
            if (selectedDateList) {
                await updateDateList({ id: selectedDateList.id, data: listData }).unwrap();
                if (dates && dates.length > 0) {
                    await linkDatesToParent({
                        id: selectedDateList.id,
                        data: dates
                    });
                }
                setSuccessMessage('Date list updated successfully');
            } else {
                const newListId = await createDateList(listData).unwrap();
                if (dates && dates.length > 0) {
                    await linkDatesToParent({
                        id: newListId,
                        data: dates
                    });
                }
                setSuccessMessage('Date list created successfully');
            }
            await refetch();
            setFormDialogOpen(false);
        } catch (error) {
            console.error('Failed to save date list:', error);
        }
    };

    const handleDownload = async (dateList: DateList) => {
        try {
            setDownloadingRows(prev => ({
                ...prev,
                [dateList.id]: true
            }));

            const response = await getDateList(dateList.id).unwrap();
            
            // Create CSV content - just the sorted dates, no header
            const csvContent = [...response].sort().join('\n');
            
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            
            link.setAttribute('href', url);
            link.setAttribute('download', `date-list-${dateList.id}.csv`);
            link.style.visibility = 'hidden';
            
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Failed to download date list:', error);
        } finally {
            setDownloadingRows(prev => ({
                ...prev,
                [dateList.id]: false
            }));
        }
    };

    // Add helper function to format days of week display
    const formatDaysOfWeek = (dateList: DateList) => {
        const days = [
            { key: 'sunday', label: 'S' },
            { key: 'monday', label: 'M' },
            { key: 'tuesday', label: 'T' },
            { key: 'wednesday', label: 'W' },
            { key: 'thursday', label: 'T' },
            { key: 'friday', label: 'F' },
            { key: 'saturday', label: 'S' }
        ];

        return (
            <div style={{ display: 'flex', gap: '2px' }}>
                {days.map(day => (
                    <div
                        key={day.key}
                        style={{
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                            backgroundColor: dateList[`${day.key}_checked`] ? '#1976d2' : '#e0e0e0',
                            color: dateList[`${day.key}_checked`] ? 'white' : '#757575',
                            fontSize: '12px'
                        }}
                    >
                        {day.label}
                    </div>
                ))}
            </div>
        );
    };

    const filteredAndSortedData = useMemo(() => {
        if (!dateLists) return [];

        let filtered = dateLists;
        
        // Apply search filter
        if (searchQuery) {
            filtered = filtered.filter(list => 
                list.name.toLowerCase() === searchQuery.toLowerCase() ||  // Exact match for chip clicks
                list.name.toLowerCase().includes(searchQuery.toLowerCase()) ||  // Partial match for typing
                list.description?.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        // Apply sorting
        return [...filtered].sort((a, b) => {
            const aValue = a[sortBy];
            const bValue = b[sortBy];

            if (aValue === bValue) return 0;
            const comparison = aValue < bValue ? -1 : 1;
            return sortDirection === 'asc' ? comparison : -comparison;
        });
    }, [dateLists, searchQuery, sortBy, sortDirection]);

    const paginatedData = useMemo(() => {
        const startIndex = page * rowsPerPage;
        return filteredAndSortedData.slice(startIndex, startIndex + rowsPerPage);
    }, [filteredAndSortedData, page, rowsPerPage]);

    // Add effect to handle search query changes
    useEffect(() => {
        // Update the search field when searchQuery prop changes
        const searchField = document.querySelector('input[type="text"]') as HTMLInputElement;
        if (searchField) {
            searchField.value = searchQuery;
            // Trigger a change event to update the filter
            searchField.dispatchEvent(new Event('change', { bubbles: true }));
        }
    }, [searchQuery]);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Paper style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                }}>
                    <TextField
                        label="Search Date Lists"
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{ width: '450px' }}
                        InputProps={{
                            startAdornment: <Icon.Search style={{ color: 'gray', marginRight: 8 }} />,
                            endAdornment: searchQuery && (
                                <IconButton
                                    size="small"
                                    onClick={() => setSearchQuery('')}
                                    style={{ padding: 4 }}
                                >
                                    <Icon.Close fontSize="small" />
                                </IconButton>
                            )
                        }}
                    />
                    <Tooltip title="Add Date List">
                        <IconButton
                            onClick={handleAdd}
                            size="small"
                            disabled={isLoading}
                        >
                            <Icon.Add fontSize={"small"}/>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={isLoading ? "Loading..." : "Refresh data"}>
                        <IconButton
                            onClick={() => refetch()}
                            disabled={isLoading}
                            size="small"
                        >
                            {isLoading ? (
                                <CircularProgress size={16} />
                            ) : (
                                <Icon.Replay fontSize={"small"}/>
                            )}
                        </IconButton>
                    </Tooltip>
                </Box>
                <div style={{ position: 'relative' }}>
                    {(isLoading) && (
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                        }}>
                            <CircularProgress />
                        </div>
                    )}
                
                    <TableContainer 
                        className={classes.tableContainer}
                        style={{ height: '600px', overflow: 'auto' }}
                    >
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={`sticky-header-cell ${classes.stickyHeader}`} style={{minWidth: '45px'}}>
                                        <TableSortLabel
                                            active={sortBy === 'id'}
                                            direction={sortBy === 'id' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('id')}
                                        >
                                            ID
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell 
                                        className={`sticky-header-cell ${classes.stickyHeader}`}
                                        style={{ 
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            minWidth: '150px'
                                        }}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'name'}
                                            direction={sortBy === 'name' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('name')}
                                        >
                                            Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className={`sticky-header-cell ${classes.stickyHeader}`} style={{minWidth: '68px'}}>
                                        <TableSortLabel
                                            active={sortBy === 'admin_only'}
                                            direction={sortBy === 'admin_only' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('admin_only')}
                                        >
                                            Admin
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className={`sticky-header-cell ${classes.stickyHeader}`} style={{minWidth: '65px'}}>
                                        <TableSortLabel
                                            active={sortBy === 'date_count'}
                                            direction={sortBy === 'date_count' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('date_count')}
                                        >
                                            Count
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className={`sticky-header-cell ${classes.stickyHeader}`} style={{minWidth: '100px'}}>Creator</TableCell>
                                    <TableCell className={`sticky-header-cell ${classes.stickyHeader}`} style={{minWidth: '90px'}}>
                                        <TableSortLabel
                                            active={sortBy === 'source'}
                                            direction={sortBy === 'source' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('source')}
                                        >
                                            Source
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className={`sticky-header-cell ${classes.stickyHeader}`} style={{minWidth: '90px'}}>Dates</TableCell>
                                    <TableCell className={`sticky-header-cell ${classes.stickyHeader}`} style={{minWidth: '100px'}}>Days of Week</TableCell>
                                    <TableCell className={`sticky-header-cell ${classes.stickyHeader}`} style={{minWidth: '100px'}}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedData.map((list) => (
                                    <TableRow key={list.id}>
                                        <TableCell>{list.id}</TableCell>
                                        <TableCell 
                                            style={{ 
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                maxWidth: '200px'  // Adjust this value based on your needs
                                            }}
                                        >
                                            <Tooltip title={list.description || 'No description'}>
                                                <span>{list.name}</span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Chip
                                                label={list.admin_only ? 'Yes' : 'No'}
                                                size="small"
                                                color={list.admin_only ? 'primary' : 'default'}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {list.date_count || 0}
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={formatCreatorTooltip(list.created_at)}>
                                                <span>{formatCreatorName(list.creator)}</span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            {DATE_LIST_SOURCES.find(s => s.value === list.source)?.label || list.source}
                                        </TableCell>
                                        <TableCell>
                                            {formatDateRange(list)}
                                        </TableCell>
                                        <TableCell>
                                            {formatDaysOfWeek(list)}
                                        </TableCell>
                                        <TableCell>
                                            <div style={{ display: 'flex' }}>
                                                <Tooltip title="Edit">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleEdit(list)}
                                                        disabled={
                                                            isLoading || 
                                                            refreshingRows[list.id] || 
                                                            (list.admin_only && !currentUser?.admin)
                                                        }
                                                    >
                                                        {isLoading ? (
                                                            <CircularProgress size={16} />
                                                        ) : (
                                                            <Icon.Edit fontSize="small" />
                                                        )}
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Download Dates">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleDownload(list)}
                                                        disabled={isLoading || downloadingRows[list.id]}
                                                    >
                                                        {downloadingRows[list.id] ? (
                                                            <CircularProgress size={16} />
                                                        ) : (
                                                            <Icon.VerticalAlignBottom fontSize="small" />
                                                        )}
                                                    </IconButton>
                                                </Tooltip>
                                                {list.source !== 'Manual' && (
                                                    <Tooltip title={
                                                        list.admin_only && !currentUser?.admin 
                                                            ? "Only admins can refresh admin-only lists" 
                                                            : formatMetadataDate(list.updated_at, list.created_at)
                                                    }>
                                                        <span>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => {
                                                                    setRefreshingRows(prev => ({
                                                                        ...prev,
                                                                        [list.id]: true
                                                                    }));
                                                                    handleFormSubmit({
                                                                        ...list,
                                                                        dates: []
                                                                    });
                                                                }}
                                                                disabled={
                                                                    isLoading || 
                                                                    refreshingRows[list.id] || 
                                                                    (list.admin_only && !currentUser?.admin)
                                                                }
                                                            >
                                                                {refreshingRows[list.id] ? (
                                                                    <CircularProgress size={16} />
                                                                ) : (
                                                                    <Icon.Replay fontSize="small" />
                                                                )}
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={filteredAndSortedData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <DateListFormDialog
                    open={formDialogOpen}
                    onClose={() => {
                        setFormDialogOpen(false);
                        setSuccessMessage('');
                        setSelectedDateList(null);
                    }}
                    onSubmit={handleFormSubmit}
                    initialData={selectedDateList}
                    currentUser={currentUser}
                    isLoading={isLoading}
                    successMessage={successMessage}
                    dateLists={dateLists}
                />
            </Paper>
        </MuiPickersUtilsProvider>
    );
};

export default DateListsTable; 