import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Checkbox,
    FormControlLabel,
    IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { ReblOption } from '../../types';

interface ReblOptionDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (data: ReblOptionFormData) => void;
    selectedOptionTable: string;
    initialData?: ReblOption;
    isEdit?: boolean;
}

export interface ReblOptionFormData {
    value: string;
    isCompoundable: boolean;
    category: string;
    ownerFacingTooltip: string;
    active: boolean;
}

export const ReblOptionDialog: React.FC<ReblOptionDialogProps> = ({
    open,
    onClose,
    onSave,
    selectedOptionTable,
    initialData,
    isEdit = false
}) => {
    const [formData, setFormData] = React.useState<ReblOptionFormData>({
        value: '',
        isCompoundable: false,
        category: '',
        ownerFacingTooltip: '',
        active: true
    });

    React.useEffect(() => {
        if (initialData) {
            const valueKey = selectedOptionTable.replace(" ", "_").toLowerCase() as keyof ReblOption;
            setFormData({
                value: initialData[valueKey]?.toString() || '',
                isCompoundable: initialData.compoundable ?? false,
                category: initialData.category ?? '',
                ownerFacingTooltip: initialData.owner_facing_tooltip ?? '',
                active: initialData.active
            });
        } else {
            setFormData({
                value: '',
                isCompoundable: false,
                category: '',
                ownerFacingTooltip: '',
                active: true
            });
        }
    }, [initialData, selectedOptionTable]);

    const handleChange = (field: keyof ReblOptionFormData, value: string | boolean) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSave = () => {
        onSave(formData);
        onClose();
    };

    const isValid = React.useMemo(() => {
        const hasRequiredFields = formData.value.trim() !== '';
        
        if (selectedOptionTable === "Strategy") {
            return hasRequiredFields && 
                   formData.category.trim() !== '' && 
                   formData.ownerFacingTooltip.trim() !== '';
        }
        
        return hasRequiredFields;
    }, [formData, selectedOptionTable]);

    return (
        <Dialog 
            open={open} 
            onClose={onClose} 
            maxWidth="sm" 
            fullWidth
            PaperProps={{
                style: {
                    width: '500px',
                    maxWidth: '95vw',
                }
            }}
        >
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{isEdit ? 'Edit' : 'New'} {selectedOptionTable} Option</span>
                    <IconButton size="small" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="dialog-form">
                    <div className="form-group">
                        <TextField
                            fullWidth
                            label="Value"
                            value={formData.value}
                            onChange={(e) => handleChange('value', e.target.value)}
                            placeholder={`${selectedOptionTable} value...`}
                            variant="outlined"
                            size="small"
                            disabled={isEdit}
                        />
                    </div>

                    <div className="form-group">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.active}
                                    onChange={() => handleChange('active', !formData.active)}
                                    color="primary"
                                />
                            }
                            label="Active"
                        />
                    </div>
                    
                    {selectedOptionTable === "Action" && (
                        <div className="form-group">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.isCompoundable}
                                        onChange={() => handleChange('isCompoundable', !formData.isCompoundable)}
                                        color="primary"
                                    />
                                }
                                label="Compoundable"
                            />
                        </div>
                    )}

                    {selectedOptionTable === "Strategy" && (
                        <>
                            <div className="form-group">
                                <TextField
                                    fullWidth
                                    label="Category"
                                    value={formData.category}
                                    onChange={(e) => handleChange('category', e.target.value)}
                                    placeholder="Category..."
                                    variant="outlined"
                                    size="small"
                                />
                            </div>
                            <div className="form-group">
                                <TextField
                                    fullWidth
                                    label="Owner Facing Tooltip"
                                    value={formData.ownerFacingTooltip}
                                    onChange={(e) => handleChange('ownerFacingTooltip', e.target.value)}
                                    placeholder="Tooltip..."
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </div>
                        </>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button 
                    onClick={handleSave} 
                    color="primary" 
                    variant="contained"
                    disabled={!isValid}
                >
                    {isEdit ? 'Save' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}; 