import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {getHeaders} from "./utils";

export const apiCohort = createApi({
    reducerPath: "cohortApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_NORTHSTAR_API}`,
        prepareHeaders: (headers) => {
            return getHeaders(headers);
        }
    }),
    tagTypes: ["Cohorts"],
    endpoints: () => ({})
})
export const apiDateList = createApi({
    reducerPath: "dateListApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_NORTHSTAR_API}`,
        prepareHeaders: (headers) => {
            return getHeaders(headers);
        }
    }),
    tagTypes: ["DateList"],
    endpoints: () => ({})
})
export const apiDemandInfluence = createApi({
    reducerPath: "demandInfluenceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_NORTHSTAR_API}`,
        prepareHeaders: (headers) => {
            return getHeaders(headers);
        }
    }),
    tagTypes: ["DemandInfluences"],
    endpoints: () => ({})
})

export const apiDemandDetail = createApi({
    reducerPath: "demandDetailApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_NORTHSTAR_API}`,
        prepareHeaders: (headers) => {
            return getHeaders(headers);
        }
    }),
    tagTypes: ["DemandDetails"],
    endpoints: () => ({})
})

export const apiPromotions = createApi({
    reducerPath: "promotionsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_NORTHSTAR_API}`,
        prepareHeaders: (headers) => {
            return getHeaders(headers);
        }
    }),
    tagTypes: ["Promotions"],
    endpoints: () => ({})
})

export const apiRatePlans = createApi({
    reducerPath: "ratePlansApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_NORTHSTAR_API}`,
        prepareHeaders: (headers) => {
            return getHeaders(headers);
        }
    }),
    tagTypes: ["RatePlans"],
    endpoints: () => ({})
})

export const apiUnit = createApi({
    reducerPath: "unitApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_NORTHSTAR_API}`,
        prepareHeaders: (headers) => {
            return getHeaders(headers);
        }
    }),
    tagTypes: ["Units"],
    endpoints: () => ({})

})
export const apiRate = createApi({
    reducerPath: "rateApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_NORTHSTAR_API}`,
        prepareHeaders: (headers) => {
            return getHeaders(headers);
        }
    }),
    tagTypes: ["Rates"],
    endpoints: () => ({})

})

export const apiAdmin = createApi({
    reducerPath: "adminApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_NORTHSTAR_API}`,
        prepareHeaders: (headers) => {
            return getHeaders(headers);
        }
    }),
    tagTypes: ["Admin"],
    endpoints: () => ({})
})

export const apiRebl = createApi({
    reducerPath: "reblApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_NORTHSTAR_API}`,
        prepareHeaders: (headers) => {
            return getHeaders(headers);
        }
    }),
    tagTypes: ["ReblRulesets"],
    endpoints: () => ({})
})

export const apiTables = createApi({
    reducerPath: "tableApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_NORTHSTAR_API}`,
        prepareHeaders: (headers) => {
            return getHeaders(headers);
        }
    }),
    tagTypes: ["Tables"],
    endpoints: () => ({})
})

export const apiUnitNightLists = createApi({
    reducerPath: 'unitNightListsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_NORTHSTAR_API}`,
        prepareHeaders: (headers) => {
            return getHeaders(headers);
        }
    }),
    tagTypes: ['UnitNightList'],
    endpoints: () => ({})
})
