import React, {useEffect, useState} from "react";
import {
    Button,
    Icon,
    withTooltip
} from '@vacasa/react-components-lib';

import {useGetDagStatusQuery, useTriggerDagMutation} from "../../store";
import {Loading} from "../Common/Loading/Loading";


export const ReblAdmin:  React.FC = () => {
    const [initialLoad, setInitialLoad] = useState<boolean>(false);
    const [isRunningPreview, setIsRunningPreview] = useState<boolean>(true);
    const [isRunningPromote, setIsRunningPromote] = useState<boolean>(true);
    const {refetch: refetchPreviewReblStatus, data: previewDagStatus, isFetching: isFetchingReblPreviewStatus} = useGetDagStatusQuery("ns_rebl_preview")
    const [cleanPreviewDagStatus, setCleanPreviewDagStatus] = useState<any>();
    const {refetch: refetchPromoteReblStatus, data: promoteDagStatus, isFetching: isFetchingReblPromoteStatus} = useGetDagStatusQuery("ns_rebl_promote_adjustments")
    const [previewRefetchTimer, setPreviewRefetchTimer] = useState<number>();
    const [promoteRefetchTimer, setPromoteRefetchTimer] = useState<number>();
    const [lastPreviewTime, setLastPreviewTime] = useState<number>();
    const [lastPromoteTime, setLastPromoteTime] = useState<number>();
    const [isPromoteDisabled, setIsPromoteDisabled] = useState<boolean>(true);

    const [triggerPreviewDag] = useTriggerDagMutation();
    const [triggerPromoteDag] = useTriggerDagMutation();

    const triggerPreview = async () => {
        setIsRunningPreview(true);
        await triggerPreviewDag("ns_rebl_preview").then(() => {
            refetchPreviewReblStatus();
        })
    }

    useEffect(() => {
        if (!isFetchingReblPreviewStatus) {
            setCleanPreviewDagStatus({
                ...previewDagStatus,
                "latest_task": previewDagStatus["latest_task"].replace('scheduled_job.', '').split(".")[0]
            })
        }
    }, [previewDagStatus, isFetchingReblPreviewStatus]);

    const triggerPromote = async () => {
        setIsRunningPromote(true);
        await triggerPromoteDag("ns_rebl_promote_adjustments").then(() => {
            refetchPromoteReblStatus();
        })
    }

    useEffect(() => {
        if (!isFetchingReblPreviewStatus && !!previewDagStatus) {
            setLastPreviewTime(previewDagStatus["latest_datetime"]);
            if (previewDagStatus["latest_datetime"] > 0) {
                const timeDiff = ((new Date().getTime() / 1000) - previewDagStatus["latest_datetime"]) / (60 * 60);
                setIsPromoteDisabled(timeDiff > 8);
            }

            const status = previewDagStatus["latest_status"];
            if (status === "success" || status === "failed") {
                setIsRunningPreview(false);
            }
            else {
                setPreviewRefetchTimer(10);
                setIsRunningPreview(true);
                setTimeout(() => {
                    refetchPreviewReblStatus()
                }, 10_000)
            }
        }
    }, [previewDagStatus, isFetchingReblPreviewStatus]);

    useEffect(() => {
        if (!isFetchingReblPromoteStatus && !!promoteDagStatus) {
            console.log(promoteDagStatus)
            setLastPromoteTime(promoteDagStatus["latest_datetime"]);
            const status = promoteDagStatus["latest_status"];
            if (status === "success" || status === "failed") {
                setIsRunningPromote(false);
            }
            else {
                setPromoteRefetchTimer(10);
                setIsRunningPromote(true);
                setTimeout(() => {
                    refetchPromoteReblStatus()
                }, 10_000)
            }
        }
    }, [promoteDagStatus, isFetchingReblPromoteStatus]);

    useEffect(() => {
        if(previewRefetchTimer > 1) {
            setTimeout(() => {
                setPreviewRefetchTimer(previewRefetchTimer - 1)
            }, 1_000)
        }
        else setPreviewRefetchTimer(null)
    }, [previewRefetchTimer])

    useEffect(() => {
        if(promoteRefetchTimer > 1) {
            setTimeout(() => {
                setPromoteRefetchTimer(promoteRefetchTimer - 1)
            }, 1_000)
        }
        else setPromoteRefetchTimer(null)
    }, [promoteRefetchTimer])

    useEffect(() => {
        if (!!promoteDagStatus && !!previewDagStatus) {
            setInitialLoad(true);
        }
    }, [promoteDagStatus, previewDagStatus])

    const environment: string = '%REACT_APP_CUSTOM_NODE_ENV%';

    const previewSteps = [
        {
            running_display: "Starting...",
            display: "Start",
            name: "start"
        }, {
            running_display: "Waiting for 11am Pacific...",
            display: "Check for Daylight Savings",
            name: "wait_11_pacific"
        }, {
            running_display: "Creating REBL Snapshot...",
            display: "Create REBL Snapshot",
            name: "snapshot_rebl_rules_clean"
        }, {
            running_display: "Setting Rule Geography...",
            display: "Set Rule Geography",
            name: "replace_location_rules"
        }, {
            running_display: "Setting Rule Priority...",
            display: "Set Rule Priority",
            name: "replace_rebl_priority"
        }, {
            running_display: "Calculating All Nights...",
            display: "Calculate All Nights",
            name: "replace_influenced_nights"
        }, {
            running_display: "Setting Rule parents...",
            display: "Set Rule parents",
            name: "replace_rule_parent"
        }, {
            running_display: "Setting Rule Relationships...",
            display: "Set Rule Relationships",
            name: "replace_ptc_relations"
        }, {
            running_display: "Snapshotting All Influences...",
            display: "Snapshot All Influences",
            name: "insert_influence_snapshot"
        }, {
            running_display: "Aggregating snapshots...",
            display: "Aggregate snapshots",
            name: "replace_aggregated_snapshot"
        }, {
            running_display: "Preparing Dashboard...",
            display: "Prepare Dashboard",
            name: "replace_daily_dash"
        }, {
            running_display: "Update Compounding...",
            display: "Update Compounding",
            name: "replace_compounding_decision"
        }, {
            running_display: "Finalizing Adjustments...",
            display: "Finalize Adjustments",
            name: "insert_final_preview"
        }, {
            running_display: "Summarizing Adjustments...",
            display: "Summarize Adjustments",
            name: "slack_rebl_summary"
        }, {
            running_display: "Complete",
            display: "Complete",
            name: "end"
        },
    ]

    const promoteSteps = [
        {
            display: "Start",
            running_display: "Starting...",
            name: "start"
        }, {
            display: "Promote Adjustments",
            running_display: "Promoting Adjustments...",
            name: "promote_rebl_adjustments"
        }, {
            display: "Wipe Base Rate Overrides",
            running_display: "Wiping Base Rate Overrides...",
            name: "wipe_base_rate_overrides"
        }, {
            display: "Wipe Analyst Factors",
            running_display: "Wiping Analyst Factors...",
            name: "wipe_analyst_factors"
        }, {
            display: "Send REBL Adjustments",
            running_display: "Merging REBL Adjustments...",
            name: "rebl_adjustments"
        }, {
            display: "Notify REBL Complete (Slack)",
            running_display: "Notifying REBL Complete (Slack)...",
            name: "slack_promote_complete"
        }, {
            display: "Super Unit Adjustments",
            running_display: "Adjusting Super Units...",
            name: "super_unit_adjustments"
        }, {
            running_display: "Complete",
            display: "Complete",
            name: "end"
        }
    ]

    return (
        <div>
            <div className="modal-header" style={{paddingBottom: "20px"}}>
                <h4>REBL Adjustments</h4>
            </div>
            <div className="modal-body" style={{minHeight: "450px"}}>{!initialLoad ? <Loading className="admin-list-loading"/> :
                <>
                    <div className="row" style={{display: "flex", maxHeight: "650px", overflow: "scroll"}}>
                        <div className="col" style={{width: "350px"}}>
                            <div className="row">
                                <Button variant="info" disabled={isRunningPreview || isFetchingReblPreviewStatus}
                                        onClick={triggerPreview}>
                                    {isRunningPreview || isFetchingReblPreviewStatus ?
                                        <Icon.Loader className={"spinning-icon"} height={20} width={20}/>
                                        :
                                        <Icon.Play height={20} width={20}/>
                                    }
                                    Run Preview
                                </Button>
                            </div>
                            {previewSteps.map((s, idx) => {
                                return (
                                    <div className={"row"} style={{margin: "3px", display: "flex"}}>
                                        <div className="col" style={{width: "50px"}}>
                                            {
                                                (
                                                    previewDagStatus["latest_status"] === "success" ||
                                                    previewSteps.map(ps => ps.name).indexOf(cleanPreviewDagStatus["latest_task"]) > idx
                                                )
                                                    ?
                                                    <Icon.CheckCircle height={20} width={20}/>
                                                    :
                                                    cleanPreviewDagStatus["latest_task"] === s.name
                                                        ?
                                                        <Icon.Loader className={"spinning-icon"} height={20}
                                                                     width={20}/>
                                                        :
                                                        <Icon.QuestionMark height={20} width={20}/>
                                            }
                                        </div>
                                        <div className="col" style={{width: "270px"}}>
                                            {
                                                cleanPreviewDagStatus["latest_task"] === s.name ?
                                                    s.running_display :
                                                    s.display
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="row">
                                <Button variant="primary" disabled={isRunningPreview || isFetchingReblPreviewStatus}
                                        onClick={refetchPreviewReblStatus}>
                                    <Icon.RefreshCCW height={20} width={20}/>
                                    Refetch{previewRefetchTimer ? `ing in (${previewRefetchTimer})` : ""}
                                </Button>
                            </div>
                        </div>
                        <div className="col" style={{width: "350px"}}>
                            {withTooltip(
                                isPromoteDisabled ? "Run promote within 6 hours of preview" :
                                    isRunningPromote ? "Wait for preview to finish"
                                        : "",
                                <div className="row">
                                    <Button variant="secondary" disabled={(
                                        isRunningPromote ||
                                        isFetchingReblPromoteStatus ||
                                        isRunningPreview ||
                                        isFetchingReblPreviewStatus ||
                                        isPromoteDisabled
                                    )}
                                            onClick={triggerPromote}>
                                        {(
                                            isRunningPromote ||
                                            isFetchingReblPromoteStatus ||
                                            isRunningPreview ||
                                            isFetchingReblPreviewStatus
                                        ) ?
                                            <Icon.Loader className={"spinning-icon"} height={20} width={20}/>
                                            :
                                            <Icon.Save height={20} width={20}/>
                                        }
                                        Run Promote
                                    </Button>
                                </div>,
                                {placement: "top"}
                            )}
                            {promoteSteps.map((s, idx) => {
                                return (
                                    <div className={"row"} style={{margin: "3px", display: "flex"}}>
                                        <div className="col" style={{width: "50px"}}>
                                            {
                                                (
                                                    promoteDagStatus["latest_status"] === "success" ||
                                                    promoteSteps.map(ps => ps.name).indexOf(promoteDagStatus["latest_task"]?.split(".")[0]) > idx
                                                )
                                                    ?
                                                    <Icon.CheckCircle height={20} width={20}/>
                                                    :
                                                    promoteDagStatus["latest_task"]?.split(".")[0] === s.name
                                                        ?
                                                        <Icon.Loader className={"spinning-icon"} height={20}
                                                                     width={20}/>
                                                        :
                                                        <Icon.QuestionMark height={20} width={20}/>
                                            }
                                        </div>
                                        <div className="col" style={{width: "270px"}}>
                                            {
                                                previewDagStatus["latest_task"] === s.name ?
                                                    s.running_display :
                                                    s.display
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="row">
                                <Button variant="primary" disabled={isRunningPromote || isFetchingReblPromoteStatus}
                                        onClick={refetchPromoteReblStatus}>
                                    <Icon.RefreshCCW height={20} width={20}/>
                                    Refetch{promoteRefetchTimer ? `ing in (${promoteRefetchTimer})` : ""}
                                </Button>
                            </div>

                        </div>
                    </div>
                    <div className="row" style={{display: "flex"}}>
                        <div className="col" style={{width: "350px"}}>
                            {lastPreviewTime &&
                                <div className="row">
                                    Last Preview:
                                    <br/>
                                    {new Date(lastPreviewTime * 1000).toLocaleDateString(undefined, {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        second: 'numeric',
                                        timeZoneName: 'short',
                                    })}
                                </div>
                            }
                        </div>
                        <div className="col" style={{width: "350px"}}>
                            {lastPromoteTime &&
                                <div className="row">
                                    Last Promote:
                                    <br/>
                                    {new Date(lastPromoteTime * 1000).toLocaleDateString(undefined, {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        second: 'numeric',
                                        timeZoneName: 'short',
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                </>}
            </div>
        </div>

    )
}