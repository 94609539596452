import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TableSortLabel,
  CircularProgress,
  TablePagination,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Select,
  MenuItem,
  Chip,
  ListSubheader,
  FormControlLabel,
  Switch,
  InputLabel,
} from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import {
  useGetUnitListMutation,
  useAddUnitListMutation,
  useUpdateUnitListMutation,
  useRefreshUnitListMutation,
  useLinkUnitsToParentMutation,
  // useGetAllReblRulesQuery,
} from '../../store';
import {Cohort, CurrentUser, LocationOptions, UnitList} from "../../types";
import { format } from 'date-fns';
import { FixedSizeList } from 'react-window';
import { UiUtils } from "../../utils";


type SortDirection = 'asc' | 'desc';

interface UnitListsProps {
  unitLists: UnitList[],
  isLoading: boolean,
  refetch: () => void,
  locationOptions: LocationOptions;
  isFetchingLocationOptions: boolean;
  cohortData: Cohort[];
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  currentUser: CurrentUser;
}

const VirtualizedMenuItems = React.memo(({ 
  items, 
  onSelect, 
  source, 
  unitLists,
  cohortData,
  searchTerm = ''
}: { 
  items: string[], 
  onSelect: (value: string) => void,
  source?: string,
  unitLists?: UnitList[],
  cohortData?: Cohort[],
  searchTerm?: string
}) => {
  const itemHeight = 48;
  
  const getFilteredItems = () => {
    const search = searchTerm.toLowerCase();
    if (!search) return items;
    
    if (source === 'Intersection') {
      return items.filter(value => {
        const list = unitLists?.find(ul => ul.id.toString() === value);
        return list?.name.toLowerCase().includes(search);
      });
    }
    
    if (source === 'strategic_cohorts') {
      return items.filter(value => {
        const cohort = cohortData?.find(c => c.id.toString() === value);
        return cohort?.name.toLowerCase().includes(search);
      });
    }
    
    // For geography options
    return items.filter(item => item.toLowerCase().includes(search));
  };

  const filteredItems = getFilteredItems();
  
  const Row = ({ index, style }: { index: number, style: React.CSSProperties }) => {
    const value = filteredItems[index];
    let label = value;
    
    // Get display label based on source type
    if (source === 'Intersection' && unitLists) {
      const list = unitLists.find(ul => ul.id.toString() === value);
      if (list) {
        label = list.name;
      }
    } else if (source === 'strategic_cohorts' && cohortData) {
      const cohort = cohortData.find(c => c.id.toString() === value);
      if (cohort) {
        label = cohort.name;
      }
    } else {
      // For geography options, capitalize first letter
      label = value.charAt(0).toUpperCase() + value.slice(1);
    }

    return (
      <MenuItem 
        key={value} 
        value={value}
        onClick={() => onSelect(value)}
        style={style}
      >
        {label}
      </MenuItem>
    );
  };

  return (
    <FixedSizeList
      height={Math.min(filteredItems.length * itemHeight, 300)}
      width="100%"
      itemCount={filteredItems.length}
      itemSize={itemHeight}
    >
      {Row}
    </FixedSizeList>
  );
});

const getSourceOptions = (
    source: string | null, 
    listId: number, 
    editingStates: { [key: number]: Omit<UnitList, 'id'> },
    unitLists?: UnitList[],
    cohortData?: Cohort[],
    locationOptions?: LocationOptions
) => {
    if (!source) return [];
    
    switch (source) {
      case 'Intersection':
        return unitLists
          ?.filter(list => !editingStates[listId]?.source_values?.includes(list.id.toString()))
          .map(list => list.id.toString()) || [];
      case 'strategic_cohorts':
        return cohortData
          ?.filter(cohort => !editingStates[listId]?.source_values?.includes(cohort.id.toString()))
          .map(cohort => cohort.id.toString()) || [];
      default:
        return (locationOptions?.[source] || [])
          .filter(value => !editingStates[listId]?.source_values?.includes(value));
    }
};

interface UnitListFormDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: Omit<UnitList, 'id'> & { unitIds?: number[] }) => Promise<void>;
  initialData?: UnitList;
  unitLists?: UnitList[];
  locationOptions?: LocationOptions;
  cohortData?: Cohort[];
  currentUser?: CurrentUser;
  isLoading?: boolean;
  successMessage?: string;
}

const UnitListFormDialog: React.FC<UnitListFormDialogProps> = ({
  open,
  onClose,
  onSubmit,
  initialData,
  unitLists,
  locationOptions,
  cohortData,
  currentUser,
  isLoading,
  successMessage
}) => {
  const [formData, setFormData] = useState<Omit<UnitList, 'id'>>({
    name: '',
    admin_only: false,
    description: '',
    source: 'Manual',
    source_values: null,
  });
  const [unitIdsText, setUnitIdsText] = useState('');
  const [nameError, setNameError] = useState<string>('');
  const [sourceValueSearch, setSourceValueSearch] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingUnits, setIsLoadingUnits] = useState(false);
  const [getUnitList] = useGetUnitListMutation();

  useEffect(() => {
    if (initialData) {
      setFormData({
        name: initialData.name,
        admin_only: initialData.admin_only,
        description: initialData.description || '',
        source: initialData.source || 'Manual',
        source_values: initialData.source_values || null,
      });
    } else {
      setFormData({
        name: '',
        admin_only: false,
        description: '',
        source: 'Manual',
        source_values: null,
      });
    }
    setNameError('');
    setSourceValueSearch('');
    setUnitIdsText('');
  }, [initialData, open]);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await onSubmit({
        ...formData,
        unitIds: formData.source === 'Manual' ? parseUnitIds(unitIdsText) : undefined
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const parseUnitIds = (text: string): number[] => {
    return text
      .split(/[,\n]/)
      .map(id => id.trim())
      .filter(id => id !== '')
      .map(id => parseInt(id))
      .filter(id => !isNaN(id))
      .sort((a, b) => a - b);
  };

  const isNameUnique = (name: string) => {
    return !unitLists?.some(list => 
      (!initialData || list.id !== initialData.id) && 
      list.name.toLowerCase() === name.toLowerCase()
    );
  };

  const getSourceOptions = (source: string | null): string[] => {
    if (!source) return [];
    
    switch (source) {
      case 'Intersection':
        return unitLists
          ?.filter(list => 
            // Exclude current list being edited and already selected lists
            list.id !== initialData?.id && 
            !formData.source_values?.includes(list.id.toString())
          )
          .map(list => list.id.toString()) || [];
      case 'strategic_cohorts':
        return cohortData
          ?.filter(cohort => !formData.source_values?.includes(cohort.id.toString()))
          .map(cohort => cohort.id.toString()) || [];
      default:
        return (locationOptions?.[source.toLowerCase()] || [])
          .filter(value => !formData.source_values?.includes(value));
    }
  };

  const getSourceValueDisplay = (value: string, source: string | null) => {
    if (source === 'Intersection' && unitLists) {
      const list = unitLists.find(ul => ul.id.toString() === value);
      return list ? list.name : value;
    }
    
    if (source === 'strategic_cohorts' && cohortData) {
      const cohort = cohortData.find(c => c.id.toString() === value);
      return cohort ? cohort.name : value;
    }

    return value;
  };

  // Update handleLoadCurrentUnits with better error handling and logging
  const handleLoadCurrentUnits = async () => {
    if (!initialData?.id) return;

    try {
      setIsLoadingUnits(true);
      const units = await getUnitList(initialData.id).unwrap();
      
      if (!Array.isArray(units) || units.length === 0) {
        return;
      }

      // If the list is very large, show a confirmation
      if (units.length > 10000) {
        const confirmed = window.confirm(
          `This list contains ${units.length.toLocaleString()} units. Loading this many units might affect performance. Continue?`
        );
        if (!confirmed) return;
      }

      // Create a new array before sorting
      const sortedUnits = [...units].sort((a, b) => a - b);
      const unitText = sortedUnits.join('\n');
      
      setUnitIdsText(unitText);
    } catch (error) {
      console.error('Failed to load units:', error);
      alert('Failed to load units. Please try again.');
    } finally {
      setIsLoadingUnits(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{initialData ? 'Edit Unit List' : 'Create Unit List'}</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '8px' }}>
          <TextField
            label="Name"
            value={formData.name}
            onChange={(e) => {
              const newName = e.target.value;
              setFormData(prev => ({ ...prev, name: newName }));
              
              if (!newName.trim()) {
                setNameError('Name is required');
              } else if (!isNameUnique(newName)) {
                setNameError('Name must be unique');
              } else {
                setNameError('');
              }
            }}
            error={!!nameError}
            helperText={nameError}
            fullWidth
          />
          
          <TextField
            label="Description"
            value={formData.description}
            onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
            fullWidth
            multiline
            rows={3}
          />

          {currentUser?.admin && (
            <FormControlLabel
              control={
                <Switch
                  checked={formData.admin_only}
                  onChange={(e) => setFormData(prev => ({ ...prev, admin_only: e.target.checked }))}
                />
              }
              label="Admin Only"
            />
          )}

          <FormControl fullWidth>
            <InputLabel>Source</InputLabel>
            <Select
              value={formData.source}
              onChange={(e) => {
                const newSource = e.target.value as string;
                setFormData(prev => ({
                  ...prev,
                  source: newSource,
                  source_values: ['All Units', 'New Units', 'No Bookings', 'strategic_cohorts'].includes(newSource) ? [] : null
                }));
              }}
              disabled={isLoading}
            >
              <MenuItem value="Manual"><em>Manual</em></MenuItem>

              <ListSubheader>Auto Generate</ListSubheader>
              <MenuItem value="Intersection">Intersection</MenuItem>

              <ListSubheader>Geography</ListSubheader>
              {Object.keys(locationOptions || {}).map(type => (
                <MenuItem key={type} value={type}>
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </MenuItem>
              ))}
              <MenuItem value="strategic_cohorts">Strategic Cohorts</MenuItem>

              <ListSubheader>Global Lists</ListSubheader>
              <MenuItem value="All Units" disabled={true}>All Units</MenuItem>
              <MenuItem value="No Bookings" disabled={true}>No Bookings</MenuItem>
              <MenuItem value="New Units" disabled={true}>New Units</MenuItem>
            </Select>
          </FormControl>

          {formData.source && !['All Units', 'New Units', 'No Bookings', 'Manual'].includes(formData.source) && (
            <div style={{ marginTop: '16px' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', marginBottom: '8px' }}>
                {formData.source_values?.map((value) => (
                  <Chip
                    key={value}
                    label={getSourceValueDisplay(value, formData.source)}
                    onDelete={() => {
                      setFormData(prev => ({
                        ...prev,
                        source_values: prev.source_values?.filter(v => v !== value)
                      }));
                    }}
                    size="small"
                  />
                ))}
              </div>
              <FormControl fullWidth>
                <InputLabel>Add Value</InputLabel>
                <Select
                  value=""
                  onChange={(e) => {
                    const newValue = e.target.value as string;
                    if (newValue && !formData.source_values?.includes(newValue)) {
                      setFormData(prev => ({
                        ...prev,
                        source_values: [...(prev.source_values || []), newValue]
                      }));
                    }
                  }}
                  onClose={() => setSourceValueSearch('')}
                  disabled={isLoading}
                >
                  <ListSubheader>
                    <TextField
                      size="small"
                      autoFocus
                      placeholder="Type to filter..."
                      value={sourceValueSearch}
                      onChange={(e) => setSourceValueSearch(e.target.value)}
                      onClick={(e) => e.stopPropagation()}
                      onKeyDown={(e) => {
                        if (e.key === 'Escape') {
                          setSourceValueSearch('');
                        }
                        e.stopPropagation();
                      }}
                      style={{ width: '100%', marginBottom: '8px' }}
                    />
                  </ListSubheader>
                  <MenuItem value="">
                    <em>Select a value</em>
                  </MenuItem>
                  <VirtualizedMenuItems
                    items={getSourceOptions(formData.source)}
                    onSelect={(value) => {
                      setFormData(prev => ({
                        ...prev,
                        source_values: [...(prev.source_values || []), value]
                      }));
                    }}
                    source={formData.source}
                    unitLists={unitLists}
                    cohortData={cohortData}
                    searchTerm={sourceValueSearch}
                  />
                </Select>
              </FormControl>
            </div>
          )}

          {formData.source === 'Manual' && (
            <>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <input
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.onload = (e) => {
                        const text = e.target?.result as string;
                        setUnitIdsText(text);
                      };
                      reader.onerror = (error) => {
                        console.error('Error reading file:', error);
                        alert('Failed to read file. Please try again.');
                      };
                      reader.readAsText(file);
                    }
                  }}
                  style={{ flex: 1 }}
                />
                {initialData && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleLoadCurrentUnits}
                    disabled={isLoadingUnits}
                    startIcon={isLoadingUnits ? <CircularProgress size={20} /> : <Icon.CloudDownload />}
                  >
                    Load Current Units
                  </Button>
                )}
              </div>
              <TextField
                label={`Unit IDs${unitIdsText ? ` (${parseUnitIds(unitIdsText).length})` : ''}`}
                multiline
                rows={5}
                value={unitIdsText}
                onChange={(e) => setUnitIdsText(e.target.value)}
                placeholder="Enter unit IDs (comma or newline separated)"
                fullWidth
              />
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSubmit}
          color="primary"
          disabled={isSubmitting || !!nameError || !formData.name.trim()}
        >
          {isSubmitting ? <CircularProgress size={24} /> : (initialData ? 'Save' : 'Create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const UnitListsTable: React.FC<UnitListsProps> = ({
  unitLists,
  isLoading,
  refetch,
  searchQuery,
  setSearchQuery,
  locationOptions, 
  isFetchingLocationOptions,
  cohortData ,
  currentUser,
}) => {
  // const {refetch: refetchRebl, data: reblRules, isFetching: isFetchingReblRules} = useGetAllReblRulesQuery();
  const [sortBy, setSortBy] = useState<keyof UnitList>('id');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [refreshingRows, setRefreshingRows] = useState<{ [key: number]: boolean }>({});
  const [selectedUnitList, setSelectedUnitList] = useState<UnitList | null>(null);
  const [linkUnitsToParent] = useLinkUnitsToParentMutation();
  const [downloadingRows, setDownloadingRows] = useState<{ [key: number]: boolean }>({});
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const [createUnitList] = useAddUnitListMutation();
  const [updateUnitList] = useUpdateUnitListMutation();
  const [isRefetching, setIsRefetching] = useState(true);
  const [getUnitList] = useGetUnitListMutation();

  useEffect(() => {
    setIsRefetching(isFetchingLocationOptions || isLoading)
  }, [isFetchingLocationOptions, isLoading]);

  const handleSort = (column: keyof UnitList) => {
    if (sortBy === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortDirection('asc');
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (unitList: UnitList) => {
    if (unitList.admin_only && !currentUser?.admin) {
      // Could add a snackbar or other notification here
      console.warn('Only admins can edit admin-only lists');
      return;
    }
    setSelectedUnitList(unitList);
    setFormDialogOpen(true);
  };

  const handleRefreshRow = async (unitList: UnitList) => {
    try {
      setRefreshingRows(prev => ({
        ...prev,
        [unitList.id]: true
      }));

      await refetch();
    } catch (error) {
      console.error('Failed to refresh unit list:', error);
    } finally {
      setRefreshingRows(prev => ({
        ...prev,
        [unitList.id]: false
      }));
    }
  };

  const handleDownload = async (unitList: UnitList) => {
    try {
      setDownloadingRows(prev => ({
        ...prev,
        [unitList.id]: true
      }));

      // Fetch the unit list data
      const response = await getUnitList(unitList.id as number).unwrap();
      
      // Create CSV content - just the sorted unit IDs, no header
      const csvContent = [...response].sort((a, b) => a - b).join('\n');
      
      // Create blob and download link
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      
      link.setAttribute('href', url);
      link.setAttribute('download', `unit-list-${unitList.id}.csv`);
      link.style.visibility = 'hidden';
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
    } catch (error) {
      console.error('Failed to download unit list:', error);
    } finally {
      setDownloadingRows(prev => ({
        ...prev,
        [unitList.id]: false
      }));
    }
  };

  const filteredAndSortedData = useMemo(() => {
    if (!unitLists) return [];

    let filtered = unitLists;
    
    // Apply search filter
    if (searchQuery) {
        filtered = filtered.filter(list => 
            list.name.toLowerCase() === searchQuery.toLowerCase() ||  // Exact match for chip clicks
            list.name.toLowerCase().includes(searchQuery.toLowerCase()) ||  // Partial match for typing
            list.description?.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }

    // Apply sorting
    return [...filtered].sort((a, b) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];

        if (aValue === bValue) return 0;
        const comparison = aValue < bValue ? -1 : 1;
        return sortDirection === 'asc' ? comparison : -comparison;
    });
  }, [unitLists, searchQuery, sortBy, sortDirection]);

  const paginatedData = useMemo(() => {
    const startIndex = page * rowsPerPage;
    return filteredAndSortedData.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredAndSortedData, page, rowsPerPage]);

  const formatDate = (updatedAt: string | null | undefined, createdAt: string | null | undefined) => {
    if (updatedAt) {
      try {
        return `Last updated: ${format(new Date(updatedAt), 'MMM d, yyyy HH:mm:ss')}`;
      } catch (e) {
        return 'Invalid update date';
      }
    } else if (createdAt) {
      try {
        return `Created: ${format(new Date(createdAt), 'MMM d, yyyy HH:mm:ss')}`;
      } catch (e) {
        return 'Invalid creation date';
      }
    }
    return 'No date available';
  };

  const getValueOptions = (source: string, currentListId: number): string[] => {
    if (!source) return [];
    
    source = source.toUpperCase();
    if (source === "INTERSECTION") {
      return unitLists 
        ? unitLists
            .filter(ul => ul.id !== currentListId) // Exclude current list
            .map(ul => ul.id.toString())
        : [];
    }
    return getGeographyValueOptions(source);
  };

  // Add helper function to get geography value options
  const getGeographyValueOptions = (geographyType: string): string[] => {
    if (!locationOptions || !geographyType) return [];
    return locationOptions[geographyType.toLowerCase()] || [];
  };

  // Add a helper function for formatting creator tooltip
  const formatCreatorTooltip = (createdAt: string | null | undefined) => {
    if (!createdAt) return 'Creation date unknown';
    try {
      return `Created on: ${format(new Date(createdAt), 'MMM d, yyyy HH:mm:ss')}`;
    } catch (e) {
      return 'Invalid creation date';
    }
  };

  // Add helper function to check name uniqueness
  const isNameUnique = (name: string, currentId: number) => {
    return !unitLists?.some(list => 
      list.id !== currentId && // Ignore current list
      list.name.toLowerCase() === name.toLowerCase()
    );
  };

  // Add effect for escape key handling
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        // Get the ID of the row being edited
        const editingId = Object.entries(refreshingRows)
          .find(([_, isRefreshing]) => isRefreshing)?.[0];
        
        if (editingId) {
          handleRefreshRow(unitLists.find(list => list.id.toString() === editingId) as UnitList);
        }
      }
    };

    // Only add listener if we're refreshing a row
    if (Object.values(refreshingRows).some(isRefreshing => isRefreshing)) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [refreshingRows, unitLists]);

  // Add helper function to format creator name (similar to DateLists)
  const formatCreatorName = (email: string) => {
    return email ? email.split('@')[0] : '-';
  };

  // Add handleAdd
  const handleAdd = () => {
    setSelectedUnitList(null);
    setFormDialogOpen(true);
  };

  // Modify handleFormSubmit
  const handleFormSubmit = async (data: Omit<UnitList, 'id'> & { unitIds?: number[] }) => {
    try {
      setIsRefetching(true);
      const { unitIds, ...listData } = data;
      
      if (selectedUnitList) {
        await updateUnitList({ id: selectedUnitList.id, data: listData }).unwrap();
        if (unitIds && unitIds.length > 0) {
          await linkUnitsToParent({
            id: selectedUnitList.id,
            data: unitIds
          });
        }
        setSuccessMessage('Unit list updated successfully');
      } else {
        const newListId = await createUnitList(listData).unwrap();
        if (unitIds && unitIds.length > 0) {
          await linkUnitsToParent({
            id: newListId,
            data: unitIds
          });
        }
        setSuccessMessage('Unit list created successfully');
      }
      await refetch();
      setFormDialogOpen(false);
    } finally {
      setIsRefetching(false);
    }
  };

  const classes = UiUtils.useTableStyles();

  return (
    <Paper style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box style={{ 
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
      }}>
        <TextField
          label="Search Unit Lists"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ width: '450px' }}
          InputProps={{
            startAdornment: <Icon.Search style={{ color: 'gray', marginRight: 8 }} />,
            endAdornment: searchQuery && (
              <IconButton
                size="small"
                onClick={() => setSearchQuery('')}
                style={{ padding: 4 }}
              >
                <Icon.Close fontSize="small" />
              </IconButton>
            )
          }}
        />
        <Tooltip title="Add Unit List">
          <IconButton
            onClick={handleAdd}
            size="small"
            disabled={isRefetching}
          >
            <Icon.Add fontSize="small"/>
          </IconButton>
        </Tooltip>

        <Tooltip title={isRefetching ? "Loading..." : "Refresh data"}>
          <IconButton
              onClick={() => refetch()}
              disabled={isRefetching}
              size="small"
          >
            {isRefetching ? (
                <CircularProgress size={16} />
            ) : (
                <Icon.Replay fontSize="small"/>
            )}
          </IconButton>
        </Tooltip>
      </Box>

      <div style={{ position: 'relative' }}>
        {(isRefetching) && (
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}>
            <CircularProgress />
          </div>
        )}

        <TableContainer 
            className={classes.tableContainer}
            style={{ height: '600px', overflow: 'auto' }}
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell className={`sticky-header-cell ${classes.stickyHeader}`}>
                  <TableSortLabel
                    active={sortBy === 'id'}
                    direction={sortBy === 'id' ? sortDirection : 'asc'}
                    onClick={() => handleSort('id')}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>
                <TableCell className={`sticky-header-cell ${classes.stickyHeader}`}>
                  <TableSortLabel
                    active={sortBy === 'name'}
                    direction={sortBy === 'name' ? sortDirection : 'asc'}
                    onClick={() => handleSort('name')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell className={`sticky-header-cell ${classes.stickyHeader}`}>
                  <TableSortLabel
                    active={sortBy === 'admin_only'}
                    direction={sortBy === 'admin_only' ? sortDirection : 'asc'}
                    onClick={() => handleSort('admin_only')}
                  >
                    Admin
                  </TableSortLabel>
                </TableCell>
                <TableCell className={`sticky-header-cell ${classes.stickyHeader}`}>
                  <TableSortLabel
                    active={sortBy === 'creator'}
                    direction={sortBy === 'creator' ? sortDirection : 'asc'}
                    onClick={() => handleSort('creator')}
                  >
                    Creator
                  </TableSortLabel>
                </TableCell>
                <TableCell className={`sticky-header-cell ${classes.stickyHeader}`}>
                  <TableSortLabel
                    active={sortBy === 'source'}
                    direction={sortBy === 'source' ? sortDirection : 'asc'}
                    onClick={() => handleSort('source')}
                  >
                    Source
                  </TableSortLabel>
                </TableCell>
                <TableCell className={`sticky-header-cell ${classes.stickyHeader}`}>
                  Unit Count
                </TableCell>
                <TableCell className={`sticky-header-cell ${classes.stickyHeader}`}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((list) => (
                <TableRow 
                    key={list.id} 
                    id={`unit-list-${list.id}`}
                    style={list.name === searchQuery ? { backgroundColor: 'rgba(25, 118, 210, 0.08)' } : undefined}
                >
                  <TableCell>{list.id}</TableCell>
                  <TableCell>{list.name}</TableCell>
                  <TableCell>
                    <Chip
                      label={list.admin_only ? 'Yes' : 'No'}
                      size="small"
                      color={list.admin_only ? 'primary' : 'default'}
                    />
                  </TableCell>
                  <TableCell>
                    <Tooltip title={formatCreatorTooltip(list.created_at)}>
                      <span>{formatCreatorName(list.creator)}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={list.source_values ? list.source_values.join(', ') : ''}>
                      <span>{list.source}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{list.unit_count}</TableCell>
                  <TableCell>
                    <div style={{ display: 'flex', gap: '4px' }}>
                      <Tooltip title={
                          list.admin_only && !currentUser?.admin 
                              ? "Only admins can edit admin-only lists" 
                              : "Edit"
                      }>
                          <span>
                              <IconButton
                                  size="small"
                                  onClick={() => handleEdit(list)}
                                  disabled={
                                      isRefetching ||
                                      refreshingRows[list.id] || 
                                      (list.admin_only && !currentUser?.admin)
                                  }
                              >
                                  {isRefetching ? (
                                      <CircularProgress size={16} />
                                  ) : (
                                      <Icon.Edit fontSize="small" />
                                  )}
                              </IconButton>
                          </span>
                      </Tooltip>
                      <Tooltip title="Download Units">
                        <IconButton
                          size="small"
                          onClick={() => handleDownload(list)}
                          disabled={isRefetching || downloadingRows[list.id]}
                        >
                          {downloadingRows[list.id] ? (
                            <CircularProgress size={16} />
                          ) : (
                            <Icon.VerticalAlignBottom fontSize="small" />
                          )}
                        </IconButton>
                      </Tooltip>
                      {list.source !== 'Manual' && (
                        <Tooltip title={
                            list.admin_only && !currentUser?.admin 
                                ? "Only admins can refresh admin-only lists" 
                                : formatDate(list.updated_at, list.created_at)
                        }>
                            <span>
                                <IconButton
                                    size="small"
                                    onClick={() => handleRefreshRow(list)}
                                    disabled={
                                        isRefetching || 
                                        refreshingRows[list.id] || 
                                        (list.admin_only && !currentUser?.admin)
                                    }
                                >
                                    {refreshingRows[list.id] ? (
                                        <CircularProgress size={16} />
                                    ) : (
                                        <Icon.Replay fontSize="small" />
                                    )}
                                </IconButton>
                            </span>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        count={filteredAndSortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <UnitListFormDialog
        open={formDialogOpen}
        onClose={() => {
          setFormDialogOpen(false);
          setSuccessMessage('');
          setSelectedUnitList(null);
        }}
        onSubmit={handleFormSubmit}
        initialData={selectedUnitList}
        unitLists={unitLists}
        locationOptions={locationOptions}
        cohortData={cohortData}
        currentUser={currentUser}
        isLoading={isRefetching}
        successMessage={successMessage}
      />
    </Paper>
  );
};

export default UnitListsTable;