import React, {ReactElement, useEffect, useState, useRef} from 'react';
import {
    TextField,
    Checkbox,
    FormControlLabel,
    Select as MuiSelect,
    MenuItem,
    IconButton,
    Tooltip as MuiTooltip,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TableSortLabel,
    Paper,
} from '@material-ui/core';
import {
    Edit as EditIcon,
    Refresh as RefreshIcon,
    CheckCircle as CheckCircleIcon,
    Cancel as CancelIcon,
    BarChart as BarChartIcon,
    Add as AddIcon,
} from '@material-ui/icons';
import './Admin.scss';
import {ReblOption, ReblOptionRequest, ReblOptions, SelectOption} from "../../types";
import {useAddReblOptionMutation, useUpdateReblOptionMutation} from "../../store";
import {UiUtils} from "../../utils";
import { ReblOptionDialog, ReblOptionFormData } from './ReblOptionDialog';

interface ReblOptionsTableProps {
    selectedOptionTable: string;
    setSelectedOptionTable: (o: string) => void;
    searchValue: string;
    setSearchValue: (o: string) => void;
    reblOptionsData: ReblOptions;
    refetchReblOptions: () => void;
    isFetchingReblOptionsData: boolean;
}

type ReblOptionsTableComponent = (props: ReblOptionsTableProps) => ReactElement<any, any> | null;

type Order = 'asc' | 'desc';
interface Column {
    id: string;
    label: string;
    width?: number;
    align?: 'left' | 'right' | 'center';
    sortable?: boolean;
    format?: (value: any, row?: ReblOption) => React.ReactNode;
}

const CellWithTooltip: React.FC<{
    content: React.ReactNode;
    align?: 'left' | 'right' | 'center';
    width?: number;
}> = ({ content, align, width }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const cellRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (cellRef.current) {
            setShowTooltip(
                cellRef.current.scrollWidth > cellRef.current.clientWidth
            );
        }
    }, [content]);

    // Convert content to string for tooltip
    const tooltipContent = typeof content === 'string' ? content : 
        typeof content === 'number' ? content.toString() : '';

    const cellContent = (
        <div
            ref={cellRef}
            style={{
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        >
            {content}
        </div>
    );

    return (
        <TableCell
            align={align || 'left'}
            style={{
                width,
                maxWidth: width,
                padding: '4px 16px',
            }}
        >
            {showTooltip && tooltipContent ? (
                <MuiTooltip title={tooltipContent}>
                    {cellContent}
                </MuiTooltip>
            ) : (
                cellContent
            )}
        </TableCell>
    );
};

export const ReblOptionsTable: ReblOptionsTableComponent = (props) => {
    const {
        selectedOptionTable,
        setSelectedOptionTable,
        searchValue,
        setSearchValue,
        reblOptionsData,
        refetchReblOptions,
        isFetchingReblOptionsData
    } = props;

    const [filteredData, setFilteredData] = useState<ReblOption[]>([]);
    const [filters, setFilters] = useState({
        showActive: true,
        showCompoundable: false
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [editingOption, setEditingOption] = useState<ReblOption | null>(null);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>('id');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const reblOptions: SelectOption[] = Object.keys(UiUtils.reblOptionsMap).map(k => UiUtils.getSelectOption(k));
    const [addOption] = useAddReblOptionMutation();
    const [updateOption] = useUpdateReblOptionMutation();

    useEffect(() => {
        if (!isFetchingReblOptionsData) {
            let filteredOptions = [...reblOptionsData[UiUtils.reblOptionsMap[selectedOptionTable].plural]];
            if (filters.showActive) filteredOptions = filteredOptions.filter((o) => o.active);
            if (selectedOptionTable === "Action" && filters.showCompoundable) {
                filteredOptions = filteredOptions.filter((o) => o.compoundable);
            }

            const searchTerms = searchValue.toLowerCase().split(" ");
            filteredOptions = filteredOptions.filter((o: ReblOption) => 
                searchTerms.every(term => 
                    o.id.toString().includes(term) ||
                    o[UiUtils.reblOptionsMap[selectedOptionTable].key].toLowerCase().includes(term)
                )
            );

            setFilteredData(filteredOptions);
        }
    }, [filters, selectedOptionTable, reblOptionsData, searchValue, isFetchingReblOptionsData]);

    const handleSaveOption = async (formData: ReblOptionFormData) => {
        const option: ReblOptionRequest = {
            option_type: selectedOptionTable.replace(" ", "_"),
            value: formData.value,
            active: formData.active,
            compoundable: formData.isCompoundable,
            category: formData.category,
            owner_facing_tooltip: formData.ownerFacingTooltip
        }
        try {
            if (editingOption) {
                await updateOption({
                    id: editingOption.id,
                    data: option
                });
            } else {
                await addOption(option);
            }
            await refetchReblOptions();
            setDialogOpen(false);
        } finally {
            setEditingOption(null);
        }
    };

    const getColumns = (): Column[] => {
        const baseColumns: Column[] = [
            {
                id: 'id',
                label: 'ID',
                width: 70,
                sortable: true,
            },
            {
                id: UiUtils.reblOptionsMap[selectedOptionTable].key,
                label: selectedOptionTable,
                sortable: true,
                width: 200,
            },
            {
                id: 'active',
                label: 'Active',
                width: 80,
                align: 'center',
                sortable: true,
                format: (value: boolean) => value ? 
                    <CheckCircleIcon style={{ color: 'green' }} /> : 
                    <CancelIcon color="error" />
            },
            {
                id: 'edit',
                label: 'Edit',
                width: 80,
                align: 'center',
                format: (_, row: ReblOption) => (
                    <IconButton
                        size="small"
                        onClick={() => {
                            setEditingOption(row);
                            setDialogOpen(true);
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                ),
            },
        ];

        if (selectedOptionTable === "Action") {
            baseColumns.splice(2, 0, {
                id: 'compoundable',
                label: 'Compoundable',
                width: 110,
                align: 'center',
                sortable: true,
                format: (value: boolean) => value ? 
                    <BarChartIcon style={{ color: 'green' }} /> : 
                    <CancelIcon color="disabled" />
            });
        } else if (selectedOptionTable === "Strategy") {
            baseColumns.splice(2, 0, 
                {
                    id: 'category',
                    label: 'Category',
                    sortable: true,
                    width: 150,
                },
                {
                    id: 'owner_facing_tooltip',
                    label: 'Owner Facing Tooltip',
                    sortable: true,
                    width: 300,
                }
            );
        }

        return baseColumns;
    };

    const handleSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortData = (data: ReblOption[]): ReblOption[] => {
        return [...data].sort((a, b) => {
            const aValue = a[orderBy as keyof ReblOption];
            const bValue = b[orderBy as keyof ReblOption];
            
            if (!aValue || !bValue) return 0;
            
            return (
                order === 'desc' ? 
                    (bValue < aValue ? -1 : 1) :
                    (bValue < aValue ? 1 : -1)
            );
        });
    };

    const handleTableChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedOptionTable(event.target.value as string);
    };

    return (
        <div className="rebl-options-container">
            <div className="admin-utility-row">
                <div className="admin-controls-group">
                    <MuiSelect
                        value={selectedOptionTable}
                        onChange={handleTableChange}
                        variant="outlined"
                        style={{ width: "200px" }}
                    >
                        {reblOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.display}
                            </MenuItem>
                        ))}
                    </MuiSelect>

                    <TextField
                        label="Search"
                        variant="outlined"
                        size="small"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        style={{width: "200px"}}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filters.showActive}
                                onChange={() => setFilters(prev => ({...prev, showActive: !prev.showActive}))}
                                color="primary"
                            />
                        }
                        label="Active"
                    />

                    {selectedOptionTable === "Action" && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filters.showCompoundable}
                                    onChange={() => setFilters(prev => ({...prev, showCompoundable: !prev.showCompoundable}))}
                                    color="primary"
                                />
                            }
                            label="Compoundable"
                        />
                    )}

                    <div className="admin-actions">
                        <MuiTooltip title="Add New Option">
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    setEditingOption(null);
                                    setDialogOpen(true);
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </MuiTooltip>

                        <MuiTooltip title="Refresh Options">
                            <IconButton onClick={refetchReblOptions}>
                                <RefreshIcon />
                            </IconButton>
                        </MuiTooltip>
                    </div>
                </div>
            </div>

            <div className="admin-list">
                <Paper>
                    <TableContainer style={{ height: 500 }}>
                        {isFetchingReblOptionsData ? (
                            <div className="loading-container">
                                <CircularProgress />
                            </div>
                        ) : (
                            <Table 
                                stickyHeader 
                                size="small"
                            >
                                <TableHead>
                                    <TableRow>
                                        {getColumns().map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align || 'left'}
                                                style={{ width: column.width }}
                                            >
                                                {column.sortable ? (
                                                    <TableSortLabel
                                                        active={orderBy === column.id}
                                                        direction={orderBy === column.id ? order : 'asc'}
                                                        onClick={() => handleSort(column.id)}
                                                    >
                                                        {column.label}
                                                    </TableSortLabel>
                                                ) : (
                                                    column.label
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortData(filteredData)
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => (
                                            <TableRow hover key={row.id}>
                                                {getColumns().map((column) => {
                                                    const content = column.format ? 
                                                        column.format(row[column.id as keyof ReblOption], row) : 
                                                        row[column.id as keyof ReblOption];

                                                    // Don't use tooltip for cells with icons
                                                    if (column.id === 'active' || column.id === 'compoundable' || column.id === 'edit') {
                                                        return (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align || 'left'}
                                                                style={{
                                                                    width: column.width,
                                                                    padding: '4px 16px',
                                                                }}
                                                            >
                                                                {content}
                                                            </TableCell>
                                                        );
                                                    }

                                                    return (
                                                        <CellWithTooltip
                                                            key={column.id}
                                                            content={content}
                                                            align={column.align}
                                                            width={column.width}
                                                        />
                                                    );
                                                })}
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        )}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={(_, newPage) => setPage(newPage)}
                        onChangeRowsPerPage={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                        }}
                    />
                </Paper>
            </div>

            <ReblOptionDialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                    setEditingOption(null);
                }}
                onSave={handleSaveOption}
                selectedOptionTable={selectedOptionTable}
                initialData={editingOption}
                isEdit={!!editingOption}
            />
        </div>
    );
};
