import {apiUnitNightLists} from "./api";
import { UnitNightList, UnitNightListInput } from '../types/UnitNightList';

// Endpoints
export const unitNightListsApi = apiUnitNightLists.injectEndpoints({
    endpoints: (builder) => ({
        getAllUnitNightLists: builder.query<UnitNightList[], void>({
            query: () => 'unit_night_lists',
            providesTags: ['UnitNightList'],
        }),
        createUnitNightList: builder.mutation<number, UnitNightListInput>({
            query: (data) => ({
                url: 'unit_night_lists',
                method: 'POST',
                body: JSON.stringify(data),
            }),
            invalidatesTags: ['UnitNightList'],
        }),
        updateUnitNightList: builder.mutation<UnitNightList, { id: number; data: Partial<UnitNightListInput> }>({
            query: ({ id, data }) => ({
                url: `unit_night_lists/${id}`,
                method: 'PUT',
                body: JSON.stringify(data),
            }),
            invalidatesTags: ['UnitNightList'],
        }),

    }),
    overrideExisting: false,
});

// Hooks
export const {
    useGetAllUnitNightListsQuery,
    useCreateUnitNightListMutation,
    useUpdateUnitNightListMutation,
} = unitNightListsApi;