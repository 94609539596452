import React, {useEffect, useRef, useState} from 'react';
import {Loading} from '../index';
import './Tables.scss';
import {
    Cohort,
    ComparisonDate,
    CurrentUser,
    DemandInfluenceFormOptions,
    DemandInfluenceFormType,
    Holiday,
    HolidayEvent,
    LocationOptions,
} from "../../types";
import * as Icon from '@material-ui/icons';
import {
    Button,
    ButtonProps as MuiButtonProps,
    ButtonGroup,
    Checkbox,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
} from '@material-ui/core';
import {
    useAddHolidayEventMutation,
    useConvertAddressToCoordinatesMutation,
    useUpdateHolidayEventMutation,
} from "../../store";
import {UiUtils} from "../../utils";
import {addDays} from "date-fns";
import { GeographySelector } from './GeographySelector';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


interface HolidaysModalProps {
    currentUser: CurrentUser;
    selectedHoliday: Holiday;
    selectedHolidayEvent: HolidayEvent;
    formType: DemandInfluenceFormType;
    closeModal: (refresh?: boolean) => void;
    isFetchingHolidays: boolean;
    locationOptions: LocationOptions;
    isFetchingLocationOptions: boolean;
    allCohorts: Cohort[];
    isFetchingCohorts: boolean;
    defaultComparisonDates: Partial<ComparisonDate>[];
    isFetchingDefaultCDs: boolean;
    onUpdateHoliday: (holidayEvent: Partial<HolidayEvent>) => void;
}

interface FooterButtonProps extends MuiButtonProps {
    onClick: () => void;
}

export const HolidayEventModal: React.FC<HolidaysModalProps> = (props) => {
    const {
        currentUser,
        selectedHoliday,
        selectedHolidayEvent,
        formType,
        closeModal,
        isFetchingHolidays,
        locationOptions,
        isFetchingLocationOptions,
        allCohorts,
        isFetchingCohorts,
        defaultComparisonDates,
        isFetchingDefaultCDs,
        onUpdateHoliday
    } = props

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [disableSave, setDisableSave] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isFetchingLatLng, setIsFetchingLatLng] = useState<boolean>(false);
    const [validationErrorMsg, setValidationErrorMsg] = useState<string>("");
    const [showGeography, setShowGeography] = useState<boolean>(false);
    const [showDefaultCD, setShowDefaultCD] = useState<boolean>(true);
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [isPasted, setIsPasted] = useState<boolean>(false);
    const eventTypeOptions = [
        "Concert",
        "Festival",
        "Holiday",
        "Political",
        "Sporting Event",
        "Other"
    ].map(o => UiUtils.getSelectOption(o));
    const classificationOptions = [
        "One Time Event",
        "Annual Event (same date)",
        "Annual Event (same day)",
        "Annual Event (dates vary)",
    ].map(o => UiUtils.getSelectOption(o));

    // Form fields
    const [eventTitle, setEventTitle] = useState<string>("");
    const [active, setActive] = useState<boolean>(true);
    const [eventType, setEventType] = useState<string>();
    const [top50, setTop50] = useState<boolean>(false);
    const [classification, setClassification] = useState<string>();
    const [minStay, setMinStay] = useState<number>(3);
    const [priority, setPriority] = useState<number>(1000);
    const [levelCategory, setLevelCategory] = useState<string>("")
    const [address, setAddress] = useState<string>("");
    const [lat, setLat] = useState<number>();
    const [lng, setLng] = useState<number>();
    const [superRegions, setSuperRegions] = useState<string>();
    const [regions, setRegions] = useState<string>();
    const [states, setStates] = useState<string>();
    const [cohorts, setCohorts] = useState<string>();
    const [opsMarkets, setOpsMarkets] = useState<string>();
    const [unitCodePrefixes, setUnitCodePrefixes] = useState<string>();
    const [comparisonDates, setComparisonDates] = useState<Partial<ComparisonDate>[]>([]);
    const [geographySummary, setGeographySummary] = useState<string>("");
    const [selectedGeographyType, setSelectedGeographyType] = useState<string>('');

    // Comparison Dates
    const maxRange = 60; // days
    const today = new Date(new Date().toISOString().split("T")[0])
    const todayString = today.toISOString().split("T")[0];
    const [numberOfDays, setNumberOfDays] = useState<number>(1);
    const [startDate, setStartDate] = useState<string>(todayString);
    const [endDate, setEndDate] = useState<string>(todayString);
    const previousValues = useRef({startDate, endDate, numberOfDays, priority, levelCategory});

    const [addHolidayEvent] = useAddHolidayEventMutation();
    const [updateHolidayEvent] = useUpdateHolidayEventMutation();
    const [convertAddress] = useConvertAddressToCoordinatesMutation();

    const lookupLatLng = async () => {
        setIsFetchingLatLng(true);
        await convertAddress({address: address}).then((response) => {
            if (!!response["error"]) {
                console.log(response["error"]);
            }
            else {
                const coords = response["data"];
                if (coords.latitude) setLat(coords.latitude);
                if (coords.longitude) setLng(coords.longitude);
            }
            setIsFetchingLatLng(false);
        })
    }

    const handleSave = async () => {
        setIsSaving(true);
        let updatedHolidayEvent: Partial<HolidayEvent> = {
            holiday_id: selectedHoliday.id,
            title: eventTitle,
            active: active,
            classification: classification,
            event_type: eventType,
            top_50: top50,
            address: address ?? null,
            lat: lat ?? null,
            lng: lng ?? null,
            min_stay: minStay,
            priority: priority,
            super_regions: superRegions ?? null,
            states: states ?? null,
            regions: regions ?? null,
            cohorts: cohorts ?? null,
            unit_code_prefixes: unitCodePrefixes ?? null,
            ops_markets: opsMarkets ?? null,
            creator_email: currentUser.email,
            comparison_dates: comparisonDates.map(cd => {
                return {
                    date: cd.date,
                    comparison_date: cd.comparison_date ?? null,
                    is_holiday: cd.is_holiday,
                }
            })
        }

        if (formType === DemandInfluenceFormOptions.EDIT) {
            updatedHolidayEvent["id"] = selectedHolidayEvent?.id;
            updatedHolidayEvent["created_at"] = selectedHolidayEvent.created_at;
        }

        if (formType === DemandInfluenceFormOptions.EDIT) {
            await updateHolidayEvent({
                event_id: selectedHolidayEvent.id,
                data: updatedHolidayEvent
            }).then((response) => {
                if (!!response["error"]) {
                    console.log(response["error"]);
                }
                setIsSaving(false);
                onUpdateHoliday(updatedHolidayEvent);
                closeModal(false);
            })
        }
        else { // ADD and COPY
            await addHolidayEvent({holiday_id: selectedHoliday.id, data: updatedHolidayEvent}).then((response) => {
                setIsSaving(false);
                if (!!response["error"]) {
                    console.log(response["error"]);
                }
                else {
                    closeModal(true);
                }
            })
        }
    }

    const getDateTooltip = (dateString: string, dateHoliday: string, displayIcon: boolean) => {
        if (!dateHoliday) {
            return <>{dateString}</>;
        }

        return (
            <Tooltip
                title={dateHoliday}
                placement="bottom"
            >
                <div style={{height: "30px", display: "unset"}}>
                    {dateHoliday ? <strong>{dateString}</strong> : <>{dateString}</>}
                    {displayIcon && <Icon.Warning style={{ width: 20, height: 20 }}/>}
                </div>
            </Tooltip>
        );
    };

    const handleStartDateChange = (date: Date | null, value?: string | null) => setStartDate(value ?? '');
    const handleEndDateChange = (date: Date | null, value?: string | null) => setEndDate(value ?? '');

    const getComparisonDate = (date: string): Partial<ComparisonDate> => {
        let matchingCD: Partial<ComparisonDate> = null;
        defaultComparisonDates.forEach(cd => {
            if (cd.date === date) {
                matchingCD = cd;
                return
            }
        });

        return matchingCD
    }

    const setDefaultComparisonDates = (cds: Partial<ComparisonDate>[]) => {
        let newComparisonDates: Partial<ComparisonDate>[] = [];

        cds?.forEach(cd => {
            let defaultCD = getComparisonDate(cd.date);

            newComparisonDates.push({
                date: cd.date,
                date_holiday: defaultCD?.date_holiday,
                default_comparison_date: defaultCD?.comparison_date,
                default_comparison_date_holiday: defaultCD?.comparison_date_holiday,
                comparison_date: cd.comparison_date,
                is_holiday: cd.is_holiday,
            });
        });

        setComparisonDates(newComparisonDates.sort(((a, b) => +new Date(a.date) - +new Date(b.date))));
    }

    const setComparisonDatesFromDates = (start: Date, end: Date) => {
        // numberOfDays and endDate change each other, so, just want to add dates between start and end
        let newComparisonDates: Partial<ComparisonDate>[] = [];

        while (start <= end) {
            let defaultCD = getComparisonDate(start.toISOString().split("T")[0])
            newComparisonDates.push({
                date: start.toISOString().split("T")[0],
                date_holiday: defaultCD?.date_holiday,
                default_comparison_date: defaultCD?.comparison_date,
                default_comparison_date_holiday: defaultCD?.comparison_date_holiday,
                comparison_date: defaultCD?.comparison_date,
                is_holiday: true,
            });
            start = addDays(start, 1);
        }

        setDefaultComparisonDates(newComparisonDates);
    }

    const updateCD = (idx: number, cDate: string, isHoliday: boolean) => {
        let newComparisonDates = [...comparisonDates];
        let updatedCD = newComparisonDates[idx];
        updatedCD.comparison_date = cDate;
        updatedCD.is_holiday = isHoliday;

        newComparisonDates[idx] = updatedCD;
        setDefaultComparisonDates(newComparisonDates);
    }

    const removeDate = (idx: number) => {
        let newComparisonDates = [...comparisonDates];
        newComparisonDates.splice(idx, 1);
        setDefaultComparisonDates(newComparisonDates);
    }

    const getGeographySummary = () => {
        let includedGeography = "";
        [
            {label: "Super Regions", value: superRegions},
            {label: "Regions", value: regions},
            {label: "States", value: states},
            {label: "Cohorts", value: cohorts},
            {label: "Ops Markets", value: opsMarkets},
            {label: "Unitcode Prefixes", value: unitCodePrefixes},
        ].forEach(geography => {
            if (!!geography.value) {
                includedGeography += ` ${geography.value.split(",").length} ${geography.label}(s);`
            }
        });

        setGeographySummary(includedGeography);
        return includedGeography
    }

    const setLatLngFromClipboard = () => {
        navigator.clipboard.readText().then(s => {
            const coordinates = s.split(",")
            if (coordinates.length === 2) {
                setLat(parseFloat(coordinates[0]));
                setLng(parseFloat(coordinates[1]));
            }
        });
        setIsPasted(true);
        setIsCopied(false);
    }

    const copyLatLngFromClipboard = () => {
        navigator.clipboard.writeText(`${lat},${lng}`);
        setIsCopied(true);
        setIsPasted(false);
    }

    const clearGeographyValues = (newType: string) => {
        // Don't clear the value of the selected type
        if (newType !== 'super_regions') setSuperRegions('');
        if (newType !== 'regions') setRegions('');
        if (newType !== 'states') setStates('');
        if (newType !== 'cohorts') setCohorts('');
        if (newType !== 'ops_markets') setOpsMarkets('');
        if (newType !== 'unitcode_prefixes') setUnitCodePrefixes('');
    };

    useEffect(() => {
        if (isFetchingHolidays) return;

        if (!!selectedHolidayEvent) {
            setEventTitle(selectedHolidayEvent.title);
            setActive(selectedHolidayEvent.active);
            setEventType(selectedHolidayEvent.event_type);
            setTop50(selectedHolidayEvent.top_50);
            setClassification(selectedHolidayEvent.classification);
            setMinStay(selectedHolidayEvent.min_stay)
            setPriority(selectedHolidayEvent.priority)
            setAddress(selectedHolidayEvent.address);
            setLat(selectedHolidayEvent.lat);
            setLng(selectedHolidayEvent.lng);
            setComparisonDates(selectedHolidayEvent.comparison_dates);
            setDefaultComparisonDates(selectedHolidayEvent.comparison_dates);
            setSuperRegions(selectedHolidayEvent.super_regions);
            setStates(selectedHolidayEvent.states);
            setRegions(selectedHolidayEvent.regions);
            setCohorts(selectedHolidayEvent.cohorts);
            setOpsMarkets(selectedHolidayEvent.ops_markets);
            setUnitCodePrefixes(selectedHolidayEvent.unit_code_prefixes);

            const sortedDates = selectedHolidayEvent.comparison_dates?.map(cd => cd.date).sort((a, b) => +new Date(a) - +new Date(b));
            if (sortedDates?.length > 0) {
                setStartDate(sortedDates[0])
                setEndDate(sortedDates[sortedDates.length - 1])
            }
        }
        setIsLoading(false);
    }, [selectedHolidayEvent, isFetchingHolidays]);

    useEffect(() => {
        if (previousValues.current.levelCategory !== levelCategory) {
            for (const category of UiUtils.levelCategories) {
                if (levelCategory === category.category) {
                    setPriority(category.threshold)
                    previousValues.current.priority = category.threshold;
                    previousValues.current.levelCategory = category.category;
                    return;
                }
            }
        } else if (previousValues.current.priority !== priority) { // priority changed
            for (const category of UiUtils.levelCategories) {
                if (priority >= category.threshold) {
                    setLevelCategory(category.category);
                    previousValues.current.levelCategory = category.category;
                    previousValues.current.priority = category.threshold;
                    return;
                }
            }
        }
    }, [priority, levelCategory]);
    
    useEffect(() => {
        if (isFetchingHolidays) return;

        let errorMessages = [];
        let warningMessages = [];

        if (!eventTitle) {
            errorMessages.push("Name is required");
        }
        else {
            const titleLowerCase = eventTitle.toLowerCase()
            selectedHoliday.holiday_events
                .filter((e) => {
                    // No errors or warnings against the name we're editing
                    if (!!selectedHolidayEvent && formType === DemandInfluenceFormOptions.EDIT) {
                        return e.id !== selectedHolidayEvent.id
                    }
                    return true;
                })
                .forEach((e) => {
                    const hLower = e.title.toLowerCase();
                    if (formType !== DemandInfluenceFormOptions.EDIT && hLower === titleLowerCase) {
                        errorMessages.push("Name must be unique")
                    }
                    else if(hLower.includes(titleLowerCase) || titleLowerCase.includes(hLower)) {
                        warningMessages.push("Name seems similar to another holiday")
                    }
                })
        }

        if (!classification) {errorMessages.push("Classification is required")}
        if (!eventType) {errorMessages.push("Event Type is required")}

        const geographySummary = getGeographySummary();
        if (!geographySummary) warningMessages.push("No geography selected -- This will become a global event")
        else if (geographySummary.split(";").filter( o => !!o).length > 1) {
            warningMessages.push("Please use only one geography; Found " + geographySummary);
        }

        if (!comparisonDates || comparisonDates.length === 0) {
            errorMessages.push("You must add comparison dates even for OTEs")
        }

        errorMessages = Array.from(new Set(errorMessages));
        warningMessages = Array.from(new Set(warningMessages));

        if (errorMessages.length > 0) {
            setValidationErrorMsg(`${errorMessages.length} error(s); ${errorMessages[0]} `);
        }
        else if (warningMessages.length > 0) {
            setValidationErrorMsg(`${warningMessages.length} warning(s); ${warningMessages[0]} `);
        }
        else {setValidationErrorMsg("")}

        setDisableSave(errorMessages.length > 0);

    }, [eventTitle, eventType, classification, isFetchingHolidays, comparisonDates, superRegions, regions, states, cohorts, unitCodePrefixes, opsMarkets]);

    useEffect(() => {
        try {
            const start = new Date(startDate)
            const oldStart = new Date(previousValues.current.startDate)

            const end = new Date(endDate)
            const oldEnd = new Date(previousValues.current.endDate)

            let newEnd = UiUtils.addDaysToDateString(startDate, numberOfDays - 1);
            let newNumberOfDays = UiUtils.dateDiffDays(endDate, startDate) + 1;

            // Not allowed: Setting end before start, setting start before today
            if ( // Cannot rely on DatePicker min/max as you can bypass with a keyboard
                numberOfDays < 1 ||
                !startDate ||
                // start < today ||
                !endDate ||
                (endDate !== previousValues.current.endDate && end < start)
            ) {
                setStartDate(previousValues.current.startDate);
                setEndDate(previousValues.current.endDate);
                setNumberOfDays(previousValues.current.numberOfDays);
            }

            // 1. Change the start date
            //     a. Goes into the past: invalid
            //     b. Precedes end:
            //         i. By more than 60 days: Number of days slams to 60 and end date moves to follow start
            //        ii. Otherwise: End stays the same, Number of days changes
            //     c. Same or greater than end: End changes, number of days stays the same
            // 2. Change end date
            //     a. Precedes start: Invalid, end date does not change
            //     b. Same or greater than start:
            //         i. less than max (60 days): number of days changes
            //        ii. over max: invalid
            // 3. Number of days
            //     a. Negative: invalid
            //     b. 0 to limit: End changes
            previousValues.current.startDate = startDate;

            if (start > oldStart) {
                if (start <= end) {
                    previousValues.current.numberOfDays = newNumberOfDays;
                    previousValues.current.endDate = endDate;
                    setNumberOfDays(newNumberOfDays);
                }
                else {
                    previousValues.current.numberOfDays = numberOfDays;
                    previousValues.current.endDate = newEnd;
                    setEndDate(newEnd)
                }
            }
            else if (start < oldStart) {
                if (newNumberOfDays > maxRange) {
                    newNumberOfDays = maxRange;
                    newEnd = UiUtils.addDaysToDateString(startDate, newNumberOfDays - 1);
                    previousValues.current.endDate = newEnd;
                    previousValues.current.numberOfDays = newNumberOfDays;
                    setNumberOfDays(newNumberOfDays);
                    setEndDate(newEnd);
                }
                else {
                    previousValues.current.endDate = endDate;
                    previousValues.current.numberOfDays = newNumberOfDays;
                    setNumberOfDays(newNumberOfDays);
                }
            }
            else if (previousValues.current.endDate !== endDate) {
                previousValues.current.endDate = endDate;
                previousValues.current.numberOfDays = newNumberOfDays;
                setNumberOfDays(newNumberOfDays);
            }
            else if (previousValues.current.numberOfDays !== numberOfDays) {
                previousValues.current.numberOfDays = numberOfDays;
                previousValues.current.endDate = newEnd;
                setEndDate(newEnd)
            }
        } catch (e) {
        } finally {
        }

    }, [startDate, endDate, numberOfDays]);

    useEffect(() => {
        if (superRegions) setSelectedGeographyType('super_regions');
        else if (regions) setSelectedGeographyType('regions');
        else if (states) setSelectedGeographyType('states');
        else if (cohorts) setSelectedGeographyType('cohorts');
        else if (opsMarkets) setSelectedGeographyType('ops_markets');
        else if (unitCodePrefixes) setSelectedGeographyType('unitcode_prefixes');
    }, [superRegions, regions, states, cohorts, opsMarkets, unitCodePrefixes]);

    const getFooterButtons = (): FooterButtonProps[] => {
        const buttons: FooterButtonProps[] = [];

        // Cancel button
        buttons.push({
            children: "Cancel",
            onClick: () => closeModal(),
            variant: "outlined",
        });

        // Save button - always show it, just disable if there are errors
        buttons.push({
            children: isSaving ? (
                <CircularProgress size={24} />
            ) : (
                <>
                    {validationErrorMsg && <Icon.Warning style={{ width: 20, height: 20 }}/>}
                    Save
                </>
            ),
            onClick: handleSave,
            variant: "contained",
            color: "primary",
            disabled: disableSave || isSaving
        });

        return buttons;
    };

    // Helper function to create a Date from a date string that preserves the date
    const createDateFromString = (dateString: string | null) => {
        if (!dateString) return null;
        const [year, month, day] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day);  // month is 0-based in Date constructor
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="holiday-event-modal">
            <div className="ut-form-header">
                <h4>{`${formType === DemandInfluenceFormOptions.EDIT ? "Edit" : "New"} ${selectedHoliday.category} for ${selectedHoliday.name}`}</h4>
            </div>
            <div className="holiday-form-body table-body">
                {isLoading || isFetchingHolidays || isFetchingCohorts || isFetchingLocationOptions || isFetchingDefaultCDs
                    ? <Loading className="table-modal-loading"/>
                    : <div>
                        <div className="form-section">
                            <h5 className="form-section-title">Basic Information</h5>
                            <div className="table-form-row">
                                <div className="col" style={{width: "450px"}}>
                                    <div className="table-form-group">
                                        <TextField
                                            fullWidth
                                            label="Name"
                                            variant="outlined"
                                            size="small"
                                            value={eventTitle}
                                            onChange={(e) => setEventTitle(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col" style={{width: "80px"}}>
                                    <div className="table-form-group checkbox-group">
                                        <Checkbox
                                            checked={active}
                                            onChange={() => setActive(!active)}
                                        />
                                        <span>Active</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-section">
                            <h5 className="form-section-title">Event Details</h5>
                            <div className="table-form-row">
                                <div className="col" style={{width: "350px"}}>
                                    <div className="table-form-group">
                                        <FormControl fullWidth className="form-control-select">
                                            <InputLabel>Classification</InputLabel>
                                            <Select
                                                value={classification || ''}
                                                onChange={(e) => setClassification(e.target.value as string)}
                                            >
                                                {classificationOptions.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.display}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="col" style={{width: "350px"}}>
                                    <div className="table-form-group">
                                        <FormControl fullWidth className="form-control-select">
                                            <InputLabel>Type</InputLabel>
                                            <Select
                                                value={eventType || ''}
                                                onChange={(e) => setEventType(e.target.value as string)}
                                            >
                                                {eventTypeOptions.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.display}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="col" style={{width: "150px"}}>
                                    <div className="table-form-group">
                                        <Checkbox
                                            checked={top50}
                                            onChange={() => setTop50(!top50)}
                                        />
                                        <label>Top 50</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-form-row">
                            <div className="col" style={{width: "230px", paddingLeft: "25px"}}>
                                <div className="table-form-group">
                                    <TextField
                                        fullWidth
                                        label="Min Stay"
                                        variant="outlined"
                                        type="number"
                                        inputProps={{ step: 1 }}
                                        value={minStay}
                                        onChange={(e) => setMinStay(parseInt(e.target.value))}
                                    />
                                </div>
                            </div>
                            <div className="col" style={{width: "200px"}}>
                                <div className="table-form-group">
                                    <TextField
                                        fullWidth
                                        label="Priority"
                                        variant="outlined"
                                        type="number"
                                        inputProps={{ step: 1 }}
                                        value={priority}
                                        onChange={(e) => setPriority(parseInt(e.target.value))}
                                    />
                                </div>
                            </div>
                            <div className="col" style={{width: "350px"}}>
                                <div className="table-form-group">
                                    <FormControl fullWidth className="form-control-select">
                                        <InputLabel>Category</InputLabel>
                                        <Select
                                            value={levelCategory || ''}
                                            onChange={(e) => setLevelCategory(e.target.value as string)}
                                        >
                                            {UiUtils.getCategoryOptions().map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.display}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="geography-section">
                            <div className="geography-header" onClick={() => setShowGeography(!showGeography)}>
                                <div className="geography-title">
                                    <Icon.Place style={{ width: 20, height: 20 }} />
                                    <h5>Geography</h5>
                                </div>
                                <div className="geography-summary">
                                    {geographySummary || "Global Event"}
                                    {showGeography
                                        ? <Icon.KeyboardArrowUp style={{ width: 20, height: 20 }} className="toggle-icon" />
                                        : <Icon.KeyboardArrowDown style={{ width: 20, height: 20 }} className="toggle-icon" />
                                    }
                                </div>
                            </div>
                            <div className={`geography-content ${showGeography ? 'visible' : ''}`}>
                                <div className="table-form-row">
                                    <div className="col" style={{width: "680px", paddingLeft: "18px"}}>
                                        <div className="table-form-group">
                                            <TextField
                                                fullWidth
                                                label="Address"
                                                variant="outlined"
                                                placeholder="(Optional)"
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col" style={{width: "180px", padding: "8px"}}>
                                        <Button
                                            onClick={() => lookupLatLng()}
                                            variant="contained"
                                            color="primary"
                                            disabled={!address || isFetchingLatLng}
                                            style={{ height: '40px' }}
                                        >
                                            {isFetchingLatLng
                                                ? <CircularProgress size={24} />
                                                : <Icon.Place style={{ width: 24, height: 24 }}/>
                                            }
                                            Set Lat/Lng
                                        </Button>
                                    </div>
                                </div>

                                <div className="table-form-row">
                                    <div className="col" style={{width: "350px"}}>
                                        <div className="table-form-group">
                                            <TextField
                                                fullWidth
                                                label="Latitude"
                                                variant="outlined"
                                                placeholder="(Optional)"
                                                type="number"
                                                inputProps={{ step: 0.000001 }}
                                                value={lat || ''}
                                                onChange={(e) => setLat(parseFloat(e.target.value))}
                                            />
                                        </div>
                                    </div>
                                    <div className="col" style={{width: "350px"}}>
                                        <div className="table-form-group">
                                            <TextField
                                                fullWidth
                                                label="Longitude"
                                                variant="outlined"
                                                placeholder="(Optional)"
                                                type="number"
                                                inputProps={{ step: 0.000001 }}
                                                value={lng || ''}
                                                onChange={(e) => setLng(parseFloat(e.target.value))}
                                            />
                                        </div>
                                    </div>
                                    <div className="col" style={{
                                                        width: "200px",
                                                        padding: "8px",
                                                        display: "flex",
                                                        justifyContent: "space-between"
                                                    }}>
                                        <Button
                                            onClick={setLatLngFromClipboard}
                                            variant="outlined"
                                            style={{ height: '40px' }}
                                        >
                                            <Icon.Assignment style={{ width: 24, height: 24 }}/>
                                            {isPasted ? "Pasted" : "Paste"}
                                        </Button>
                                        <Button
                                            onClick={copyLatLngFromClipboard}
                                            variant="contained"
                                            color="primary"
                                            style={{ height: '40px' }}
                                        >
                                            <Icon.FileCopy style={{ width: 24, height: 24 }}/>
                                            {isCopied ? "Copied" : "Copy"}
                                        </Button>
                                    </div>
                                </div>
                                {lat && lng &&
                                    <div className="table-form-row">
                                        {/*THIS GMAPS API KEY WAS BORROWED FROM FIELD OPS*/}
                                        <iframe
                                            src={`https://www.google.com/maps/embed/v1/place?q=${lat},${lng}&key=AIzaSyBvJitnNTAzxkmDeGNN9GxFJ_nSLfXkoNs`}
                                            height="300"
                                            width="700"
                                        >
                                        </iframe>
                                    </div>
                                }
                                <div className="table-form-row">
                                    <div className="col" style={{width: "450px"}}>
                                        <div className="table-form-group">
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="geography-type-label">Geography Type</InputLabel>
                                                <Select
                                                    labelId="geography-type-label"
                                                    label="Geography Type"
                                                    value={selectedGeographyType}
                                                    onChange={(e) => {
                                                        const newType = e.target.value as string;
                                                        clearGeographyValues(newType);
                                                        setSelectedGeographyType(newType);
                                                    }}
                                                >
                                                    <MenuItem value="">Select a geography type</MenuItem>
                                                    <MenuItem value="super_regions">Super Regions</MenuItem>
                                                    <MenuItem value="states">States</MenuItem>
                                                    <MenuItem value="cohorts">Cohorts</MenuItem>
                                                    <MenuItem value="ops_markets">Ops Markets</MenuItem>
                                                    <MenuItem value="unitcode_prefixes">Unit Code Prefixes</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>

                                {selectedGeographyType === 'super_regions' && (
                                    <GeographySelector
                                        label="Super Regions"
                                        options={locationOptions.super_regions}
                                        value={superRegions}
                                        onChange={setSuperRegions}
                                    />
                                )}

                                {selectedGeographyType === 'regions' && (
                                    <GeographySelector
                                        label="Regions"
                                        options={locationOptions.regions}
                                        value={regions}
                                        onChange={setRegions}
                                    />
                                )}

                                {selectedGeographyType === 'states' && (
                                    <GeographySelector
                                        label="States"
                                        options={locationOptions.states}
                                        value={states}
                                        onChange={setStates}
                                    />
                                )}

                                {selectedGeographyType === 'cohorts' && (
                                    <GeographySelector
                                        label="Cohorts"
                                        options={allCohorts.filter(c => c.active).map(c => c.name)}
                                        value={cohorts}
                                        onChange={setCohorts}
                                    />
                                )}

                                {selectedGeographyType === 'ops_markets' && (
                                    <GeographySelector
                                        label="Ops Markets"
                                        options={locationOptions.ops_markets}
                                        value={opsMarkets}
                                        onChange={setOpsMarkets}
                                    />
                                )}

                                {selectedGeographyType === 'unitcode_prefixes' && (
                                    <GeographySelector
                                        label="Unit Code Prefixes"
                                        options={locationOptions.unitcode_prefixes}
                                        value={unitCodePrefixes}
                                        onChange={setUnitCodePrefixes}
                                    />
                                )}
                            </div>
                        </div>
                        <hr/>
                        <div className="table-form-row">
                            <div className="table-form-group">
                                <p>Start Date:</p>
                                <KeyboardDatePicker
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="yyyy-MM-dd"
                                    maxDate={addDays(today, 720)}
                                    value={createDateFromString(startDate)}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{'aria-label': 'change start date'}}
                                />
                            </div>
                            <div className="table-form-group">
                                <p>End Date:</p>
                                <KeyboardDatePicker
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="yyyy-MM-dd"
                                    minDate={startDate ? createDateFromString(startDate) : null}
                                    maxDate={startDate ? addDays(createDateFromString(startDate), maxRange) : null}
                                    value={createDateFromString(endDate)}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{'aria-label': 'change end date'}}
                                />
                            </div>
                            <div className="table-form-group">
                                <p>Day Count:</p>
                                <TextField
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    className="number-input"
                                    inputProps={{
                                        min: 0,
                                        max: maxRange,
                                        step: 1
                                    }}
                                    value={numberOfDays}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = parseInt(e.target.value || '0');
                                        if (!isNaN(value) && value >= 0 && value <= maxRange) {
                                            setNumberOfDays(value);
                                        }
                                    }}
                                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                        const value = parseInt(e.target.value || '0');
                                        if (isNaN(value) || value < 0) {
                                            setNumberOfDays(0);
                                        } else if (value > maxRange) {
                                            setNumberOfDays(maxRange);
                                        }
                                    }}
                                />
                            </div>
                            <Button
                                style={{ marginTop: '34px' }}
                                onClick={() => setComparisonDatesFromDates(new Date(startDate), new Date(endDate))}
                                variant="contained"
                                color="primary"
                            >
                                {comparisonDates.length > 0 ? "Reset" : "Add"} Comparison Dates
                            </Button>
                        </div>
                        {comparisonDates.length > 0 &&
                            <table className={"date-table"}>
                                <thead>
                                <tr>
                                    <th colSpan={2} className={"th-underline"}>Selected</th>
                                    {showDefaultCD &&
                                        <th colSpan={2} className={"th-underline"}>
                                            <Icon.Close width={16} height={16} onClick={() => setShowDefaultCD(false)}/>
                                            Default
                                        </th>
                                    }
                                    <th colSpan={3} className={"th-underline"}>Comparison</th>
                                    <th className={"th-underline"}>Actions</th>
                                </tr>
                                <tr>
                                    <th className={"date-col th-underline cd-border-left"}>DOW</th>
                                    <th className={"date-col th-underline cd-border-right"}>Date</th>
                                    {showDefaultCD && <>
                                        <th className={"date-col th-underline"}>DOW</th>
                                        <th className={"date-col th-underline cd-border-right"}>Date</th>
                                    </>}
                                    <th className={"date-col th-underline"}>DOW</th>
                                    <th className={"date-input-col th-underline"}>Comparison Date</th>
                                    <th className={"cb-col th-border-right th-underline cd-border-right"}>
                                        <Tooltip
                                            title="Use this checkbox to indicate which dates are holidays or events; We map holidays to last year holidays, and non-holidays to last year non-holidays"
                                            placement="top"
                                        >
                                            <div>
                                                Holiday
                                                <Icon.Info style={{ width: 16, height: 16 }}/>
                                            </div>
                                        </Tooltip>
                                    </th>
                                    <th className={"date-actions-col th-underline cd-border-right"}></th>
                                </tr>
                                </thead>
                                <tbody>
                                {comparisonDates.map((cd, idx) => {
                                    return (
                                        <tr>
                                            <td className={"cd-border-left"}>{
                                                getDateTooltip(
                                                    UiUtils.days[new Date(cd.date + "T00:00:00.000-10:00").getDay()],
                                                    cd.date_holiday,
                                                    false,
                                                )
                                            }</td>
                                            <td className={"cd-border-right"}>{
                                                getDateTooltip(cd.date, cd.date_holiday, true)
                                            }</td>
                                            {showDefaultCD && <>
                                                <td>{
                                                    getDateTooltip(
                                                        UiUtils.days[new Date(cd.default_comparison_date + "T00:00:00.000-10:00").getDay()],
                                                        cd.default_comparison_date_holiday,
                                                        false,
                                                    )
                                                }</td>
                                                <td className={"cd-border-right"}>
                                                    {
                                                        getDateTooltip(
                                                            cd.default_comparison_date,
                                                            cd.default_comparison_date_holiday,
                                                            false,
                                                        )
                                                    }
                                                    <span style={{
                                                        float: "right",
                                                        cursor: "pointer",
                                                    }} onClick={() => updateCD(idx, cd.default_comparison_date, cd.is_holiday)}>
                                                        <Tooltip
                                                            title="Set comparison date as default CD"
                                                            placement="top"
                                                        >
                                                            <div>
                                                                <Icon.CallMade style={{ width: 16, height: 16 }}/>
                                                            </div>
                                                        </Tooltip>
                                                    </span>
                                                </td>
                                            </>}
                                            <td>{UiUtils.days[new Date(cd.comparison_date + "T00:00:00.000-10:00").getDay()]}</td>
                                            <td>
                                                <div>
                                                    <span style={{float: "left", paddingTop: "6px", width: "24px"}}>
                                                        {cd.comparison_date &&
                                                            <Icon.Close
                                                                style={{cursor: "pointer"}}
                                                                height={16}
                                                                width={16}
                                                                onClick={() => updateCD(idx, null, cd.is_holiday)}
                                                            />
                                                        }
                                                    </span>
                                                    <KeyboardDatePicker
                                                        variant="inline"
                                                        inputVariant="outlined"
                                                        format="yyyy-MM-dd"
                                                        value={createDateFromString(cd.comparison_date)}
                                                        onChange={(date: Date | null, value?: string | null) => updateCD(idx, value, cd.is_holiday)}
                                                        KeyboardButtonProps={{'aria-label': 'change comparison date'}}
                                                        size="small"
                                                        style={{ width: '200px' }}
                                                    />
                                                </div>
                                            </td>
                                            <td className={"cd-border-right"}>
                                                <Checkbox
                                                    checked={cd.is_holiday}
                                                    onChange={() => updateCD(idx, cd.comparison_date, !cd.is_holiday)}
                                                />
                                            </td>
                                            <td className={"cd-border-right"}>
                                                <Icon.Delete className={"table-action-icon"} style={{ width: 24, height: 24 }}
                                                            onClick={() => removeDate(idx)}/>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                                <tr>
                                    <td className={"cd-border-top"}></td>
                                    <td className={"cd-border-top"}></td>
                                    {showDefaultCD && <>
                                        <td className={"cd-border-top"}></td>
                                        <td className={"cd-border-top"}></td>
                                    </>}
                                    <td className={"cd-border-top"}></td>
                                    <td className={"cd-border-top"}></td>
                                    <td className={"cd-border-top"}></td>
                                    <td className={"cd-border-top"}></td>
                                </tr>
                                </tbody>
                            </table>
                        }
                    </div>
                }
            </div>
            <div className="ut-form-footer" style={{height: "30px"}}>
                <span>
                    {validationErrorMsg}
                </span>
                <div className="form-footer-buttons">
                    <ButtonGroup>
                        {getFooterButtons().map((button, index) => (
                            <Button
                                key={index}
                                onClick={button.onClick}
                                variant={button.variant}
                                color={button.color}
                                disabled={button.disabled}
                            >
                                {button.children}
                            </Button>
                        ))}
                    </ButtonGroup>
                </div>
            </div>
        </div>
        </MuiPickersUtilsProvider>
    );
};
