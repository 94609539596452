import { timer, Subject } from 'rxjs';
import {StorageKeys} from "../store";
import {Configuration} from "../Configuration";

export interface TokenStatus {
    isExpiring: boolean;
    timeRemaining: number;
}

export class AuthService {
    private tokenExpiryBuffer: number = (Configuration.isLocal ? 2 : 6) * 60 * 1000; // 2 or 6 minute warning
    private tokenStatus = new Subject<TokenStatus>();

    // Observable that components can subscribe to
    public tokenStatus$ = this.tokenStatus.asObservable();

    setupTokenRefresh() {
        // Check token expiration every minute
        timer(0, 60 * 1000).subscribe(() => {
            const token = this.getToken();
            if (!token) {
                // If no token, let ImplicitFlow handle the redirect
                this.tokenStatus.next({
                    isExpiring: true,
                    timeRemaining: 0
                });
                return;
            }

            const timeRemaining = this.getTimeRemaining(token);
            if (timeRemaining < this.tokenExpiryBuffer) {
                // Only emit warning if token exists but is expiring
                this.tokenStatus.next({
                    isExpiring: true,
                    timeRemaining: timeRemaining
                });
            } else {
                this.tokenStatus.next({
                    isExpiring: false,
                    timeRemaining: timeRemaining
                });
            }
        });
    }

    private getToken(): string | null {
        const token = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
        return token || null;
    }

    private getTokenExpiry(token: string): number {
        try {
            // Parse the JWT token payload
            const payload = JSON.parse(atob(token.split('.')[1]));
            if (payload.exp) {
                return payload.exp * 1000; // Convert to milliseconds
            }
            return 0;
        } catch (e) {
            console.error('Failed to parse token expiry:', e);
            return 0;
        }
    }

    private getTimeRemaining(token: string): number {
        const expiry = this.getTokenExpiry(token);
        const remaining = expiry - Date.now();
        console.log('Seconds remaining:', Math.floor(remaining / 1000));
        return remaining;
    }
}

export const authService = new AuthService();