import React, {useEffect, useState} from 'react';
import './UnitAssignmentModal.scss';
import {CurrentUser, SelectOption, StrategicCohort, Unit} from "../../types";
import {useAddCohortAssignmentMutation, usePostUnitQualityScoreMutation, useGetSuggestedCohortsQuery, useRemoveCohortAssignmentMutation, useRemoveQualityScoreMutation} from "../../store";
import {Button, CircularProgress, MenuItem, Select, TextField, Tooltip} from '@material-ui/core';
import * as Icon from '@material-ui/icons';

interface UnitAssignmentModalProps {
    selectedUnit: Unit;
    cohortOptionsSameState: SelectOption[];
    closeModal: () => void;
    changeSelectedUnit: (id: number) => void;
    currentUser: CurrentUser;
    refetch: () => void;
    isFetchingUnits: boolean;
}

export const UnitAssignmentModal: React.FC<UnitAssignmentModalProps> = (props) => {
    const {selectedUnit, cohortOptionsSameState, closeModal, changeSelectedUnit, currentUser, refetch, isFetchingUnits} = props
    const {data: suggestedCohorts, isFetching} = useGetSuggestedCohortsQuery({id: selectedUnit.id, super_unit: selectedUnit.super_unit});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [disableAssignCohort, setDisableAssignCohort] = useState<boolean>(true);
    const [disableUpdateScore, setDisableUpdateScore] = useState<boolean>(true);
    const [assignUnitToStrategicCohort] = useAddCohortAssignmentMutation();
    const [setUnitQualityScore] = usePostUnitQualityScoreMutation();
    const [unassignUnitFromStrategicCohort] = useRemoveCohortAssignmentMutation();
    const [removeQualityScore] = useRemoveQualityScoreMutation()
    const [selectedCohortID, setSelectedCohortID] = useState<number>(null);
    const [qualityScore, setQualityScore] = useState<string>(
        (selectedUnit.unit_quality_override ? selectedUnit.unit_quality_override : selectedUnit.unit_quality).toString()
    );
    const [cohortOptions, setCohortOptions] = useState<SelectOption[]>();
    const [errorMsg, setErrorMsg] = useState<string>("No cohorts suggested");

    const assignUnit = async () => {
        setIsLoading(true);
        await assignUnitToStrategicCohort({
            unit_id: selectedUnit.id,
            strategic_cohort_id: selectedCohortID,
            is_super_unit: selectedUnit.super_unit
        }).then(() => {
            refetch();
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const unassignUnit = async () => {
        setIsLoading(true);
        await unassignUnitFromStrategicCohort({id: selectedUnit.id, super_unit: selectedUnit.super_unit}).then(() => {
            refetch();
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const updateQualityScore = async () => {
        setIsLoading(true);
        const score = parseFloat(qualityScore)
        await setUnitQualityScore({
            unit_id: selectedUnit.id,
            unit_quality: score
        }).then(() => {
            refetch();
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const unsetQualityScore = async () => {
        setIsLoading(true);
        await removeQualityScore(selectedUnit.id).then(() => {
            refetch();
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        // Only check these conditions if it's not a super unit
        if (!selectedUnit.super_unit) {
            const disable = (
                selectedUnit.lat === "" ||
                selectedUnit.lng === "" ||
                selectedUnit.lat === 0 ||
                selectedUnit.lng === 0 ||
                selectedCohortID === null ||
                (
                    selectedUnit.pending_strategic_cohort_id ?
                        selectedUnit.pending_strategic_cohort_id === selectedCohortID
                        :
                        selectedUnit.strategic_cohort_id === selectedCohortID
                )
            )
            setDisableAssignCohort(disable)
        }
    }, [selectedCohortID, selectedUnit.strategic_cohort_id, selectedUnit.super_unit])

    useEffect(() => {
        const score = parseFloat(qualityScore);
        const disable = (
            !qualityScore ||
            !score ||
            qualityScore !== score.toString() || // Vacasa react-components-lib Input does not handle floats
            (
                !!selectedUnit.unit_quality_override // requires a change
                ?
                selectedUnit.unit_quality_override === score
                :
                selectedUnit.unit_quality === score
            )
        );
        setDisableUpdateScore(disable)
    }, [qualityScore, selectedUnit.unit_quality_override])

    useEffect(() => {
        if (!isFetching && !!suggestedCohorts) {
            let options: SelectOption[] = []

            if (selectedUnit.super_unit) {
                const subUnitCohorts = new Set<number>();
                selectedUnit.sub_unit_data?.forEach(sub => {
                    if (sub.strategic_cohort_id) {
                        subUnitCohorts.add(sub.strategic_cohort_id);
                    }
                    if (sub.pending_strategic_cohort_id) {
                        subUnitCohorts.add(sub.pending_strategic_cohort_id);
                    }
                });

                cohortOptionsSameState.forEach(opt => {
                    if (subUnitCohorts.has(opt.value as number)) {
                        options.push(opt);
                    }
                });
            } else {
                // Order matters -- Add pending assignment first since that indicates the most recent transaction
                if (selectedUnit.pending_strategic_cohort_id) {
                    options.push({value: selectedUnit.pending_strategic_cohort_id, display: selectedUnit.pending_strategic_cohort})
                }
                // Next, add assigned cohort
                if (selectedUnit.strategic_cohort_id) {
                    options.push({value: selectedUnit.strategic_cohort_id, display: selectedUnit.strategic_cohort})
                }
                // Next add suggested
                suggestedCohorts.forEach((sc: StrategicCohort) => {
                    const idx = options.map(o => o.value).indexOf(sc.strategic_cohort_id)
                    if (idx < 0) {
                        options.push({
                            value: sc.strategic_cohort_id,
                            display: sc.strategic_cohort
                        });
                    }
                })
                // Finally, add all cohorts from the same state
                options = options.concat(cohortOptionsSameState);
            }

            // Only set option if there are options available
            if (options.length > 0) {
                setSelectedCohortID(options[0].value as number);
            }

            setCohortOptions(options);

            if (selectedUnit.super_unit) {
                let shouldDisable = false;
                if (selectedUnit.sub_unit_data.length === 0) {
                    setErrorMsg("This super unit does not have any sub-units");
                    shouldDisable = true;
                } else {
                    const allValid = selectedUnit.sub_unit_data.every(u => 
                        !!u.strategic_cohort_id || !!u.pending_strategic_cohort_id
                    );
                    if (!allValid) {
                        setErrorMsg("All sub units need to be assigned before assigning a super unit");
                        shouldDisable = true;
                    }
                }
                setDisableAssignCohort(shouldDisable);
            }

            setIsLoading(false);
        }
    }, [suggestedCohorts, isFetching, selectedUnit])

    return (
        <div>
            <div className="modal-header">
                <h4>Unit Properties</h4>
            </div>
            <div className="modal-body ua-properties" style={{ minHeight: '400px' }}>
                {isLoading || isFetchingUnits
                    ? <CircularProgress />
                    : <div>
                        <div className="ua-input-group">
                            <label htmlFor="suggested-sc">Selected Cohort:</label>
                            <Select
                                id="suggested-sc"
                                className="number-input"
                                value={selectedCohortID}
                                variant="outlined"
                                onChange={(e) => {
                                    setSelectedCohortID(e.target.value as number)
                                }}
                            >
                                {cohortOptions?.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.display}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Button
                                onClick={assignUnit}
                                variant="contained"
                                color="primary"
                                disabled={isLoading || isFetchingUnits || disableAssignCohort}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={unassignUnit}
                                variant="contained"
                                color="secondary"
                                disabled={isLoading || isFetchingUnits || !selectedUnit.strategic_cohort_id || true}
                            >
                                Remove
                            </Button>
                        </div>

                        <div className="ua-input-group">
                            <label htmlFor="suggested-sc">Quality Score:</label>
                            <TextField
                                className="number-input"
                                type="text"
                                variant="outlined"
                                disabled={selectedUnit.super_unit}
                                value={qualityScore.toString()}
                                onChange={(e) => {
                                    setQualityScore(e.target.value)
                                }}
                            />
                            <Button
                                onClick={updateQualityScore}
                                variant="contained"
                                color="primary"
                                disabled={isLoading || isFetchingUnits || disableUpdateScore || selectedUnit.super_unit}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={unsetQualityScore}
                                variant="contained"
                                color="secondary"
                                disabled={isLoading || isFetchingUnits || !selectedUnit.unit_quality_override || selectedUnit.super_unit}
                            >
                                Remove
                            </Button>
                        </div>

                        <div>
                            {!isFetching && (
                                selectedUnit.lat === "" ||
                                selectedUnit.lng === "" ||
                                selectedUnit.lat === 0 ||
                                selectedUnit.lng === 0
                            ) ?
                                <div className="missing-data-warning">
                                    <Icon.Warning />
                                    <p>Unit does not have valid Lat/Lng and cannot be assigned</p>
                                </div>
                                : !isFetching && suggestedCohorts.length === 0
                                ?
                                <div className="missing-data-warning">
                                    <Icon.Warning />
                                    <p>{errorMsg}</p>
                                </div>
                                :
                                <></>
                            }
                        </div>
                        <div className="unit-info">
                            <div className="row">
                                <tr>
                                    <td className="unit-key">ID:</td>
                                    <td className="unit-value">{selectedUnit.id}</td>
                                </tr>
                                <tr>
                                    <td className="unit-key">Code:</td>
                                    <td className="unit-value">{selectedUnit.unit_code}</td>
                                </tr>
                                <tr>
                                    <td className="unit-key">Unit Quality:</td>
                                    <td className="unit-value">{selectedUnit.unit_quality}</td>
                                </tr>
                                {!!selectedUnit.unit_quality_override && (
                                    <div>
                                        <tr>
                                            <td className="unit-key">Quality Override:</td>
                                            <td className="unit-value">{selectedUnit.unit_quality_override}</td>
                                        </tr>
                                        <tr>
                                            <td className="unit-key">Override Assigner:</td>
                                            <td className="unit-value">{selectedUnit.unit_quality_override_assigner}</td>
                                        </tr>
                                    </div>
                                )}
                                <tr>
                                    <td className="unit-key">State:</td>
                                    <td className="unit-value">{selectedUnit.state}</td>
                                </tr>
                                <tr>
                                    <td className="unit-key">Region:</td>
                                    <td className="unit-value">{selectedUnit.region}</td>
                                </tr>
                                <tr>
                                    <td className="unit-key">City:</td>
                                    <td className="unit-value">{selectedUnit.city_name}</td>
                                </tr>
                                <div className={selectedUnit.matches_oz ? 'cohort-match' : 'cohort-mismatch'}>
                                    <tr>
                                        <td className="unit-key">Strategic Cohort:</td>
                                        <td className="unit-value">
                                            <b>{
                                                !!selectedUnit.pending_strategic_cohort_id
                                                    ? selectedUnit.pending_strategic_cohort + " (pending)"
                                                    : !!selectedUnit.strategic_cohort_id ? selectedUnit.strategic_cohort : "UNASSIGNED"
                                            }</b>
                                        </td>
                                    </tr>
                                    {!!selectedUnit.pending_strategic_cohort &&
                                        <tr>
                                            <td className="unit-key">Pending Cohort:</td>
                                            <td className="unit-value">
                                                <b>{selectedUnit.pending_strategic_cohort}</b></td>
                                        </tr>
                                    }
                                    {selectedUnit.super_unit ? (
                                        selectedUnit.sub_unit_data?.length > 0
                                        ?
                                        selectedUnit.sub_unit_data.map(sub => (
                                            <tr>
                                                <td className="unit-key">SubUnit ({sub.id}):</td>
                                                <td className="unit-value">{
                                                    !!sub.pending_strategic_cohort
                                                        ? sub.pending_strategic_cohort + " (pending)"
                                                        : !!sub.strategic_cohort ? sub.strategic_cohort : "UNASSIGNED"
                                                }</td>
                                                <td className="select-unit-button">
                                                    {!!sub.strategic_cohort_id
                                                        ?
                                                        <Button
                                                            onClick={() => setSelectedCohortID(sub.strategic_cohort_id)}
                                                            disabled={selectedCohortID === sub.strategic_cohort_id}
                                                            variant="text"
                                                            size="small"
                                                        >
                                                            Set Selected
                                                        </Button>
                                                        :
                                                        !!sub.pending_strategic_cohort_id ?
                                                        <Button
                                                            onClick={() => setSelectedCohortID(sub.pending_strategic_cohort_id)}
                                                            disabled={selectedCohortID === sub.pending_strategic_cohort_id}
                                                            variant="text"
                                                            size="small"
                                                        >
                                                            Set Selected
                                                        </Button>
                                                        :
                                                        <Tooltip
                                                            title="This sub-unit needs to be assigned before the super-unit can be assigned"
                                                        >
                                                            <div className="ua-input-group">
                                                                <Icon.Warning />
                                                                <Button
                                                                    className="as-link link-button"
                                                                    onClick={() => {
                                                                        closeModal()
                                                                        changeSelectedUnit(sub.id)
                                                                    }}
                                                                    variant="text"
                                                                    size="small"
                                                                >
                                                                    Jump to sub-unit assigner
                                                                </Button>
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                            :
                                            <tr>
                                                <td className="unit-key">Sub Units</td>
                                                <td className="unit-value"><strong>NO SUB UNITS FOUND</strong></td>
                                                <td></td>
                                        </tr>
                                    ) :
                                        suggestedCohorts.map((suggested: StrategicCohort, i) => (
                                            <tr>
                                                <td className="unit-key">Suggested Cohort:</td>
                                                <td className="unit-value">{suggested.strategic_cohort}</td>
                                                <td className="select-unit-button">
                                                    <Button
                                                        onClick={() => setSelectedCohortID(suggested.strategic_cohort_id)}
                                                        disabled={selectedCohortID === suggested.strategic_cohort_id}
                                                        variant="text"
                                                        size="small"
                                                    >
                                                        Set Selected
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    <tr>
                                        <td className="unit-key">Oz Cohort:</td>
                                        <td className="unit-value">{selectedUnit.oz_cohort_name}</td>
                                    </tr>
                                </div>
                                <tr>
                                    <td className="unit-key">Beds:</td>
                                    <td className="unit-value">{selectedUnit.bedrooms}</td>
                                </tr>
                                <tr>
                                    <td className="unit-key">Super Unit:</td>
                                    <td className="unit-value">{selectedUnit.super_unit}</td>
                                </tr>
                                <tr>
                                    <td className="unit-key">ESP:</td>
                                    <td className="unit-value">{selectedUnit.esp}</td>
                                </tr>
                                <tr>
                                    <td className="unit-key">Ski:</td>
                                    <td className="unit-value">{selectedUnit.ski}</td>
                                </tr>
                                <tr>
                                    <td className="unit-key">Water:</td>
                                    <td className="unit-value">{selectedUnit.water}</td>
                                </tr>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};
