import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Icon } from '@vacasa/react-components-lib';
import React, { useEffect, useMemo, useState } from 'react';

import {
  useAddRatePlanOptionMutation,
  useUpdateRatePlanOptionMutation,
} from '../../store';
import { RatePlanOption } from '../../types/RatePlanOption';
import { UiUtils } from '../../utils';

type SortDirection = 'asc' | 'desc';

interface RatePlanOptionsProps {
  searchValue: string;
  setSearchValue: (o: string) => void;
  ratePlanOptions: RatePlanOption[];
  refetchRatePlanOptions: () => void;
  isFetchingRatePlanOptions: boolean;
}

interface EditingState extends Omit<RatePlanOption, 'id'> {}

const RatePlanOptions: React.FC<RatePlanOptionsProps> = (props) => {
  const {
    searchValue,
    setSearchValue,
    ratePlanOptions,
    refetchRatePlanOptions,
    isFetchingRatePlanOptions,
  } = props;

  const [sortBy, setSortBy] = useState<keyof RatePlanOption>('id');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [options, setOptions] = useState<RatePlanOption[]>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editingOptions, setEditingOptions] = useState<{
    [key: number]: boolean;
  }>({});
  const [editingStates, setEditingStates] = useState<{
    [key: number]: EditingState;
  }>({});
  const [savingRows, setSavingRows] = useState<{ [key: number]: boolean }>({});
  const [isRefetching, setIsRefetching] = useState(false);

  const [addRatePlanOption] = useAddRatePlanOptionMutation();
  const [updateRatePlanOption] = useUpdateRatePlanOptionMutation();

  const handleSort = (column: keyof RatePlanOption) => {
    if (sortBy === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortDirection('asc');
    }
  };

  const filteredAndSortedData = useMemo(() => {
    return options
      ?.filter(
        (option) =>
          option.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          option.id.toString().includes(searchValue)
      )
      .sort((a, b) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];

        if (sortDirection === 'asc') {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else {
          return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
        }
      });
  }, [options, searchValue, sortBy, sortDirection]);

  useEffect(() => {
    if (!isFetchingRatePlanOptions && !!ratePlanOptions) {
      setOptions(ratePlanOptions);
    }
  }, [ratePlanOptions, isFetchingRatePlanOptions]);

  const handleRefresh = async () => {
    setIsRefetching(true);
    refetchRatePlanOptions();
    // Users like to see buttons do something
    setTimeout(() => {
      setIsRefetching(false);
    }, 750);

    console.log('done refreshing');
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = useMemo(() => {
    if (!filteredAndSortedData) return [];
    const startIndex = page * rowsPerPage;
    return filteredAndSortedData.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredAndSortedData, page, rowsPerPage]);

  const handleEdit = (option: RatePlanOption) => {
    setEditingOptions((prev) => ({
      ...prev,
      [option.id]: prev.hasOwnProperty(option.id) ? !prev[option.id] : true,
    }));

    // Initialize editing state with current values
    setEditingStates((prev) => ({
      ...prev,
      [option.id]: {
        name: option.name,
        active: option.active,
        non_refundable: option.non_refundable,
        days_from_checkin: option.days_from_checkin,
        discount_percentage: option.discount_percentage,
        vacasa: option.vacasa,
        vrbo: option.vrbo,
        airbnb: option.airbnb,
        bdc: option.bdc,
        expedia: option.expedia,
        mybookingpal: option.mybookingpal,
      },
    }));
  };

  const handleSave = async (option: RatePlanOption) => {
    try {
      setSavingRows((prev) => ({
        ...prev,
        [option.id]: true,
      }));

      const editingState = editingStates[option.id];
      const updatedOption = {
        ...option,
        ...editingState,
      };
      await updateRatePlanOption({
        id: option.id,
        data: updatedOption,
      });

      // Refetch rate plan options without waiting
      refetchRatePlanOptions();

      // Clear editing states immediately after save
      setEditingOptions((prev) => ({
        ...prev,
        [option.id]: false,
      }));
      setEditingStates((prev) => {
        const newState = { ...prev };
        delete newState[option.id];
        return newState;
      });
    } catch (error) {
      console.error('Failed to update rate plan option:', error);
    } finally {
      setSavingRows((prev) => ({
        ...prev,
        [option.id]: false,
      }));
      setIsRefetching(false);
    }
  };

  const handleCancel = (optionId: number) => {
    setEditingOptions((prev) => ({
      ...prev,
      [optionId]: false,
    }));
    // Clear editing state
    setEditingStates((prev) => {
      const newState = { ...prev };
      delete newState[optionId];
      return newState;
    });
  };

  type Togglable = keyof Omit<
    RatePlanOption,
    'id' | 'name' | 'days_from_checkin' | 'discount_percentage'
  >;
  const handleToggle = (
    option: RatePlanOption,
    field: Togglable,
    e?: React.MouseEvent | React.ChangeEvent
  ) => {
    setEditingStates((prev) => {
      const currentState = prev[option.id] || {
        name: option.name,
        active: option.active,
        non_refundable: option.non_refundable,
        days_from_checkin: option.days_from_checkin,
        discount_percentage: option.discount_percentage,
        vacasa: option.vacasa,
        vrbo: option.vrbo,
        airbnb: option.airbnb,
        bdc: option.bdc,
        mybookingpal: option.mybookingpal,
        expedia: option.expedia,
      };

      return {
        ...prev,
        [option.id]: {
          ...currentState,
          [field]: !currentState[field],
        },
      };
    });
  };

  const handleAddOption = async () => {
    let newOption: Omit<RatePlanOption, 'id'> = {
      name: 'New Rate Plan Option',
      active: true,
      non_refundable: false,
      days_from_checkin: 0,
      discount_percentage: 0,
    };
    try {
      setIsRefetching(true);
      const response = await addRatePlanOption({
        ...newOption,
      }).unwrap();

      refetchRatePlanOptions();

      // Scroll to the top of the table where the new row will appear
      const tableContainer = document.querySelector('.MuiTableContainer-root');
      if (tableContainer) {
        tableContainer.scrollTop = 0;
      }

      // Change sort/filter to show new row at the top
      setSortBy('id');
      setSortDirection('desc');
      setSearchValue('');
      setPage(0);

      // FIXME: This isn't working
      // Put the new row in edit mode
      handleEdit(response);
    } catch (error) {
      console.error('Failed to create rate plan option:', error);
    } finally {
      setIsRefetching(false);
    }
  };

  return (
    <Paper>
      <Box
        style={{
          display: 'flex',
          gap: '16px',
          marginBottom: '16px',
          alignItems: 'center',
        }}
      >
        <TextField
          label="Search Rate Plan Options"
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />

        <div style={{ display: 'flex', gap: '8px' }}>
          <Tooltip title="Add Rate Plan Option">
            <IconButton onClick={handleAddOption} size="small">
              <Icon.Plus height={20} width={20} />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={isFetchingRatePlanOptions ? 'Loading...' : 'Refresh data'}
          >
            <IconButton
              onClick={handleRefresh}
              disabled={isFetchingRatePlanOptions}
              size="small"
            >
              {isFetchingRatePlanOptions ? (
                <CircularProgress size={20} />
              ) : (
                <Icon.RefreshCCW height={20} width={20} />
              )}
            </IconButton>
          </Tooltip>
        </div>
      </Box>

      {isFetchingRatePlanOptions && !!options ? (
        <Box
          style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer style={{ height: '500px', overflow: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === 'id'}
                      direction={sortBy === 'id' ? sortDirection : 'asc'}
                      onClick={() => handleSort('id')}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === 'name'}
                      direction={sortBy === 'name' ? sortDirection : 'asc'}
                      onClick={() => handleSort('name')}
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === 'active'}
                      direction={sortBy === 'active' ? sortDirection : 'asc'}
                      onClick={() => handleSort('active')}
                    >
                      Active
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === 'non_refundable'}
                      direction={
                        sortBy === 'non_refundable' ? sortDirection : 'asc'
                      }
                      onClick={() => handleSort('non_refundable')}
                    >
                      Non-refundable
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === 'days_from_checkin'}
                      direction={
                        sortBy === 'days_from_checkin' ? sortDirection : 'asc'
                      }
                      onClick={() => handleSort('days_from_checkin')}
                    >
                      Days from Check-in
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === 'discount_percentage'}
                      direction={
                        sortBy === 'discount_percentage' ? sortDirection : 'asc'
                      }
                      onClick={() => handleSort('discount_percentage')}
                    >
                      Discount
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Channels</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((option) => (
                  <TableRow key={option.id}>
                    <TableCell>{option.id}</TableCell>
                    <TableCell>
                      {editingOptions[option.id] ? (
                        <TextField
                          value={editingStates[option.id]?.name ?? option.name}
                          onChange={(e) => {
                            setEditingStates((prev) => ({
                              ...prev,
                              [option.id]: {
                                ...prev[option.id],
                                name: e.target.value,
                              },
                            }));
                          }}
                          onBlur={(e) => {
                            setEditingStates((prev) => ({
                              ...prev,
                              [option.id]: {
                                ...prev[option.id],
                                name: e.target.value,
                              },
                            }));
                          }}
                          size="small"
                          variant="outlined"
                          fullWidth
                        />
                      ) : (
                        option.name
                      )}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={
                          editingOptions[option.id]
                            ? editingStates[option.id]?.active
                            : option.active
                        }
                        onChange={(e) => handleToggle(option, 'active', e)}
                        disabled={!editingOptions[option.id]}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={
                          editingOptions[option.id]
                            ? editingStates[option.id]?.non_refundable
                            : option.non_refundable
                        }
                        onChange={(e) =>
                          handleToggle(option, 'non_refundable', e)
                        }
                        disabled={!editingOptions[option.id]}
                      />
                    </TableCell>
                    <TableCell>
                      {editingOptions[option.id] ? (
                        <TextField
                          type="number"
                          value={
                            editingStates[option.id]?.days_from_checkin ??
                            option.days_from_checkin
                          }
                          onChange={(e) => {
                            setEditingStates((prev) => ({
                              ...prev,
                              [option.id]: {
                                ...prev[option.id],
                                days_from_checkin:
                                  parseInt(e.target.value) || 0,
                              },
                            }));
                          }}
                          onBlur={(e) => {
                            setEditingStates((prev) => ({
                              ...prev,
                              [option.id]: {
                                ...prev[option.id],
                                days_from_checkin:
                                  parseInt(e.target.value) || 0,
                              },
                            }));
                          }}
                          size="small"
                          variant="outlined"
                          fullWidth
                        />
                      ) : (
                        option.days_from_checkin
                      )}
                    </TableCell>
                    <TableCell>
                      {editingOptions[option.id] ? (
                        <TextField
                          value={editingStates[option.id]?.discount_percentage ?? option.discount_percentage}
                          onChange={(e) => {
                            setEditingStates((prev) => ({
                              ...prev,
                              [option.id]: {
                                ...prev[option.id],
                                discount_percentage: parseInt(e.target.value) || 0,
                              },
                            }));
                          }}
                          onBlur={(e) => {
                            setEditingStates((prev) => ({
                              ...prev,
                              [option.id]: {
                                ...prev[option.id],
                                discount_percentage: parseInt(e.target.value) || 0,
                              },
                            }));
                          }}
                          size="small"
                          variant="outlined"
                          fullWidth
                        />
                      ) : (
                        `${option.discount_percentage}%`
                      )}
                    </TableCell>
                    <TableCell>
                      <Box display="flex" flexDirection="column">
                        {UiUtils.channelColumns.map((column) => (
                          <Box
                            key={column.key}
                            display="flex"
                            alignItems="center"
                          >
                            <Checkbox
                              checked={
                                editingOptions[option.id]
                                  ? !!editingStates[option.id]?.[
                                      column.key as keyof EditingState
                                    ]
                                  : !!option[column.key as keyof RatePlanOption]
                              }
                              onChange={(e) => {
                                handleToggle(
                                  option,
                                  column.key as Togglable,
                                  e
                                );
                              }}
                              disabled={!editingOptions[option.id]}
                            />
                            <span>{column.label}</span>
                          </Box>
                        ))}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {editingOptions[option.id] ? (
                        <>
                          <Tooltip title="Save">
                            <IconButton
                              size="small"
                              onClick={() => handleSave(option)}
                              disabled={savingRows[option.id] || isRefetching}
                            >
                              {savingRows[option.id] ? (
                                <CircularProgress size={16} />
                              ) : (
                                <Icon.Check height={16} width={16} />
                              )}
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Cancel">
                            <IconButton
                              size="small"
                              onClick={() => handleCancel(option.id)}
                              disabled={savingRows[option.id] || isRefetching}
                            >
                              <Icon.X height={16} width={16} />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <Tooltip title="Edit">
                          <IconButton
                            size="small"
                            onClick={() => handleEdit(option)}
                            disabled={isRefetching}
                          >
                            {isRefetching ? (
                              <CircularProgress size={16} />
                            ) : (
                              <Icon.Edit2 height={16} width={16} />
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={filteredAndSortedData?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </Paper>
  );
};

export default RatePlanOptions;
