import React, { useState } from 'react';
import { Tabs, Tab, Paper, Fade } from '@material-ui/core';
import { UnitNightListsTable } from './UnitNightListsTable';
import UnitListsTable from "./UnitListsTable";
import DateListsTable from "./DateListsTable";
import {Cohort, CurrentUser, LocationOptions} from "../../types";
import {useGetAllDateListsQuery, useGetAllUnitListsQuery} from "../../store";

interface UnitNightSectionProps {
    locationOptions: LocationOptions;
    isFetchingLocationOptions: boolean;
    cohortData: Cohort[];
    unitNightListSearchQuery: string;
    setUnitNightListSearchQuery: (value: string) => void;
    unitListSearchQuery: string;
    setUnitListSearchQuery: (value: string) => void;
    dateListSearchQuery: string;
    setDateListSearchQuery: (value: string) => void;
    onTabChange: (tabId: string, searchTerm: string) => void;
    selectedTab: 'unit_nights' | 'unit_lists' | 'date_lists';
    setSelectedTab: (value: 'unit_nights' | 'unit_lists' | 'date_lists') => void;
    currentUser: CurrentUser;
}

export const UnitNightsSection: React.FC<UnitNightSectionProps> = ({
    locationOptions,
    isFetchingLocationOptions,
    cohortData,
    unitNightListSearchQuery,
    setUnitNightListSearchQuery,
    unitListSearchQuery,
    setUnitListSearchQuery,
    dateListSearchQuery,
    setDateListSearchQuery,
    selectedTab,
    setSelectedTab,
    currentUser,
    onTabChange
}) => {
    // Define tabMap at component level
    const tabMap: Record<'unit_nights' | 'unit_lists' | 'date_lists', number> = {
        'unit_nights': 0,
        'unit_lists': 1,
        'date_lists': 2
    };

    const { data: unitLists, isLoading: isLoadingUnitLists, refetch: refetchUnitLists } = useGetAllUnitListsQuery();
    const { data: dateLists, isLoading: isLoadingDateLists, refetch: refetchDateLists } = useGetAllDateListsQuery();

    const getTabValue = (tab: 'unit_nights' | 'unit_lists' | 'date_lists'): number => {
        return tabMap[tab];
    };

    return (
        <Paper>
            <Tabs
                value={getTabValue(selectedTab)}
                onChange={(_, newValue) => {
                    const newTab = Object.entries(tabMap).find(([_, val]) => val === newValue)?.[0] as 'unit_nights' | 'unit_lists' | 'date_lists';
                    if (newTab) setSelectedTab(newTab);
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab label="Unit Night Lists" />
                <Tab label="Unit Lists" />
                <Tab label="Date Lists" />
            </Tabs>

            <Fade in={getTabValue(selectedTab) === 0} timeout={300}>
                <div style={{ padding: '1rem', display: getTabValue(selectedTab) === 0 ? 'block' : 'none' }}>
                    <UnitNightListsTable
                        unitLists={unitLists}
                        isLoadingUnitLists={isLoadingUnitLists}
                        refetchUnitLists={refetchUnitLists}
                        dateLists={dateLists}
                        isLoadingDateLists={isLoadingDateLists}
                        searchTerm={unitNightListSearchQuery}
                        setSearchTerm={setUnitNightListSearchQuery}
                        refetchDateLists={refetchDateLists}
                        onTabChange={onTabChange}
                    />
                </div>
            </Fade>

            <Fade in={getTabValue(selectedTab) === 1} timeout={300}>
                <div style={{ padding: '1rem', display: getTabValue(selectedTab) === 1 ? 'block' : 'none' }}>
                    <UnitListsTable
                        unitLists={unitLists}
                        isLoading={isLoadingUnitLists}
                        refetch={refetchUnitLists}
                        locationOptions={locationOptions}
                        isFetchingLocationOptions={isFetchingLocationOptions}
                        searchQuery={unitListSearchQuery}
                        setSearchQuery={setUnitListSearchQuery}
                        cohortData={cohortData}
                        currentUser={currentUser}
                    />
                </div>
            </Fade>

            <Fade in={getTabValue(selectedTab) === 2} timeout={300}>
                <div style={{ padding: '1rem', display: getTabValue(selectedTab) === 2 ? 'block' : 'none' }}>
                    <DateListsTable
                        dateLists={dateLists}
                        isLoading={isLoadingDateLists}
                        refetch={refetchDateLists}
                        searchQuery={dateListSearchQuery}
                        setSearchQuery={setDateListSearchQuery}
                        currentUser={currentUser}
                    />
                </div>
            </Fade>
        </Paper>
    );
}; 