import {forEach, isNumber, map} from 'lodash';
import {Cohort, LocationOptions, MatrixInfo, Message, ReblComponent, SelectOption, Unit} from "../types";
import {addDays, isAfter, isEqual, isValid, parse, startOfDay, differenceInDays, format} from "date-fns";
import { makeStyles } from '@material-ui/core/styles';

export const OnlyNumbersRegex = new RegExp(/^-?\d+$/);
export const TwoDecimalNumberRegex = new RegExp(/\d+(\.\d{0,2})$/);
export const DATE_FORMAT = 'yyyy-MM-dd';
export const CanadianStates: string[] = ["AB", "BC", "MB", "NB", "NL", "NT", "NS", "ON", "PE", "QC", "SK", "YT"]

export class UiUtils {

    public static days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    public static levelCategories = [  // reverse order
        {threshold: 20_000, category: "Owner Driven Pricing"},
        {threshold: 18_000, category: "Upper Mgmt Override"},
        {threshold: 16_000, category: "Testing"},
        {threshold: 14_000, category: "Management Override"},
        {threshold: 12_000, category: "Analyst Holiday"},
        {threshold: 10_000, category: "Analyst Testing"},
        {threshold: 8_000, category: "Analyst Override"},
        {threshold: 6_000, category: "Analyst Monitoring"},
        {threshold: 4_000, category: "Analyst Planning"},  // "Analyst Planning Non-Holiday"
        {threshold: 2_000, category: "Analyst Safety Net"},
        {threshold: 1_000, category: "Mgmt Safety Net"},
        {threshold: 1, category: "Upper Mgmt Safety Net"}
    ]

    private static alphabetically = (ascending: boolean) => {
        return (a, b) => {
            // equal items sort equally
            if (a === b)
                return 0;

            // nulls sort after anything else
            if (a === null)
                return 1;

            if (b === null)
                return -1;

            // otherwise, if we're ascending, lowest sorts first
            if (ascending)
                return a < b ? -1 : 1;

            // if descending, highest sorts first
            return a < b ? 1 : -1;
        };
    }

    private static getOptions = (data: Cohort[], key: string) => {
        const options: string[] = []
        let uniqueValues: string[] = Array.from(
            new Set(data.map(option => option[key]))
        )

        // Inactive cohorts could have only null manager/analyst. Short-circuit before sorting
        if (uniqueValues.length === 0)
            return ["ALL"];

        uniqueValues = uniqueValues.sort(this.alphabetically(true));

        forEach(uniqueValues, (option: any) => {
            if (option !== undefined) options.push(option);
        })

        options.unshift("ALL");

        return options;
    }

    public static parseCSVField = (field: string, value: string) => {
        const values = value.split(",");
        if (values.length <= 1) return value ?? "";

        let commonParts = "";

        for (let i= 0; i < values[0].length; i++){
            let match = true;
            for (let j= 0; j < values.length - 1; j++) {
                if(values[j][i] === " " || values[j][i] === "_" || values[j][i] !== values[j+1][i]) match = false;
            }

            if (match) commonParts += values[0][i];
            else break;
        }

        return `${values.length} ${field} ${commonParts.length > 0 ? ` in ${commonParts}` : ""}`;
    }
    public static getRegionOptions = (cohorts) => this.getOptions(cohorts, "region");
    public static getStateOptions = (cohorts) => this.getOptions(cohorts, "state");
    public static getManagerOptions = (cohorts) => this.getOptions(cohorts, "manager");
    public static getAnalystOptions = (cohorts) => this.getOptions(cohorts, "analyst");

    public static channelColumns = [
        { key: 'vacasa', label: 'Vacasa' },
        { key: 'vrbo', label: 'VRBO' },
        { key: 'airbnb', label: 'AirBNB' },
        { key: 'bdc', label: 'BDC', tooltip: 'Booking.com' },
        { key: 'mybookingpal', label: 'MBP', tooltip: 'MyBookingPal' },
        { key: 'expedia', label: 'Expedia' }
    ];

    public static getDecayOptions = () => {
        return [
            {value: "linear", display: "Steady"},
            {value: "step", display: "Step"},
            {value: "exponential", display: "Slow"},
            {value: "logarithmic", display: "Fast"},
        ]
    };

    public static getCategoryOptions = () => {
        let categoryOptions = []
        for (let category of this.levelCategories) {
            categoryOptions.push({
                value: category.category, display: category.category
            })
        }
        return categoryOptions
    };

    public static getCohortByState = (selectedUnit: Unit, cohorts: Cohort[]): SelectOption[] => {

        let cohortOptions: SelectOption[] = []
        const activeCohorts = cohorts.filter((c, i) => c.active)
        const state = CanadianStates.indexOf(selectedUnit?.state) >= 0 ? "CN" : selectedUnit?.state;

        for (let cohort of activeCohorts) {
            const idx = cohortOptions.map(o => o.value).indexOf(cohort.id);
            if (
                idx < 0 && (
                    cohort.state === state ||
                    selectedUnit?.unit_code.startsWith(cohort.state)
                )
            ) {
                cohortOptions.push({value: cohort.id, display: cohort.name})
            }
        }
        return cohortOptions;
    }

    public static getValue = (multiSelectVariable) => {
        const multiSelectValue = multiSelectVariable?.split(',').map((k: string) => this.getSelectOption(k));
        if (!multiSelectVariable || (multiSelectValue?.length === 1 && multiSelectValue[0]?.value === "")) return [];
        return multiSelectValue;
    }

    public static isFloat(val: string): boolean {
        const asNumber = Number(val);
        const isNumeric = isNumber(asNumber) && !isNaN(asNumber);
        return isNumeric && (OnlyNumbersRegex.test(val) || TwoDecimalNumberRegex.test(val));
    }

    public static isInt(val: string): boolean {
        const asNumber = Number(val);
        const isNumeric = isNumber(asNumber) && !isNaN(asNumber);
        return isNumeric && OnlyNumbersRegex.test(val);
    }

    public static transformStringToDate(dateString: string) {
        return parse(dateString, DATE_FORMAT, new Date());
    }
    public static isValidDateRange(start: string, end: string): boolean {
        const startDate = this.transformStringToDate(start);
        const endDate = this.transformStringToDate(end);
        const validDates = (startDate) && isValid(endDate);
        const startOfFirstDate = startOfDay(startDate);
        const startOfLastDate = startOfDay(endDate);
        const isSameDate = isEqual(startOfFirstDate, startOfLastDate);
        const isEndAfterStart = isAfter(startOfDay(endDate), startOfDay(startDate));
        return validDates && (isSameDate || isEndAfterStart);
    }

    public static dateDiffDays(end: string, start: string): number {
        const startDate = start === "" ? new Date() : this.transformStringToDate(start);
        const endDate = end === "" ? new Date() : this.transformStringToDate(end);
        return differenceInDays(endDate, startDate)
    }

    public static addDaysToDateString(dateString: string, days: number): string {
        const date = this.transformStringToDate(dateString);
        return format(addDays(date, days), DATE_FORMAT)
    }

    public static getNonEmptyRowsLengthFromCSVContent = (csvContent: string[]): number => {
        return csvContent.filter((csvLine) => !csvLine.match(/^[,\s]*$/)).length;
    };

    public static isValidCSVLength = (csvContent: string[]): boolean => {
        // return UiUtils.getNonEmptyRowsLengthFromCSVContent(csvContent) <= Configuration.getMaxCSVLength();
        return UiUtils.getNonEmptyRowsLengthFromCSVContent(csvContent) <= 200_000;
    };
    public static getBulkSuccessMessage = (): Message => ({
        type: 'success',
        content: `File sent successfully. The data may take a while to be charged`,
    });
    public static getBulkErrorMessage = (e: string): Message => ({
        type: 'error',
        content: `An error occurred trying to send the file ,${e}`,
    });
    public static getBulkEmptyFileMessage = (): Message => ({
        type: 'error',
        content: `You are trying to send an empty file, please check your csv file`,
    });
    public static getBulkFileExceededMessage = (): Message => ({
        type: 'error',
        // content: `The file exceeds the maximum of ${Configuration.getMaxCSVLength().toLocaleString()} rows. Please update the file`,
        content: "The file exceeds the maximum of 200,000 rows. Please update the file",
    });
    public static getSaveSuccessMessage = (): Message => ({
        type: 'success',
        content: `Changes submitted successfully. You will be redirected shortly...`
    });
    public static getCopySuccessMessage = (): Message => ({
        type: 'success',
        content: `Copy submitted successfully. Would you like to copy to another cohort?`
    });

    public static getPendingChangesMessage = (): Message => ({type: 'warning', content: 'You have unsaved changes'});
    public static getErrorMessage = (error, status): Message => ({
        type: 'error',
        content: `An error occurred status: ${status}, message:${map(error, (e) => e.detail.error).toString()}`
    });

    public static getMatrixFromStructure(detailArray: MatrixInfo[], sc_id: number, stay_date: string) {
        if (!!sc_id) detailArray = detailArray.filter(f => f.strategic_cohort_id === sc_id);
        if (!!stay_date) detailArray = detailArray.filter(f => f.stay_date === stay_date);

        // Booking Window along the top should always have 7 BWs
        // Pricing Bucket along the side which usually has 7 buckets
        let structure = this.populateStructure(detailArray);
        structure = this.roundStructureValues(structure);

        return structure;
    }

    private static populateStructure(detailArray: MatrixInfo[]) {
        let structure = {};
        detailArray.forEach((m, i) => {
            if (!structure.hasOwnProperty(m.booking_window_group)) structure[m.booking_window_group] = {};
            if (!structure[m.booking_window_group].hasOwnProperty(m.price_group)) {
                structure[m.booking_window_group][m.price_group] = {
                    // adjusted_independent_demand: 0,
                    // adjusted_prediction: 0,
                    // available_units: 0,
                    // booked_units: 0,
                    // bookings_taken: 0,
                    btc: 0,
                    nested_revenue: 0,
                    // cumulative_demand: 0,
                    // elapsed_forecast: 0,
                    independent_demand: 0,
                    prediction: 0,
                    // remaining_forecast: 0,
                    // remaining_units: 0,
                    selected_price: 0,
                    // spilled_demand: 0,
                    total: 0,

                    min_bound_bw: 999999999,
                    max_bound_bw: 0,
                    min_pb: 999999999,
                    max_pb: 0,
                }
            }

            // structure[m.booking_window_group][m.price_group]["adjusted_independent_demand"] += m.adjusted_independent_demand;
            // structure[m.booking_window_group][m.price_group]["adjusted_prediction"] += m.adjusted_prediction;
            // structure[m.booking_window_group][m.price_group]["available_units"] += m.available_units;
            // structure[m.booking_window_group][m.price_group]["booked_units"] += m.booked_units;
            // structure[m.booking_window_group][m.price_group]["bookings_taken"] += m.bookings_taken;
            structure[m.booking_window_group][m.price_group]["btc"] += m.btc;
            structure[m.booking_window_group][m.price_group]["nested_revenue"] += m.nested_revenue;
            // structure[m.booking_window_group][m.price_group]["cumulative_demand"] += m.cumulative_demand;
            // structure[m.booking_window_group][m.price_group]["elapsed_forecast"] += m.elapsed_forecast;
            structure[m.booking_window_group][m.price_group]["independent_demand"] += m.independent_demand;
            // structure[m.booking_window_group][m.price_group]["prediction"] += m.prediction;
            // structure[m.booking_window_group][m.price_group]["remaining_forecast"] += m.remaining_forecast;
            // structure[m.booking_window_group][m.price_group]["remaining_units"] += m.remaining_units;
            structure[m.booking_window_group][m.price_group]["selected_price"] += m.selected_price;
            // structure[m.booking_window_group][m.price_group]["spilled_demand"] += m.spilled_demand;
            structure[m.booking_window_group][m.price_group]["total"] += 1;

            if (structure[m.booking_window_group][m.price_group]["min_bound_bw"] >= m.min_bound_bw) {
                structure[m.booking_window_group][m.price_group]["min_bound_bw"] = m.min_bound_bw
            }
            if (structure[m.booking_window_group][m.price_group]["max_bound_bw"] <= m.max_bound_bw) {
                structure[m.booking_window_group][m.price_group]["max_bound_bw"] = m.max_bound_bw
            }
            if (structure[m.booking_window_group][m.price_group]["min_pb"] >= m.min_pb) {
                structure[m.booking_window_group][m.price_group]["min_pb"] = m.min_pb
            }
            if (structure[m.booking_window_group][m.price_group]["max_pb"] <= m.max_pb) {
                structure[m.booking_window_group][m.price_group]["max_pb"] = m.max_pb
            }
        })
        return structure;
    }

    private static roundStructureValues( structure: any) {
        Object.keys(structure).forEach(bw => {
            Object.keys(structure[bw]).forEach(pg => {
                Object.keys(structure[bw][pg]).forEach(p => {
                    structure[bw][pg][p] = Math.round(structure[bw][pg][p] * 100) / 100;
                })
            })
        })
        return structure
    }

    public static getRanges(structure: any) {
        let bookingWindowGroups = [];

        [0, 1, 2, 3, 4, 5, 6].forEach(bw => {
            let bw_min = 999999999;
            let bw_max = 0;

            [0, 1, 2, 3, 4, 5, 6].forEach(pg => {
                if(structure[bw][pg]["min_bound_bw"] < bw_min) bw_min = structure[bw][pg]["min_bound_bw"];
                if(structure[bw][pg]["max_bound_bw"] > bw_max) bw_max = structure[bw][pg]["max_bound_bw"];
            })
            let display = bw === 6 ? `${bw_min}+` : `${bw_min}-${bw_max}`;
            bookingWindowGroups.push({
                min: bw_min,
                max: bw_max,
                display: display
            });
        });

        let priceGroups = [];
        [0, 1, 2, 3, 4, 5, 6].forEach(pg => {
            let pg_min = 999999999;
            let pg_max = 0;

            [0, 1, 2, 3, 4, 5, 6].forEach(bw => {
                if(structure[bw][pg]["min_pb"] < pg_min) pg_min = structure[bw][pg]["min_pb"];
                if(structure[bw][pg]["max_pb"] > pg_max) pg_max = structure[bw][pg]["max_pb"];
            })

            let display = pg === 6 ? `$${pg_min}+` : `$${pg_min}-${pg_max}`;
            priceGroups.push({
                min: pg_min,
                max: pg_max,
                display: display
            });
        })
        return {
            bookingWindowGroups: bookingWindowGroups,
            priceGroups: priceGroups
        }
    }

    public static async parseFactorDataForHighcharts(previewData: any, activeData: any) {
        //  {
        //       "parsedDates": dates,
        //       "parsedSeries": series,
        //       "extraData": dataByDate
        //  };
        const parsedPreviewData = this.parseSingleDataset(previewData, "preview");
        const parsedActiveData = this.parseSingleDataset(activeData, "active");

        return [parsedActiveData, parsedPreviewData];
    }

    private static parseSingleDataset(data, factorType: string) {
        /*
            "date": "2023-12-22",
            "price_group": 2,
            "factor": 1.1400000000000001,
            "strategic_cohort": "AK_Alaska_Anchorage_ALL_ALL",
            "parent_di_id": 64,
            "demand_influence_title": "test 20",
            "day_of_week": "Friday",
            "season": "Standard",
            "priority": 20,
            "decay": "linear",
            "starting_influence": 10.0,
            "target_influence": 20.0,
            "booking_window_group": 0
        */

        // Deliberate steps
        // 1. Data Structure
        // [ { "2023-12-18":
        //     { "0":
        //       {
        //         "factor": 1.1
        //         "priority": 26
        //         "demand_influence": 64
        //         "demand_influence_title": "tswift"
        //        }
        //      }
        //    }, {
        //       ...
        //    }
        // ]

        // ~~~~ ASSUME THAT PREVIEW AND ACTIVE DATA HAVE SAME DATE RANGE ~~~~
        let dataByDate = {}
        let dates = []

        const priceGroups = [0, 1, 2, 3, 4, 5, 6]; // Not the only place this is hard coded for order ¯\_(ツ)_/¯
        const colors = [ "#2caffe", "#544fc5", "#00e272", "#fe6a35", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42" ] // HC defaults

        data.forEach((factor, idx) => {
            const dt = factor.date;
            const pg = factor.price_group;
            const name = `Price Group ${pg} (${factorType})`

            dates.push(factor.date);

            // Assumes ordered response -- danger! danger, family Robinson!
            if (!dataByDate.hasOwnProperty(dt)) {
                dataByDate[dt] = {}
            }
            if (!dataByDate[dt].hasOwnProperty(name)) {
                dataByDate[dt][name] = {}
            }

            dataByDate[dt][name] = {
                factor: factor.factor,
                parent_di_id: factor.parent_di_id,
                demand_influence_title: factor.demand_influence_title,
                priority: factor.priority,
                season: factor.season,
                day: factor.day_of_week,
                starting_influence: factor.starting_influence,
                target_influence: factor.target_influence,
                booking_window_group: factor.booking_window_group
            }
        })

        // 2. Make dates and price_group unique and sorted
        dates = Array.from(new Set(dates)).sort((a, b) => +new Date(a) - +new Date(b))

        // 3. Order data for series
        // { 0 : [1.0, 1.1, 1.2, ...], 1: [0.9, 0.8, 1.2, ...], ...}
        let seriesData = {}
        for (const pg of priceGroups) {
            const name = `Price Group ${pg} (${factorType})`

            if (!seriesData.hasOwnProperty(name)) {
                seriesData[name]  = []
            }
            for (const dt of dates) {
                if (!!dataByDate[dt][name]) {
                    seriesData[name].push(dataByDate[dt][name]["factor"])
                }
                else {
                    console.log("MISSING DATA", name, dt) // Should be filled by SQL query
                    seriesData[pg].push(1.0)
                }
            }
        }

        // 4. Finalize series
        /*
           name: "Price Group 1",
           type: "line",
           data: [1, 0.99, 1, 1.25, 1, 1, 1]
        */

        let series = [];
        priceGroups.forEach((pg, idx) => {
            const name = `Price Group ${pg} (${factorType})`
            const previewData: boolean = factorType === "preview"

            series.push({
                name: name,
                type: "line",
                color: colors[idx],
                dashStyle: previewData ? "Dash" : "Solid",
                marker: {
                    symbol: previewData ? "circle" : "square"
                },
                data: seriesData[name]
            })
        })

        return {
            "parsedDates": dates,
            "parsedSeries": series,
            "extraData": dataByDate
        };
    }

    public static getSelectOption = (key: string) => {
        return {
            display: !!key && key !== "" ? key : "(unset)",
            value: key
        }
    }

    private static seasonValues = [
        "",
        "Winter",
        "Spring",
        "Summer",
        "Fall",
    ]

    private static bothYesNoValues = [
        "Both",
        "Yes",
        "No",
    ];

    public static reblOptionsMap = {
        "Action": {
            key: "action",
            plural: "actions",
            required: true,
            extras: ["compoundable"],
        },
        "Adjustment Type": {
            key: "adjustment_type",
            plural: "adjustment_types",
            required: true,
        },
        "Flag": {
            key: "flag",
            plural: "flags",
            required: false,
        },
        "Strategy": {
            key: "strategy",
            plural: "strategies",
            required: true,
        }
    };

    public static getRulesetComponents (
        cohorts: Cohort[],
        locationOptions: LocationOptions,
    ): ReblComponent[] {
        return [{
            title: "Rule Details",
            tooltip: null,
            type: "metadata",
            sections: [
                {
                    title: "Information",
                    components: [
                        {
                            label: "Active",
                            key: "is_active",
                            type: "boolean",
                            default: true,
                        }, {
                            label: "Compoundable",
                            key: "compoundable",
                            type: "boolean",
                            default: false,
                        }, {
                            label: "Wipeable",
                            key: "wipeable",
                            type: "boolean",
                            default: true,
                        }, {
                            label: "Priority",
                            key: "priority",
                            type: "priority", // special component to display category
                            min: 1,
                            max: 25000,
                            step: 1,
                            default: 1,
                            required: true,
                            options: this.levelCategories.map(k => this.getSelectOption(k.category)),
                        }, {
                            label: "Strategy",
                            key: "strategy_id",
                            type: "select_id",
                            required: true,
                        }, {
                            label: "Adjustment Type",
                            key: "adjustment_type_id",
                            type: "select_id",
                            required: true,
                            default: "Override",
                        }, {
                            label: "Action",
                            key: "action_id",
                            type: "select_id",
                            required: true,
                        }, {
                            label: "Flag",
                            key: "flag_id",
                            type: "select_id",
                        }, {
                            label: "Adjustment Notes",
                            key: "adjustment_note",
                            type: "textarea",
                            required: true,
                        }
                    ]
                }
            ]}, {
            title: "Date Information",
            tooltip: null,
            type: "rules",
            sections: [{
                title: "Dates",
                components: [
                    {
                        label: "Stay Date",
                        type: "date_range",
                        start_key: "stay_date_start",
                        end_key: "stay_date_end",
                    }, {
                        label: "Active Date",
                        type: "date_range",
                        start_key: "active_date_start",
                        end_key: "active_date_end",
                        required: true,
                    }, {
                        label: "Season",
                        key: "season",
                        type: "select",
                        options: this.seasonValues.map(v => this.getSelectOption(v)),
                    },
                ]
            }, {
                title: "Rolling Windows",
                components: [
                    {
                        label: "Days Out",
                        type: "days_range",
                        start_key: "days_out_start",
                        end_key: "days_out_end",
                        step: 1,
                        min: 0,
                        max: 600,
                    }, {
                        label: "Days Live",
                        type: "days_range",
                        start_key: "days_live_start",
                        end_key: "days_live_end",
                        step: 1,
                        min: 0,
                        max: null,
                    }
                ]
            }, {
                title: "Days of Week",
                components: this.days.map((d) => {
                    return {
                        label: d,
                        key: `${d.toLowerCase()}_checked`,
                        type: "boolean",
                        required: true,
                        default: true,
                    }
                })
            }]
        }, {
            title: "Unit Attributes",
            tooltip: null,
            type: "rules",
            sections: [{
                title: "Locations",
                tooltip: "Only use one geography at a time. 'Excluded Units' can be used anytime",
                components: [{
                    label: "Cohorts",
                    key: "cohort_values",
                    type: "geography",
                    options: cohorts.filter(c => c.active).map(c => this.getSelectOption(c.name)),
                }, {
                    label: "States",
                    key: "state",
                    type: "geography",
                    options: locationOptions.states.map(s => this.getSelectOption(s))
                }, {
                    label: "Regions",
                    key: "region",
                    type: "geography",
                    options: locationOptions.regions.map(s => this.getSelectOption(s))
                }, {
                    label: "Super Regions",
                    key: "super_regions",
                    type: "geography",
                    options: locationOptions.super_regions.map(s => this.getSelectOption(s))
                }, {
                    label: "OPS Markets",
                    key: "ops_markets",
                    type: "geography",
                    options: locationOptions.ops_markets.map(s => this.getSelectOption(s))
                }, {
                    label: "Unitcode Prefix",
                    key: "unit_code_prefix",
                    type: "geography",
                    options: locationOptions.unitcode_prefixes.map(s => this.getSelectOption(s))
                }, {
                    label: "Include Units",
                    key: "included_units",
                    type: "textarea",
                    placeholder: "CSV list of units",
                }, {
                    label: "Exclude Units",
                    key: "excluded_units",
                    type: "textarea",
                    placeholder: "CSV list of units",
                }]
            }, {
                title: "Units",
                components: [{
                    label: "Housing Type",
                    key: "housing_type",
                    type: "select",
                    options: ["All", "House", "Cabin", "Hotel", "Condo"].map(v => this.getSelectOption(v)),
                    default: "All",
                    required: true
                }, {
                    label: "Minstay Type",
                    key: "min_stay_type",
                    type: "select",
                    options: ["Both", "LTR", "STR"].map(v => this.getSelectOption(v)),
                    default: "Both",
                    required: true
                }]
            }]
        }, {
            title: "Pricing",
            tooltip: "When only Pricing conditions are used the rule cannot be wipeable. If Pricing is mixed with other conditions the rule must be wipeable",
            type: "rules",
            sections: [{
                title: "Current Price",
                components: [
                    {
                        label: "Current Price Floor",
                        key: "current_price_floor",
                        type: "number",
                        step: 1,
                        min: 1,
                        max: 100000,
                    }, {
                        label: "Current Price Top",
                        key: "current_price_top",
                        type: "number",
                        step: 1,
                        min: 1,
                        max: 100000,
                    }, {
                        label: "Rgn BRSizeTy Top",
                        key: "price_rgn_brsize_ty_top",
                        type: "number",
                        tooltip: "The difference between current adjusted rate and RGN BDRM AVG Rate as a percentage of the RGN BDRM AVG Rate",
                        step: 1,
                        min: -100,
                        max: 100000,
                    }, {
                        label: "Rgn BRSizeTy Floor",
                        key: "price_rgn_brsize_ty_floor",
                        type: "number",
                        tooltip: "The difference between current adjusted rate and RGN BDRM AVG Rate as a percentage of the RGN BDRM AVG Rate",
                        step: 1,
                        min: -100,
                        max: 100000,
                    }
                ]
            }, {
                title: "Price Change",
                components: [
                    {
                        label: "7 Day Floor",
                        key: "svn_day_price_floor",
                        type: "number",
                        tooltip: "A value of -0.3 will exclude any nights with more than a 30% rate decrease over past 7 days",
                        step: 0.01,
                        min: -1,
                        max: 1,
                    }, {
                        label: "7 Day Top",
                        key: "svn_day_price_top",
                        type: "number",
                        tooltip: "A value of 0.3 will exclude any nights with more than a 30% rate increase over past 7 days",
                        step: 0.01,
                        min: -1,
                        max: 1,
                    }, {
                        label: "14 Day Floor",
                        key: "day_14price_floor",
                        type: "number",
                        tooltip: "A value of -0.3 will exclude any nights with more than a 30% rate decrease over past 14 days",
                        step: 0.01,
                        min: -1,
                        max: 1,
                    }, {
                        label: "14 Day Top",
                        key: "day_14price_top",
                        type: "number",
                        tooltip: "A value of 0.3 will exclude any nights with more than a 30% rate increase over past 14 days",
                        step: 0.01,
                        min: -1,
                        max: 1,
                    }, {
                        label: "21 Day Floor",
                        key: "day_21price_floor",
                        type: "number",
                        tooltip: "A value of -0.3 will exclude any nights with more than a 30% rate decrease over past 21 days",
                        step: 0.01,
                        min: -1,
                        max: 1,
                    }, {
                        label: "21 Day Top",
                        key: "day_21price_top",
                        type: "number",
                        tooltip: "A value of 0.3 will exclude any nights with more than a 30% rate increase over past 21 days",
                        step: 0.01,
                        min: -1,
                        max: 1,
                    }, {
                        label: "28 Day Floor",
                        key: "day_28price_floor",
                        type: "number",
                        tooltip: "A value of -0.3 will exclude any nights with more than a 30% rate decrease over past 28 days",
                        step: 0.01,
                        min: -1,
                        max: 1,
                    }, {
                        label: "28 Day Top",
                        key: "day_28price_top",
                        type: "number",
                        tooltip: "A value of 0.3 will exclude any nights with more than a 30% rate increase over past 28 days",
                        step: 0.01,
                        min: -1,
                        max: 1,
                    }
                ]
            }, {
                title: "Price vs Last Year",
                components: [
                    {
                        label: "LY ADR Floor",
                        key: "price_ly_adr_floor",
                        type: "number",
                        tooltip: "A value of -0.3 will exclude any nights priced lower than LY ADR -30%",
                        step: 0.01,
                        min: -10,
                        max: 10,
                    }, {
                        label: "LY ADR Top",
                        key: "price_ly_adr_top",
                        type: "number",
                        tooltip: "A value of 0.3 will only include nights priced lower than LY ADR +30%",
                        step: 0.01,
                        min: -10,
                        max: 10,
                    }, {
                        label: "LY SNAP Floor",
                        key: "price_ly_snap_floor",
                        type: "number",
                        tooltip: "A value of -0.3 will exclude any nights priced lower than LY Snapshot Rate -30%",
                        step: 0.01,
                        min: -10,
                        max: 10,
                    }, {
                        label: "LY SNAP Top",
                        key: "price_ly_snap_top",
                        type: "number",
                        tooltip: "A value of 0.3 will only include nights priced lower than LY Snapshot Rate +30%",
                        step: 0.01,
                        min: -10,
                        max: 10,
                    }, {
                        label: "LYADR STD Floor",
                        key: "price_lyadr_stddev_floor",
                        type: "number",
                        tooltip: "Calculated as the difference between the adjusted rate and the AVG LY ADR for prior year unit season divided by the Standard Deviation of the LY Unit Season",
                        step: 0.01,
                        min: -1,
                        max: 1,
                    }, {
                        label: "LYADR STD Top",
                        key: "price_lyadr_stddev_top",
                        type: "number",
                        tooltip: "Calculated as the difference between the adjusted rate and the AVG LY ADR for prior year unit season divided by the Standard Deviation of the LY Unit Season",
                        step: 0.01,
                        min: -1,
                        max: 1,
                    }, {
                        label: "BRI RGN LY STD Floor",
                        key: "bri_rgn_ly_stdev_floor",
                        type: "number",
                        tooltip: "Calculated as the difference between the current year price BRI and the LY AVG ADR BRI for the Region Bedroom group, divided by the Prior Year RGN BDR Standard Deviation",
                        step: 0.01,
                        min: -20,
                        max: 20,
                    }, {
                        label: "BRI RGN LY STD Top",
                        key: "bri_rgn_ly_stdev_top",
                        tooltip: "Calculated as the difference between the current year price BRI and the LY AVG ADR BRI for the Region Bedroom group, divided by the Prior Year RGN BDR Standard Deviation",
                        type: "number",
                        step: 0.01,
                        min: -20,
                        max: 20,
                    }
                ]
            }, {
                title: "Analyst",
                components: [{
                //     label: "Minrate Floor",
                //     key: "minrate_floor",
                //     type: "number",
                //     step: 1,
                //     min: -100,
                //     max: 100000,
                // }, {
                //     label: "Minrate Top",
                //     key: "minrate_top",
                //     type: "number",
                //     step: 1,
                //     min: -100,
                //     max: 100000,
                // }, {
                //     label: "Analyst Rate Floor",
                //     key: "analyst_rate_floor",
                //     type: "number",
                //     step: 1,
                //     min: -100,
                //     max: 100000,
                // }, {
                //     label: "Analyst Rate Top",
                //     key: "analyst_rate_top",
                //     type: "number",
                //     step: 1,
                //     min: -100,
                //     max: 100000,
                // }, {
                    label: "Distance to Minrate Floor",
                    key: "distance_to_minrate_floor",
                    type: "number",
                    tooltip: "Adjusted rate divided by min_rate minus 1. E.g. 150 adjusted rate, 100 min rate = 0.5. Floor is the min acceptable value",
                    step: 0.01,
                    min: -1,
                    max: 1,
                }, {
                    label: "Distance to Minrate Top",
                    key: "distance_to_minrate_top",
                    type: "number",
                    tooltip: "Adjusted rate divided by min_rate minus 1. E.g. 150 adjusted rate, 100 min rate = 0.5. Top is the max acceptable value",
                    step: 0.01,
                    min: -1,
                    max: 1,
                }]
            }, {
                title: "BRI",
                components: [{
                    label: "Price BRI TY Floor",
                    key: "price_bri_ty_floor",
                    type: "number",
                    tooltip: "A value of 3 will exclude nights with a BRI lower than 3",
                    step: 0.01,
                    min: -20,
                    max: 20,
                }, {
                    label: "Price BRI TY Top",
                    key: "price_bri_ty_top",
                    type: "number",
                    tooltip: "A value of 3 will only include nights with a BRI lower than 3",
                    step: 0.01,
                    min: -20,
                    max: 20,
                }]
            // }, {
            //     title: "Cadensa",
            //     components: [{
            //         label: "Cadensa Rate Floor",
            //         key: "cadensa_rate_floor",
            //         type: "number",
            //         step: 1,
            //         min: -100,
            //         max: 100000,
            //     }, {
            //         label: "Cadensa Rate Top",
            //         key: "cadensa_rate_top",
            //         type: "number",
            //         step: 1,
            //         min: -100,
            //         max: 100000,
            //     }, {
            //         label: "Analyst Cadensa Rate Floor",
            //         key: "analyst_cadensa_rate_floor",
            //         type: "number",
            //         step: 1,
            //         min: -100,
            //         max: 100000,
            //     }, {
            //         label: "Analyst Cadensa Rate Top",
            //         key: "analyst_cadensa_rate_top",
            //         type: "number",
            //         step: 1,
            //         min: -100,
            //         max: 100000,
            //     }]
            }]
        }, {
            title: "Comp",
            tooltip: null,
            type: "rules",
            sections: [{
                title: "Variance",
                components: [{
                    label: "Variance 2% Floor",
                    key: "variance_2pctile_floor",
                    type: "number",
                    tooltip: "The difference of the current rate less 2nd percentile rate, divided by 2nd percentile rate. E.g. Rate = 150, 2nd_pct = 100, Variance = 50 / 100 = 0.5",
                    step: 0.01,
                    min: -1,
                    max: 1,
                }, {
                    label: "Variance 2% Top",
                    key: "variance_2pctile_top",
                    type: "number",
                    tooltip: "The difference of the current rate less 2nd percentile rate, divided by 2nd percentile rate. E.g. Rate = 150, 2nd_pct = 100, Variance = 50 / 100 = 0.5",
                    step: 0.01,
                    min: -1,
                    max: 1,
                }, {
                    label: "Variance 25% Floor",
                    key: "variance_25pctile_floor",
                    type: "number",
                    tooltip: "The difference of the current rate less the 25th percentile rate, divided by the 25th percentile rate. E.g., Rate = 150, 25th_pct = 120, Variance = (150 - 120) / 120 = 0.25.",
                    step: 0.01,
                    min: -1,
                    max: 1,
                }, {
                    label: "Variance 25% Top",
                    key: "variance_25pctile_top",
                    type: "number",
                    tooltip: "The difference of the current rate less the 25th percentile rate, divided by the 25th percentile rate. E.g., Rate = 150, 25th_pct = 120, Variance = (150 - 120) / 120 = 0.25.",
                    step: 0.01,
                    min: -1,
                    max: 1,
                }, {
                    label: "Variance 50% Floor",
                    key: "variance_50pctile_floor",
                    type: "number",
                    tooltip: "The difference of the current rate less the 50th percentile rate, divided by the 50th percentile rate. E.g., Rate = 150, 50th_pct = 140, Variance = (150 - 140) / 140 = 0.071.",
                    step: 0.01,
                    min: -1,
                    max: 1,
                }, {
                    label: "Variance 50% Top",
                    key: "variance_50pctile_top",
                    type: "number",
                    tooltip: "The difference of the current rate less the 50th percentile rate, divided by the 50th percentile rate. E.g., Rate = 150, 50th_pct = 140, Variance = (150 - 140) / 140 = 0.071.",
                    step: 0.01,
                    min: -1,
                    max: 1,
                }, {
                    label: "Variance 75% Floor",
                    key: "variance_75pctile_floor",
                    type: "number",
                    tooltip: "The difference of the current rate less the 75th percentile rate, divided by the 75th percentile rate. E.g., Rate = 150, 75th_pct = 130, Variance = (150 - 130) / 130 = 0.154.",
                    step: 0.01,
                    min: -1,
                    max: 1,
                }, {
                    label: "Variance 75% Top",
                    key: "variance_75pctile_top",
                    type: "number",
                    tooltip: "The difference of the current rate less the 75th percentile rate, divided by the 75th percentile rate. E.g., Rate = 150, 75th_pct = 130, Variance = (150 - 130) / 130 = 0.154.",
                    step: 0.01,
                    min: -1,
                    max: 1,
                }]
            }]
        }, {
            title: "Occupancy",
            tooltip: null,
            type: "rules",
            sections: [{
                title: "Scarcity",
                components: [{
                    label: "Scarity Floor",
                    key: "scarcityscore_floor",
                    type: "number",
                    tooltip: "Min acceptable scarcity score for the season. (High seasons = more scarcity)",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Scarity Top",
                    key: "scarcityscore_top",
                    type: "number",
                    tooltip: "Max acceptable scarcity score for the season. (High seasons = more scarcity)",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }]
            }, {
                title: "Units",
                components: [{
                    label: "Unit Month OCC Floor",
                    key: "unit_monthocc_floor",
                    type: "number",
                    tooltip: "Min acceptable general unit month occupancy for that unit. 0.6 = 60%",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Unit Month OCC Top",
                    key: "unit_monthocc_top",
                    type: "number",
                    tooltip: "Max acceptable general unit month occupancy for that unit. 0.6 = 60%",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Unit Month Weekday OCC Floor",
                    key: "unit_monthwkdayocc_floor",
                    type: "number",
                    tooltip: "Min acceptable unit month Weekdays Occupancy. 0.6 = 60%",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Unit Month Weekday OCC Top",
                    key: "unit_monthwkdayocc_top",
                    type: "number",
                    tooltip: "Max acceptable unit month Weekdays Occupancy. 0.6 = 60%",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Unit Month Weekend OCC Floor",
                    key: "unit_monthwkendocc_floor",
                    type: "number",
                    tooltip: "Min acceptable unit month Weekends Occupancy. 0.6 = 60%",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Unit Month Weekend OCC Top",
                    key: "unit_monthwkendocc_top",
                    type: "number",
                    tooltip: "Max acceptable unit month Weekends Occupancy. 0.6 = 60%",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Unit Month LY Final OCC Floor",
                    key: "unit_monthlyfnlocc_floor",
                    tooltip: "Min acceptable unit month LY Occupancy. 0.6 = 60%",
                    type: "number",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Unit Month LY Final OCC Top",
                    key: "unit_monthlyfnlocc_top",
                    type: "number",
                    tooltip: "Max acceptable unit month LY Occupancy. 0.6 = 60%",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }]
            }, {
                title: "Regions",
                components: [{
                    label: "Region Month OCC Floor",
                    key: "region_monthocc_floor",
                    tooltip: "Min acceptable region month occupancy. 0.6 = 60%",
                    type: "number",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Month OCC Top",
                    key: "region_monthocc_top",
                    type: "number",
                    tooltip: "Max acceptable region month occupancy. 0.6 = 60%",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Day OCC Floor",
                    key: "region_dayocc_floor",
                    type: "number",
                    tooltip: "Min acceptable region day occupancy. 0.6 = 60%",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Day OCC Top",
                    key: "region_dayocc_top",
                    type: "number",
                    tooltip: "Max acceptable region day occupancy. 0.6 = 60%",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Month Weekday OCC Floor",
                    key: "region_monthwkdayocc_floor",
                    type: "number",
                    tooltip: "Min acceptable region month weekday occupancy. 0.6 = 60%",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Month Weekday OCC Top",
                    key: "region_monthwkdayocc_top",
                    type: "number",
                    step: 0.01,
                    tooltip: "Max acceptable region month weekday occupancy. 0.6 = 60%",
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Month Weekend OCC Floor",
                    key: "region_monthwkendocc_floor",
                    type: "number",
                    tooltip: "Min acceptable region month weekend occupancy. 0.6 = 60%",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Month Weekend OCC Top",
                    key: "region_monthwkendocc_top",
                    type: "number",
                    tooltip: "Max acceptable region month weekend occupancy. 0.6 = 60%",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Month LY Final OCC Floor",
                    key: "region_monthlyfnlocc_floor",
                    type: "number",
                    tooltip: "Min acceptable region month LY Final occupancy. 0.6 = 60%",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Month LY Final OCC Top",
                    key: "region_monthlyfnlocc_top",
                    type: "number",
                    tooltip: "Max acceptable region month LY Final occupancy. 0.6 = 60%",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Day LY Final OCC Floor",
                    key: "region_daylyfnlocc_floor",
                    type: "number",
                    tooltip: "Min acceptable region day LY Final occupancy. 0.6 = 60%",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Day LY Final OCC Top",
                    key: "region_daylyfnlocc_top",
                    type: "number",
                    tooltip: "Max acceptable region day LY Final occupancy. 0.6 = 60%",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }]
            }]
        }, {
            title: "Pacing",
            tooltip: "Occupancy Points Change (ex: '-2' for behind)",
            type: "rules",
            sections: [{
                title: "Region",
                components: [{
                    label: "Region Month Pacing v LY Floor",
                    key: "region_monthpacingvly_floor",
                    type: "number",
                    tooltip: "(Percentage Points) Min occupancy pace region month vs LY. 60 = 60% ahead, -60 = 60% behind",
                    step: 0.01,
                    min: -100,
                    max: 100,
                }, {
                    label: "Region Month Pacing v LY TOP",
                    key: "region_monthpacingvly_top",
                    type: "number",
                    tooltip: "(Percentage Points) Max occupancy pace region month vs LY. 60 = 60% ahead, -60 = 60% behind",
                    step: 0.01,
                    min: -100,
                    max: 100,
                }, {
                    label: "Region Month WKND Pacing Floor",
                    key: "region_monthwkndpacing_floor",
                    type: "number",
                    tooltip: "(Percentage Points) Min occupancy pace region month weekends vs LY. 60 = 60% ahead, -60 = 60% behind",
                    step: 0.01,
                    min: -100,
                    max: 100,
                }, {
                    label: "Region Month WKND Pacing Top",
                    key: "region_monthwkndpacing_top",
                    type: "number",
                    tooltip: "(Percentage Points) Max occupancy pace region month weekends vs LY. 60 = 60% ahead, -60 = 60% behind",
                    step: 0.01,
                    min: -100,
                    max: 100,
                }, {
                    label: "Region Month WKDY Pacing Floor",
                    key: "region_monthwkdyacing_floor",
                    type: "number",
                    tooltip: "(Percentage Points) Min occupancy pace region month weekdays vs LY. 60 = 60% ahead, -60 = 60% behind",
                    step: 0.01,
                    min: -100,
                    max: 100,
                }, {
                    label: "Region Month WKDY Pacing Top",
                    key: "region_monthwkdyacing_top",
                    type: "number",
                    tooltip: "(Percentage Points) Max occupancy pace region month weekdays vs LY. 60 = 60% ahead, -60 = 60% behind",
                    step: 0.01,
                    min: -100,
                    max: 100,
                }, {
                    label: "Region Day Pacing v LY Floor",
                    key: "region_daypacingvly_floor",
                    type: "number",
                    tooltip: "(Percentage Points) Min occupancy pace region day vs LY. 60 = 60% ahead, -60 = 60% behind",
                    step: 0.01,
                    min: -100,
                    max: 100,
                }, {
                    label: "Region Day Pacing v LY TOP",
                    key: "region_daypacingvly_top",
                    type: "number",
                    tooltip: "Max occupancy pace region day vs LY. 60 = 60% ahead, -60 = 60% behind",
                    step: 0.01,
                    min: -100,
                    max: 100,
                }]
            }, {
                title: "Unit",
                components: [{
                    label: "Unit Month Pacing v LY Floor",
                    key: "unit_monthpacingvly_floor",
                    type: "number",
                    tooltip: "(Percentage Points) Min occupancy pace unit month vs LY. 60 = 60% ahead, -60 = 60% behind",
                    step: 1,
                    min: -100,
                    max: 100,
                }, {
                    label: "Unit Month Pacing v LY TOP",
                    key: "unit_monthpacingvly_top",
                    type: "number",
                    tooltip: "(Percentage Points) Max occupancy pace unit month vs LY. 60 = 60% ahead, -60 = 60% behind",
                    step: 1,
                    min: -100,
                    max: 100,
                }, {
                    label: "Unit Month Pacing v RGN Floor",
                    key: "unit_monthpacingvrgn_floor",
                    type: "number",
                    tooltip: "(Percentage Points) Min occupancy pace unit month vs region.60 = 60% ahead, -60 = 60% behind",
                    step: 1,
                    min: -100,
                    max: 100,
                }, {
                    label: "Unit Month Pacing v RGN TOP",
                    key: "unit_monthpacingvrgn_top",
                    type: "number",
                    tooltip: "(Percentage Points) Max occupancy pace unit month vs region. 60 = 60% ahead, -60 = 60% behind",
                    step: 1,
                    min: -100,
                    max: 100,
                }]
            }]
        }, {
            title: "Builds",
            tooltip: "Occupancy Points Change (ex: '-2' for behind)",
            type: "rules",
            sections: [{
                title: "Unit",
                components: [{
                    label: "Unit Month BLD 7 Day Floor",
                    key: "unit_monthbld7day_floor",
                    type: "number",
                    tooltip: "Min unit month occupancy gained in last 7 days. 0.6 = 60% occupancy gained",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Unit Month BLD 7 Day Top",
                    key: "unit_monthbld7day_top",
                    type: "number",
                    tooltip: "Max unit month occupancy gained in last 7 days. 0.6 = 60% occupancy gained",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Unit Month BLD 28 Day Floor",
                    key: "unit_monthbld28day_floor",
                    type: "number",
                    tooltip: "Min unit month occupancy gained in last 28 days. 0.6 = 60% occupancy gained",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Unit Month BLD 28 Day Top",
                    key: "unit_monthbld28day_top",
                    type: "number",
                    tooltip: "Max unit month occupancy gained in last 28 days. 0.6 = 60% occupancy gained",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Unit Month BLD 7 Day v LY Floor",
                    key: "unit_monthbld7dayvly_floor",
                    type: "number",
                    tooltip: "(Percentage Points) Min unit month occupancy gained in last 7 days vs LY. 60 = 60% occupancy gained",
                    step: 0.01,
                    min: -100,
                    max: 100,
                }, {
                    label: "Unit Month BLD 7 Day v LY Top",
                    key: "unit_monthbld7dayvly_top",
                    type: "number",
                    tooltip: "(Percentage Points) Max unit month occupancy gained in last 7 days vs LY. 60 = 60% occupancy gained",
                    step: 0.01,
                    min: -100,
                    max: 100,
                }, {
                    label: "Unit Month BLD 28 Day v LY Floor",
                    key: "unit_monthbld28dayvly_floor",
                    type: "number",
                    tooltip: "(Percentage Points) Min unit month occupancy gained in last 28 days vs LY. 60 = 60% occupancy gained",
                    step: 0.01,
                    min: -100,
                    max: 100,
                }, {
                    label: "Unit Month BLD 28 Day v LY Top",
                    key: "unit_monthbld28dayvly_top",
                    type: "number",
                    tooltip: "(Percentage Points) Max unit month occupancy gained in last 28 days vs LY. 60 = 60% occupancy gained",
                    step: 0.01,
                    min: -100,
                    max: 100,
                }]
            }, {
                title: "Region",
                components: [{
                    label: "Region Day BLD 7 Day Floor",
                    key: "region_daybld7day_floor",
                    type: "number",
                    tooltip: "Min region day occupancy gained in last 7 days. 0.6 = 60% occupancy gained",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Day BLD 7 Day v Top",
                    key: "region_daybld7day_top",
                    type: "number",
                    tooltip: "Max region day occupancy gained in last 7 days. 0.6 = 60% occupancy gained",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Day BLD 7 Day v LY Floor",
                    key: "region_daybld7dayvly_floor",
                    type: "number",
                    tooltip: "(Percentage Points) Min region day occupancy gained in last 7 days vs LY. 60 = 60% occupancy gained",
                    step: 1,
                    min: -100,
                    max: 100,
                }, {
                    label: "Region Day BLD 7 Day v LY Top",
                    key: "region_daybld7dayvly_top",
                    type: "number",
                    tooltip: "(Percentage Points) Max region day occupancy gained in last 7 days vs LY. 60 = 60% occupancy gained",
                    step: 1,
                    min: -100,
                    max: 100,
                }, {
                    label: "Region Month BLD 7 Day Floor",
                    key: "region_monthbld7day_floor",
                    type: "number",
                    tooltip: "Min region month occupancy gained in last 7 days. 0.6= 60% occupancy gained",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Month BLD 7 Day v Top",
                    key: "region_monthbld7day_top",
                    type: "number",
                    tooltip: "Max region month occupancy gained in last 7 days. 0.6= 60% occupancy gained",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Month BLD 7 Day v LY Floor",
                    key: "region_monthbld7dayvly_floor",
                    type: "number",
                    tooltip: "(Percentage Points) Min region month occupancy gained in last 7 days vs LY. 60 = 60% occupancy gained",
                    step: 1,
                    min: -100,
                    max: 100,
                }, {
                    label: "Region Month BLD 7 Day v LY Top",
                    key: "region_monthbld7dayvly_top",
                    type: "number",
                    tooltip: "(Percentage Points) Max region month occupancy gained in last 7 days vs LY. 60 = 60% occupancy gained",
                    step: 1,
                    min: -100,
                    max: 100,
                }, {
                    label: "Region Day BLD 28 Day Floor",
                    key: "region_daybld28day_floor",
                    type: "number",
                    tooltip: "Min region day occupancy gained in last 28 days. 0.6 = 60% occupancy gained",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Day BLD 28 Day v Top",
                    key: "region_daybld28day_top",
                    type: "number",
                    tooltip: "Max region day occupancy gained in last 28 days. 0.6 = 60% occupancy gained",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Day BLD 28 Day v LY Floor",
                    key: "region_daybld28dayvly_floor",
                    type: "number",
                    tooltip: "(Percentage Points) Min region day occupancy gained in last 28 days vs LY. 60 = 60% occupancy gained",
                    step: 1,
                    min: -100,
                    max: 100,
                }, {
                    label: "Region Day BLD 28 Day v LY Top",
                    key: "region_daybld28dayvly_top",
                    type: "number",
                    tooltip: "(Percentage Points) Max region day occupancy gained in last 28 days vs LY. 60 = 60% occupancy gained",
                    step: 1,
                    min: -100,
                    max: 100,
                }, {
                    label: "Region Month BLD 28 Day Floor",
                    key: "region_monthbld28day_floor",
                    type: "number",
                    tooltip: "Min region month occupancy gained in last 28 days. 0.6 = 60% occupancy gained",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Month BLD 28 Day v Top",
                    key: "region_monthbld28day_top",
                    type: "number",
                    tooltip: "Max region month occupancy gained in last 28 days. 0.6 = 60% occupancy gained",
                    step: 0.01,
                    min: 0,
                    max: 1,
                }, {
                    label: "Region Month BLD 28 Day v LY Floor",
                    key: "region_monthbld28dayvly_floor",
                    type: "number",
                    tooltip: "(Percentage Points) Min region month occupancy gained in last 28 days vs LY. 60 = 60% occupancy gained",
                    step: 1,
                    min: -100,
                    max: 100,
                }, {
                    label: "Region Month BLD 28 Day v LY Top",
                    key: "region_monthbld28dayvly_top",
                    type: "number",
                    tooltip: "(Percentage Points) Max region month occupancy gained in last 28 days vs LY. 60 = 60% occupancy gained",
                    step: 1,
                    min: -100,
                    max: 100,
                }]
            }]
        }, {
            title: "Other",
            tooltip: null,
            type: "rules",
            sections: [{
                title: "Selects",
                components: [{
                    label: "Distress LY",
                    key: "distressed_ly",
                    type: "select",
                    tooltip: "Yes = Target only LY Distressed nights",
                    options: this.bothYesNoValues.map((v: string) => this.getSelectOption(v)),
                    default: "Both",
                    required: true
                }, {
                    label: "HY Spill LY",
                    key: "hy_spilly",
                    type: "select",
                    tooltip: "Yes = Target only LY High Yield Spill nights",
                    options: this.bothYesNoValues.map((v: string) => this.getSelectOption(v)),
                    default: "Both",
                    required: true
                }, {
                    label: "LY Spill LY",
                    key: "ly_spilly",
                    type: "select",
                    tooltip: "Yes = Target only LY Low Yield Spill nights",
                    options: this.bothYesNoValues.map((v: string) => this.getSelectOption(v)),
                    default: "Both",
                    required: true
                }]
            }, {
                title: "Price",
                components: [{
                //     label: "NS PV Floor",
                //     key: "ns_pv_floor",
                //     type: "number",
                //     step: 1,
                //     min: -100,
                //     max: 100000,
                // }, {
                    label: "NS PV Top",
                    key: "ns_pv_top",
                    type: "number",
                    step: 1,
                    min: -100,
                    max: 100000,
                }, {
                //     label: "NS PV Comp Avg Floor",
                //     key: "ns_pv_comp_avg_floor",
                //     type: "number",
                //     step: 1,
                //     min: -100,
                //     max: 100000,
                // }, {
                    label: "NS PV Comp Avg Top",
                    key: "ns_pv_comp_avg_top",
                    type: "number",
                    step: 1,
                    min: -100,
                    max: 100000,
                }, {
                //     label: "NS Price Change Low Bkt Floor",
                //     key: "ns_pricechange_lower_bkt_floor",
                //     type: "number",
                //     step: 1,
                //     min: -100,
                //     max: 100000,
                // }, {
                    label: "NS Price Change Low Bkt Top",
                    key: "ns_pricechange_lower_bkt_top",
                    type: "number",
                    step: 1,
                    min: -100,
                    max: 100000,
                }, {
                //     label: "NS Demand Change Low Bkt Floor",
                //     key: "ns_dmdchange_lower_bkt_floor",
                //     type: "number",
                //     step: 1,
                //     min: -100,
                //     max: 100000,
                // }, {
                    label: "NS Demand Change Low Bkt Top",
                    key: "ns_dmdchange_lower_bkt_top",
                    type: "number",
                    step: 1,
                    min: -100,
                    max: 100000,
                }]
            }]
        }]
    }

    public static useTableStyles = makeStyles({
        tableRow: {
            '&:hover': {
                backgroundColor: '#f8f8f8',
            },
        },
        stickyHeader: {
            zIndex: -1,
            backgroundColor: 'white',
            '& th': {
                backgroundColor: 'white',
            }
        },
        tableContainer: {
            position: 'relative',
            zIndex: 0
        }
    });
}
